import * as React from "react";
import { AppColors } from "../models/General";
import { NewReservation } from "../models/Reservation";
import { useDebounce } from "../hooks/Debounce/use-debounce";
import { WhiteBoxInput } from "./WhiteBoxInput";
import { Heroicons } from "./Heroicon/Heroicon";

type NewReservationDetail = Pick<
  Required<NewReservation>,
  "name" | "note" | "email" | "requestChannel" | "phoneNumber"
>;

export const NewReservationDetail = (
  props: {
    appColors: AppColors;
    selectDetails: (details: Partial<NewReservation>) => void;
    handleContinue?: () => void;
    active?: boolean;
  } & Partial<NewReservation>
) => {
  const [newReservation, setNewReservation] = React.useState<NewReservationDetail>({
    email: props.email || "",
    name: props.name || "",
    note: props.note || "",
    phoneNumber: props.phoneNumber || "",
    requestChannel: props.requestChannel || "",
  });

  const debouncedReservation = useDebounce(newReservation, 350);

  const [hasErrorOccured, setHasErrorOccured] = React.useState(false);

  const _handleSave = React.useCallback(() => {
    if (newReservation.name) {
      setHasErrorOccured(false);
      props.selectDetails.call(undefined, newReservation);
      if (props.handleContinue) {
        props.handleContinue.call(undefined);
      }
    } else {
      setHasErrorOccured(true);
    }
  }, [newReservation, props.handleContinue, props.selectDetails]);

  React.useEffect(() => {
    if (props.handleContinue) {
      props.selectDetails.call(undefined, debouncedReservation);
    }
  }, [debouncedReservation, props.handleContinue, props.selectDetails]);

  React.useEffect(() => {
    setNewReservation((c) => {
      let temp = c;
      if (c.email !== props.email && props.email) {
        temp = { ...temp, email: props.email };
      }
      if (c.name !== props.name && props.name) {
        temp = { ...temp, name: props.name };
      }
      if (c.note !== props.note && props.note) {
        temp = { ...temp, note: props.note };
      }
      if (c.phoneNumber !== props.phoneNumber && props.phoneNumber) {
        temp = { ...temp, phoneNumber: props.phoneNumber };
      }
      if (c.requestChannel !== props.requestChannel && props.requestChannel) {
        temp = { ...temp, requestChannel: props.requestChannel };
      }
      return temp;
    });
  }, [props.email, props.name, props.note, props.phoneNumber, props.requestChannel]);

  const _handleValue = React.useCallback(
    (key: keyof NewReservationDetail, value: NewReservationDetail[typeof key]) =>
      setNewReservation((res) => ({ ...res, [key]: value })),
    []
  );

  const reservationKeys: (keyof NewReservationDetail)[] = React.useMemo(
    () => ["email", "name", "note", "phoneNumber", "requestChannel"] as (keyof NewReservationDetail)[],
    []
  );

  const [changeHandler, clearHandler] = React.useMemo(() => {
    // create object that enables -> handlerObject["name"] === (value) => _handleValue("name", value);
    const handlerObject = reservationKeys.reduce(
      (obj: { [key in keyof NewReservationDetail]: (value: string) => void }, key) => {
        return { ...obj, [key]: (value: NewReservationDetail[typeof key]) => _handleValue(key, value) };
      },
      {} as { [key in keyof NewReservationDetail]: (value: string) => void }
    );
    const clearObject = reservationKeys.reduce((obj: { [key in keyof NewReservationDetail]: () => void }, key) => {
      return { ...obj, [key]: () => _handleValue(key, "") };
    }, {} as { [key in keyof NewReservationDetail]: () => void });

    return [handlerObject, clearObject];
  }, [_handleValue, reservationKeys]);

  const _saveOnEnter = React.useCallback(
    (ev: React.KeyboardEvent) => {
      if (ev.key === "Enter") {
        _handleSave();
      }
    },
    [_handleSave]
  );

  return (
    <div className="flex flex-1 flex-col justify-start items-start h-full w-full z-10">
      <div className={`relative flex flex-1 flex-col w-full px-2 pb-2 pt-1 min-h-0 max-h-full h-full`}>
        <div className="flex flex-col justify-start items-center w-full px-2 pb-2 space-y-1">
          <WhiteBoxInput
            label={"Name"}
            onChange={changeHandler.name}
            onRemove={clearHandler.name}
            inputValue={newReservation.name}
            hasError={hasErrorOccured && !newReservation.name}
            className="flex-shrink-0"
          />

          <WhiteBoxInput
            label={"Email"}
            onChange={changeHandler.email}
            onRemove={clearHandler.email}
            inputValue={newReservation.email || null}
            hasError={false}
            className="flex-shrink-0"
          />

          <div className="inline-flex w-full flex-shrink-0 space-x-1">
            <WhiteBoxInput
              label={"Telefon Nr."}
              onChange={changeHandler.phoneNumber}
              onRemove={clearHandler.phoneNumber}
              inputValue={newReservation.phoneNumber || null}
              hasError={false}
              className="flex-shrink-0"
              width="flex"
            />
            <a
              tabIndex={-1}
              href={`tel:${newReservation.phoneNumber}`}
              className={`w-12 h-12 rounded bg-${props.appColors.backgroundcolor}-500 hover:bg-${props.appColors.backgroundcolor}-600 text-white inline-flex justify-center items-center p-2 transition-all duration-200 ease-in-out`}
            >
              <Heroicons.Outline.PhoneIcon className="w-full h-full" />
            </a>
          </div>
          <WhiteBoxInput
            label={"Kanal"}
            onChange={changeHandler.requestChannel}
            onRemove={clearHandler.requestChannel}
            inputValue={newReservation.requestChannel || null}
            hasError={false}
            className="flex-shrink-0"
          />

          <WhiteBoxInput
            label={"Notiz"}
            onChange={changeHandler.note}
            onRemove={clearHandler.note}
            inputValue={newReservation.note || null}
            hasError={false}
            size="loose"
            className="flex-shrink-0 h-32"
          />
        </div>
        <div className="flex w-full h-16 flex-shrink-0 flex-grow-0 justify-center items-center p-1 xs:p-3">
          <div
            className={`relative flex justify-center items-center w-full h-full rounded-lg bg-${props.appColors.backgroundcolor}-500 shadow-md font-semibold text-lg text-white cursor-pointer `}
            onClick={_handleSave}
            onKeyDown={_saveOnEnter}
            tabIndex={0}
          >
            <span>Weiter</span>
            <span className="absolute flex h-full w-full justify-end items-center px-2">
              <Heroicons.Outline.ArrowRightIcon className="h-7 w-7" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
