import { Route, Switch } from "react-router-dom";
import { ExportDayView } from "../day/ExportDayView";
import { useExportMainViewModel } from "./ExportMainViewModel";
import { ExportMainViewTypes } from "./ExportMainViewTypes";

export const ExportMainView = (props: ExportMainViewTypes.Props) => {
  const viewModel = useExportMainViewModel(props);
  return (
    <Switch>
      <Route path="(/export|/export/day/:date?)">
        <ExportDayView {...viewModel} />
      </Route>
    </Switch>
  );
};
