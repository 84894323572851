import { SilentRequest, InteractionType } from "@azure/msal-browser";
import { useMsalAuthentication, useMsal, MsalContext, useAccount } from "@azure/msal-react";
import { useMemo, useEffect, useContext } from "react";
import AuthConfig from "../../../AuthConfig";
import { useSavedAccount } from "../../../hooks/useSavedAccount";
import { WelcomeViewTypes } from "./WelcomeViewTypes";

export const useWelcomeViewModel = (props: WelcomeViewTypes.Props) => {
  const [acc, setAccount] = useSavedAccount();
  const account = useAccount(acc ?? {});

  const config: SilentRequest = useMemo(() => ({ ...AuthConfig.loginRequest, account: acc ?? undefined }), [acc]);
  const { result, error } = useMsalAuthentication(InteractionType.Silent, {
    ...config,
    loginHint:
      acc?.username ?? (acc?.idTokenClaims as any)?.emails
        ? (acc?.idTokenClaims as any)?.emails[0] ?? undefined
        : undefined ?? undefined,
  });
  const { instance } = useMsal();

  useEffect(() => {
    if (result) {
      setAccount(account);
      instance.setActiveAccount(account);
    }
    if (error) {
      console.error(error);
      if (acc && error.message.includes("silent_sso_error")) {
        instance.loginRedirect(config);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acc, account, config, error, instance.setActiveAccount, instance.loginRedirect, result, setAccount]);

  return {};
};
