import AuthConfig from "../AuthConfig";
import { addProtocol } from "../hooks/useProtocol";
import { getSavedAccount } from "../hooks/useSavedAccount";
import { PCA } from "../index";

const getBaseUrl = () => {
  const localServerPort = "44324";
  const conveyorPort = "45456";
  const base = "https://";
  const { host, hostname, origin } = window.location;
  if (host.includes("localhost")) {
    return `${base}localhost:${localServerPort}`;
  } else if (host.includes("192.168.")) {
    return `http://192.168.178.28:${conveyorPort}`;
  } else return origin;
};

const baseUrl = getBaseUrl();

type ApiCall = <T>(
  apiUrl: string,
  method: "POST" | "GET" | "PUT" | "DELETE",
  body?: string,
  mapper?: (response: any) => T,
  defaultReturn?: any
) => Promise<T>;
export const ApiCall: ApiCall = async (apiUrl, method, body, mapper, defaultReturn) => {
  try {
    let headers: any = {};
    if (method !== "GET") {
      headers["Content-Type"] = "application/json";
    }
    const url = apiUrl.includes("https://") ? apiUrl : `${baseUrl}/api/${apiUrl}`;
    const shortenUrl = url.slice((url.length - window.location.origin.length) * -1);

    const savedAccount = PCA.getActiveAccount() || PCA.getAllAccounts().find((a) => a) || getSavedAccount();

    const request = {
      ...AuthConfig.apiRequest.reservierungen,
      account: savedAccount || undefined,
    };

    const { accessToken } = await PCA.acquireTokenSilent(request);

    const promiseWrapper: Promise<Response> = new Promise((resolve, reject) =>
      fetch(url, {
        method,
        headers: { ...headers, Authorization: `Bearer ${accessToken}` },
        body: body,
      })
        .then((resp) => resolve(resp))
        .catch((error) => reject(error))
    );

    const fetching = await promiseWrapper;
    if (fetching === undefined) throw new Error("Request did not return solvable promise");
    const resp: Response = fetching.clone();
    let textResponse = null;
    if (resp.ok) {
      let response = null;
      try {
        console.log("parsing");
        response = await resp.json();
      } catch (error) {
        try {
          textResponse = await resp.text();
        } catch (error) {
          console.error("Unable to parse response text");
          textResponse = null;
        }
        response = null;
      }
      if (mapper && response) {
        let text = null;
        try {
          text = JSON.stringify(response, null, 2) + body ? JSON.stringify(body, null, 2) : null;
        } catch (error) {
          text = body ? JSON.stringify(body, null, 2) : null;
        }
        addProtocol({
          data: text || "--",
          desc: `${resp.status} - ${resp.statusText ?? "--"} - ${method} - ${shortenUrl}`,
          type: "API",
          date: new Date(),
        });
        return mapper(response);
      } else if (response) {
        let text = null;
        try {
          text = JSON.stringify(response, null, 2) + body ? JSON.stringify(body, null, 2) : null;
        } catch (error) {
          text = body ? JSON.stringify(body, null, 2) : null;
        }
        addProtocol({
          data: text || "--",
          desc: `${resp.status} - ${resp.statusText ?? "--"} - ${method} - ${shortenUrl}`,
          type: "API",
          date: new Date(),
        });
        return response;
      }
      if (defaultReturn) {
        return defaultReturn;
      } else return response;
    } else {
      try {
        textResponse = await resp.text();
      } catch (error) {
        console.error("Unable to parse response text");
        textResponse = null;
      }
      addProtocol({
        data: body ? JSON.stringify(JSON.parse(body), null, 2) : textResponse ?? "--",
        desc: `${resp.status} - ${resp.statusText ?? "--"} - ${method} - ${shortenUrl}`,
        type: "API",
        date: new Date(),
      });
      if (textResponse) throw new Error(`${resp.status} - Status did not indicate success\r\n\r\n${textResponse}`);
      throw new Error(`${resp.status} - Status did not indicate success`);
    }
  } catch (error) {
    if (
      error &&
      error.message &&
      (error.message.includes("silent_sso") ||
        error.message.includes("grant") ||
        error.message.includes("interaction_required"))
    ) {
      addProtocol({
        data: error,
        desc: `Fehler - Silent Token Renewal Fehlgeschlagen`,
        type: "API",
        date: new Date(),
      });
      const savedAccount = PCA.getActiveAccount() || PCA.getAllAccounts().find((a) => a) || getSavedAccount();
      PCA.acquireTokenRedirect({
        ...AuthConfig.apiRequest.reservierungen,
        account: savedAccount ?? undefined,
      });
    } else {
      const desc = `${typeof error === "string" ? error : error && "name" in error ? error.name : "--"} \r\n\r\n ${
        error && "message" in error ? error.message : "--"
      } \r\n\r\n ${error && "stack" in error ? error.stack : "--"} \r\n\r\n ## BODY ## \r\n\r\n ${
        body ? JSON.stringify(JSON.parse(body ?? "{}"), null, 2) : "--"
      }`;
      addProtocol({
        data: desc,
        desc: `Error - ${method} - ${apiUrl}`,
        type: "API",
        date: new Date(),
      });
    }

    throw error;
  }
};
