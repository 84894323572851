import ClientApi, { Savelocation } from "./ClientApi";
import PersistentStorageRepository from "./PersistentStorageRepository";
import QueueProvider, { QueueElement } from "./QueueProvider";

export const _dispatchEvent = <T>(event: string, detail?: T) => {
  if (detail) {
    const eventToDispatch = new CustomEvent<typeof detail>(event, { detail });
    return window.dispatchEvent(eventToDispatch);
  } else {
    const eventToDispatch = new Event(event);
    return window.dispatchEvent(eventToDispatch);
  }
};

const Events = {
  COMMAND_QUEUE_UPDATE: "COMMAND_QUEUE_UPDATE",
  COMMAND_QUEUE_TEMPIDS: "COMMAND_QUEUE_TEMPIDS",
};

export type CommandQueueItem<T extends keyof typeof ClientApi.BackendCalls> = {
  command: T;
  props: Parameters<typeof ClientApi.BackendCalls[T]>;
  id: string;
  isCreation: boolean;
  reference: Savelocation;
  date?: Date;
};

const COMMAND_QUEUE = "COMMAND_QUEUE";
const COMMAND_QUEUE_TEMPIDS = "COMMAND_QUEUE_TEMPIDS";
const COMMAND_QUEUE_CACHED_ = "COMMAND_QUEUE_CACHED_";

const handleCommandQueueElement = async <T extends keyof typeof ClientApi.BackendCalls>(
  element: CommandQueueItem<T>,
  queue: QueueElement<CommandQueueItem<T>>[]
) => {
  try {
    if (element.command in ClientApi.BackendCalls) {
      // Führe Command aus
      const resp = await (ClientApi.BackendCalls[element.command] as any).apply(null, element.props as any);
      // Ersetze existierendes Element
      if (resp && "id" in resp && element.isCreation) {
        const res = await PersistentStorageRepository.get<any[]>(element.reference, element.date);
        const match = res?.find((r) => r.id === element.id);
        if (match) {
          await PersistentStorageRepository.add<any, Savelocation>(
            element.reference,
            element.date,
            { ...match, id: (resp as any).id },
            true,
            "id",
            false,
            true,
            element.id
          );
        }

        if (element.isCreation) {
          // Füge response id zur tempids damit Änderungen abgefangen werden
          await addIdToCommandTempIds((resp as any).id);
          const tempIds = await getCommandTempIds();
          // Prüfe ob es eine passende TempID gibt
          if (tempIds?.some((i) => i.id === element.id)) {
            const cached = await PersistentStorageRepository.get<CommandQueueItem<any>[]>(
              COMMAND_QUEUE_CACHED_ + element.id
            );
            if (cached) {
              // Ersetze die id in den Cached Commands
              const mappedCached = cached.map((item) => {
                if (item && item.props && "id" in (item.props[0] as any)) {
                  const [i, ...rest] = item.props;
                  return { ...item, props: [{ ...(i as any), id: (resp as any).id }, ...rest] };
                } else return item;
              });
              // Fühe die CachedCommands zur Queue hinzu
              for (const cachedItem of mappedCached) {
                commandQueue.addToQueue({ value: cachedItem, reference: element.id });
                await PersistentStorageRepository.removeEntry<CommandQueueItem<any>>(
                  COMMAND_QUEUE_CACHED_ + element.id,
                  (_, idx) => !!idx
                );
              }
              // Lösche die CachedCommands
              await PersistentStorageRepository.remove(COMMAND_QUEUE_CACHED_ + element.id);
            }
          } else {
            // // console.debug("Found no matching TempId");
          }
          await removeIdFromCommandTempIds(element.id, (resp as any).id);
        }
      } else if (element.isCreation) {
        throw new Error("Element is creation but response does not contain id");
      } else return;
    } else throw new Error(`Command ${element.command} does not exist.`);
  } catch (error) {
    throw error;
  }
};

const persistsCommandQueue = async (queue: QueueElement<CommandQueueItem<any>>[]) => {
  try {
    await PersistentStorageRepository.write(COMMAND_QUEUE, undefined, queue);
    _dispatchEvent(Events.COMMAND_QUEUE_UPDATE);
    return;
  } catch (error) {
    throw error;
  }
};

const commandQueue = QueueProvider.Queue<CommandQueueItem<any>>({
  handleQueueElement: handleCommandQueueElement,
  onQueueChange: persistsCommandQueue,
  maxFailAmount: 20,
  onError: console.error,
  shouldCheckConnection: true,
});

const getIds = () => {
  const ids = commandQueue.CurrentQueue.filter((item) => item.reference);
  return ids;
};

const findInQueue = (reference: string) => {
  const matches = commandQueue.findInQueueByReference(reference);
  return matches;
};

const getCommandTempIds = async () => {
  try {
    const resp = await PersistentStorageRepository.get<{ id: string }[]>(COMMAND_QUEUE_TEMPIDS);
    return resp;
  } catch (error) {
    throw error;
  }
};

const removeIdFromCommandTempIds = async (elementid: string, respid: string) => {
  try {
    const resp = await PersistentStorageRepository.removeEntry<{ id: string }>(
      COMMAND_QUEUE_TEMPIDS,
      (i) => i.id !== elementid && i.id !== respid
    );
    _dispatchEvent(Events.COMMAND_QUEUE_TEMPIDS);
    return resp;
  } catch (error) {
    throw error;
  }
};

const addIdToCommandTempIds = async (id: string) => {
  try {
    // console.debug(`Adding ID to tempIDs ${id}`);
    const resp = await PersistentStorageRepository.add<{ id: string }, Savelocation>(
      COMMAND_QUEUE_TEMPIDS,
      undefined,
      { id },
      false,
      undefined,
      true,
      true
    );
    _dispatchEvent(Events.COMMAND_QUEUE_TEMPIDS);
    return resp;
  } catch (error) {
    throw error;
  }
};

const addItemToCommandCached = async (item: CommandQueueItem<any>) => {
  try {
    // console.debug(`Adding item to Commandcache ${item.id}`);
    return await PersistentStorageRepository.add<CommandQueueItem<any>, Savelocation>(
      COMMAND_QUEUE_CACHED_ + item.id,
      undefined,
      item,
      false,
      undefined,
      true,
      true
    );
  } catch (error) {
    throw error;
  }
};

const addToCommandQueue = async <T extends keyof typeof ClientApi.BackendCalls>(item: CommandQueueItem<T>) => {
  try {
    // console.debug(`Adding Item to Queue ${item.id}`);
    if (item.isCreation && "id" in item) {
      // console.debug(`Item is Creation`);
      // Ist Erstellung
      await addIdToCommandTempIds(item.id);
    } else if ("id" in item) {
      // console.debug(`Item is not Creation`);
      // Ist nicht Erstellung
      const tempIds = await PersistentStorageRepository.get<{ id: string }[]>(COMMAND_QUEUE_TEMPIDS);
      // Prüfen ob Erstellung existiert
      if (tempIds && tempIds.some((i) => i.id === item.id)) {
        // console.debug(`TempID exist, adding to Cached ${item.id}`);
        // Existiert - Command zurückstellen
        await addItemToCommandCached(item);
        return;
      } else {
        // console.debug(`TempID does not exist, adding to Queue `);
        // Existiert nicht - Command hinzufügen
      }
    }
    if ("id" in item) {
      return commandQueue.addToQueue({ value: item, reference: item.id });
    }
  } catch (error) {
    throw error;
  }
};

const getQueue = async () => {
  try {
    const queue = await PersistentStorageRepository.get<QueueElement<CommandQueueItem<any>>[]>(
      COMMAND_QUEUE,
      undefined
    );
    return queue || [];
  } catch (error) {
    throw error;
  }
};

const initialise = async () => {
  try {
    const oldQueue = await PersistentStorageRepository.get(COMMAND_QUEUE);
    if (oldQueue && Array.isArray(oldQueue)) {
      for (const item of oldQueue) {
        commandQueue.addToQueue(item);
      }
    }
  } catch (error) {
    throw error;
  }
};

export default {
  addToCommandQueue,
  getCommandTempIds,
  initialise,
  getQueue,
  Constants: {
    COMMAND_QUEUE,
    COMMAND_QUEUE_CACHED_,
    COMMAND_QUEUE_TEMPIDS,
    Array: [COMMAND_QUEUE, COMMAND_QUEUE_CACHED_, COMMAND_QUEUE_TEMPIDS],
  },
  findInQueue,
  Events,
  getIds,
};
