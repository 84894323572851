import { XIcon } from "@heroicons/react/outline";
import * as React from "react";
import { AppColors } from "../models/General";
import { Heroicon, Heroicons } from "./Heroicon/Heroicon";

export const MessageBox = React.memo(
  (props: {
    isDisplaying: boolean;
    handleClick: () => any;
    handleClose: () => any;
    handleNeverAgain?: () => any;
    appColors: AppColors;
    text: string;
    clickText: string;
    icon: Heroicon;
  }) => {
    const Icon = React.useMemo(() => Heroicons.Outline[props.icon], [props.icon]);

    return (
      <div
        className={`absolute h-24 p-3 bottom-0 right-0 z-50 container transition-transform transform ${
          props.isDisplaying ? "translate-y-0" : "translate-y-32"
        } duration-200 ease-in-out`}
      >
        <div
          className={`flex h-full w-full md:w-1/2 shadow-2xl rounded-lg justify-center items-stretched cursor-pointer ${
            props.isDisplaying ? "backdrop-blurred " : ""
          } ml-auto bg-white bg-opacity-80`}
        >
          <div className="flex justify-center items-center w-20 h-full p-1 flex-grow-0 flex-shrink-0">
            <Icon
              className={`w-13 h-13 border rounded-lg shadow bg-white text-${props.appColors.backgroundcolor}-500`}
            />
          </div>
          <div
            className="relative flex flex-1 flex-col justify-center items-start p-1 select-none"
            onClick={props.handleClick}
          >
            <div
              className="absolute top-0 right-0 w-9 h-7 p-1 px-2"
              onClick={(ev) => {
                ev.stopPropagation();
                props.handleClose();
              }}
            >
              <XIcon className="w-full h-full text-gray-700" />
            </div>
            <div className="absolute bottom-0 right-0 px-2">
              {props.handleNeverAgain ? (
                <span
                  className="text-xxxs uppercase w-full h-full text-gray-500"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    if (props.handleNeverAgain) props.handleNeverAgain();
                  }}
                >
                  Nichtmehr anzeigen
                </span>
              ) : null}
            </div>
            <span className="text-sm font-semibold text-gray-800 text-left">{props.text}</span>
            <span
              className={`text-xxs uppercase font-semibold text-${props.appColors.backgroundcolor}-400 tracking-wide border-b border-gray-300`}
            >
              {props.clickText}
            </span>
          </div>
        </div>
      </div>
    );
  }
);
