import React, { useRef } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTheme } from "../../hooks/useTheme";

const views = {
  app: () => import("../../App"),
  reservierungWeb: () => import("../ReservierungenWeb"),
  reservierungMobile: () => import("../ReservierungMobile"),
  reservierungTablet: () => import("../ReservierungTablet"),
  nachrichten: () => import("../Nachrichten"),
  nachrichtenView: () => import("../Nachrichten/NachrichtenView"),
  events: () => import("../Events"),
  fetchToy: () => import("../FetchToy/FetchToyView"),
  organisationPageView: () => import("../../pages/Organisation/OrganisationPageView"),
  protokoll: () => import("../Protokoll"),
  reservationCreation: () => import("../../components/ReservationCreation"),
  reservationDetail: () => import("../../components/ReservationDetail"),
  dateTable: () => import("../../components/DateTable"),
  dateTableDrawer: () => import("../../components/DateSelectDrawer"),
  reservierungenView: () => import("../Reservierungen/ReservierungenView"),
  tischeView: () => import("../Tische/TischeView"),
  veranstaltungView: () => import("../Veranstaltung/VeranstaltungView"),
};

export const ViewLoader = (props: {
  props?: any;
  path: keyof typeof views;
  componentName?: string;
  loadingComponent?: (...p: any) => JSX.Element;
}) => {
  const [isLoadingView, setIsLoadingView] = useState(false);

  const componentProps = useMemo(() => props.props, [props.props]);

  const view = useRef<((p: any) => JSX.Element) | null>(null);
  const isCancelled = useRef(false);

  const handleLoadedView = useCallback(() => {
    if (!isCancelled.current) setIsLoadingView(false);
  }, []);

  const loadView = useCallback(async () => {
    try {
      const importedComponent = await views[props.path]();
      if (props.componentName) {
        if (props.componentName in importedComponent) {
          view.current = (importedComponent as any)[props.componentName];
        } else if ("default" in importedComponent) {
          if (props.componentName in importedComponent["default"]) {
            view.current = (importedComponent as any).default[props.componentName];
          } else {
            view.current = (importedComponent as any).default;
          }
        } else {
          throw new Error(`Component ${props.componentName} seems to be missing in ${props.path}`);
        }
      } else {
        view.current = importedComponent as any;
      }
      if (isCancelled.current === false) handleLoadedView();
    } catch (error) {
      throw error;
    }
  }, [props.path, props.componentName, handleLoadedView]);

  useEffect(() => {
    loadView();
    if (isCancelled.current === false) {
      setIsLoadingView(true);
    }

    return () => {
      isCancelled.current = true;
    };
  }, [loadView]);

  const currentView = useMemo(
    () =>
      isLoadingView ? (
        props.loadingComponent ? (
          <props.loadingComponent></props.loadingComponent>
        ) : (
          <Loading></Loading>
        )
      ) : view.current ? (
        <view.current {...componentProps}></view.current>
      ) : null,
    [componentProps, isLoadingView, props]
  );

  return currentView;
};

const Loading = () => {
  const { theme } = useTheme();

  return (
    <div className="flex w-full h-full flex-col justify-center items-center">
      <div className="relative flex w-24 h-24 rounded-full bg-gray-300 shadow-inner p-2 overflow-hidden">
        <div className="absolute flex top-0 left-0 w-full h-full justify-center items-start animate-spin rounded-full shadow-inner overflow-hidden">
          <div className={`h-12 w-12 bg-${theme.backgroundcolor}-500 transform rounded-lg shadow-md`}></div>
        </div>
        <div className="relative flex justify-center items-center text-center leading-none w-full h-full bg-gray-100 rounded-full shadow-inner">
          <span className="text-gray-500 text-xxs uppercase font-semibold tracking-wide">Loading...</span>
        </div>
      </div>
    </div>
  );
};
