import { useIsAuthenticated } from "@azure/msal-react";
import { useLayoutEffect } from "react";
import App from "../../App";
import ClientApi from "../../clientApi/ClientApi";
import ProtocolTracker from "../../components/AppInsights/ProtocolTracker";
import { useAuthenticatedViewModel } from "./AuthenticatedViewModel";
import { AuthenticatedViewTypes } from "./AuthenticatedViewTypes";

const AuthenticatedView = (props: AuthenticatedViewTypes.Props) => {
  const viewModel = useAuthenticatedViewModel(props);

  const isAuthenticated = useIsAuthenticated();

  useLayoutEffect(() => {
    if (isAuthenticated) {
      ClientApi.initialise();
    }
  }, [isAuthenticated]);

  return (
    <>
      <ProtocolTracker />
      <App serviceWorker={props.sw} account={props.account} />
    </>
  );
};

export default AuthenticatedView;
