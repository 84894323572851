import * as React from "react";
import { AppFunctions, AppState } from "../App";
import { GastroEvent, getIconForEvent } from "../models/GastroEvent";
import { AppColors } from "../models/General";
import {
  Reservation,
  ReservationCancelReasons,
  ReservationSatisfactionLevels,
  ReservationStateLocalization,
  ReservationStates,
  walkedInConfirmation,
} from "../models/Reservation";
import { Table } from "../models/Table";
import { CloseArrow } from "./CloseArrow";
import { CustomInput } from "./CustomInput";
import { DetailLine } from "./DetailLine";
import { EventBlock } from "./EventBlock";
import { EventTimeLine } from "./EventTimeline";
import { InformationBar } from "./InformationBar";
import { InformationButton } from "./InformationButton";
import { InformationDetail } from "./InformationDetail";
import { LoadingBar } from "./LoadingBar";
import { Popup, PopupProps } from "./Popup";
import { ReservationButton } from "./ReservationButton";
import { SliderBlock } from "./SliderBlock";
import { SelectableTable } from "./TableSelect";
import { TableSelectDrawer } from "./TableSelectDrawer";
import { TimePicker } from "./TimePicker";
import { ViewHeader } from "./ViewHeader";
import { ViewTopSpace } from "./ViewTopSpace";
import { DateTable } from "./DateTable";
import { GuestAmountEdit } from "./GuestAmountEdit";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Email } from "../models/Email";
import { RefreshIcon } from "@heroicons/react/solid";
import { Heroicons } from "./Heroicon/Heroicon";

type ReservationDetailProps = {
  reservation: Reservation | undefined;
  tableList: Table[];
  appColors: AppColors;
  updateReservation: (updatedReservation: Reservation) => Promise<any>;
  assignTableToReservation: (res: Reservation, tabs: Table[]) => Promise<any>;
  unassignTableToReservation: (res: Reservation) => Promise<any>;
  isLocked: boolean;
  close?: () => any;
  handleTableSelectCall?: () => Promise<any>;
  handleCancel: (res: Reservation, reason: string, reasonPhrase: string) => Promise<any>;
  handleConfirm: (res: Reservation, shouldSendEmail: boolean, email?: Email) => Promise<any>;
  handleReschedule: (res: Reservation, isoDate: string) => Promise<any>;
  handleWalkedInWithReservation: (
    res: Reservation,
    walkedInConfirmation: walkedInConfirmation,
    tables: Table[]
  ) => Promise<any>;
  handleGuestAmountChange: (res: Reservation, guestAmount: Reservation["guestAmount"]) => Promise<any>;
  handleLeave: (
    res: Reservation,
    leaveOptions: {
      satisfaction: Reservation["satisfaction"];
      leaveNotice: Reservation["leaveNotice"];
      leaveDateTime: Reservation["leaveDateTime"];
    }
  ) => Promise<void>;
  getEventsForReservation: (res: Reservation) => Promise<{ events: GastroEvent[] }>;
  getSummary: ({ year, month }: { year: number; month: number }, cb: any) => any;
  getEmail: (reservation: Reservation) => Promise<Email>;
  sendEmail: (email: Email, resId: Reservation["id"]) => Promise<void>;
  appIsStandalone: boolean;
  appIsMobile: boolean;
  appReservations?: AppState["appReservations"];
  appEvents?: AppState["appEvents"];
  appSummary?: AppState["appSummary"];
  setNewReservationPrimer: AppFunctions["setNewReservationPrimer"];
};

type editableFields = "date" | "guestAmount" | "personalInformation";

const emailButtons = [{ label: "Keine Email" }, { label: "Mit Email" }];

export const ReservationDetail = ({
  getEventsForReservation,
  reservation,
  getEmail,
  ...props
}: ReservationDetailProps) => {
  const hasReservation = React.useMemo(() => reservation !== undefined, [reservation]);

  const [listRef, setListRef] = React.useState<HTMLDivElement | null>(null);
  const [events, setEvents] = React.useState<GastroEvent[]>(
    props.appEvents && reservation
      ? [...props.appEvents]
          .sort((a, b) => +new Date(b.timestamp) - +new Date(a.timestamp))
          .filter((ev) => ev.aggregateId === reservation!.id)
      : []
  );
  const [, setIsLoadingEvents] = React.useState(true);
  const [hasRefreshedEvents, setHasRefreshedEvents] = React.useState(false);
  const [eventRefs, setEventRefs] = React.useState<HTMLDivElement[]>([]);

  const [scrollAnchor, setScrollAnchor] = React.useState<HTMLDivElement | null>(null);

  const [isDrawerVisible, setIsDrawerVisible] = React.useState(false);

  const [isEditing, setIsEditing] = React.useState<editableFields | null>(null);
  const [editedValues, setEditedValues] = React.useState<Partial<Reservation> | null>(null);
  const editableFieldConfig: { [key in editableFields]: { ratio: PopupProps["ratio"]; title: PopupProps["title"] } } = {
    date: { ratio: "1x9", title: "Datum anpassen" },
    guestAmount: { ratio: "7x3", title: "Gästeanzahl anpassen" },
    personalInformation: { ratio: "3x7", title: "Informationen anpassen" },
  };

  const [isGettingEvents, setIsGettingEvents] = React.useState(false);

  const [isCancelling, setIsCancelling] = React.useState(false);
  const [cancelReasonIndex, setCancelReasonIndex] = React.useState<number>(0);
  const [cancelReasonPhrase, setCancelReasonPhrase] = React.useState<string>("");

  const [isLeaving, setIsLeaving] = React.useState(false);
  const [leaveNotice, setLeaveNotice] = React.useState("");
  const [satisfactionIndex, setSatisfactionIndex] = React.useState<number>(0);

  const [isWalkingIn, setIsWalkingIn] = React.useState(false);
  const [walkInTables, setWalkInTables] = React.useState<Reservation["tables"] | null>(
    reservation ? reservation.tables : []
  );
  const [walkInGuestAmount, setWalkInGuestAmount] = React.useState(
    reservation ? reservation.guestAmount : { adultAmount: 0, childAmount: 0 }
  );
  const [walkInTime, setWalkInTime] = React.useState(
    new Date().toLocaleString("de-de", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    })
  );

  React.useEffect(() => {
    if (reservation?.guestAmount) {
      setWalkInGuestAmount(() => {
        setWalkInTables(() => {
          return reservation.tables ? reservation.tables ?? [] : [];
        });

        return reservation.guestAmount;
      });
    }
  }, [reservation?.guestAmount, reservation?.tables]);

  const [isShowingConfirmation, setIsShowingConfirmation] = React.useState(false);
  const [shouldSendEmail, setShouldSendEmail] = React.useState(false);
  const [email, setEmail] = React.useState<Email | null>(null);
  const [isEditingEmail, setIsEditingEmail] = React.useState<boolean>(false);

  const _handleGetEventsForReservation = React.useCallback(
    async (reservation: Reservation) => {
      try {
        const events = await getEventsForReservation(reservation);
        return events;
      } catch (error) {
        throw error;
      }
    },
    [getEventsForReservation]
  );

  React.useEffect(() => {
    if (!hasRefreshedEvents && reservation && !isGettingEvents) {
      setIsGettingEvents(() => {
        _handleGetEventsForReservation(reservation)
          .then(({ events }) =>
            setEvents(() => {
              setHasRefreshedEvents(true);
              return events.sort((a, b) => +new Date(b.timestamp) - +new Date(a.timestamp));
            })
          )
          .catch((error) => {
            setIsLoadingEvents(() => {
              setTimeout(() => {
                setIsGettingEvents(false);
              }, 1500);

              return false;
            });
          });
        return true;
      });
    } else if (events.length > 0) {
      setIsLoadingEvents(false);
    }
  }, [
    _handleGetEventsForReservation,
    events,
    getEventsForReservation,
    hasRefreshedEvents,
    isGettingEvents,
    reservation,
  ]);

  React.useEffect(() => {
    setIsLoadingEvents(() => {
      setIsGettingEvents(() => {
        setHasRefreshedEvents(false);
        return false;
      });
      return true;
    });
  }, [reservation]);

  React.useEffect(() => {
    if (listRef) {
      disableBodyScroll(listRef, { allowTouchMove: (el) => el.id === "event" });
      return () => {
        enableBodyScroll(listRef);
      };
    }
  }, [listRef]);

  const _handleOnFollowUpReservation = React.useCallback(async () => {
    try {
      if (reservation) {
        if (props.close) props.close.call(undefined);
        const { name, email, phoneNumber, durationOfStay } = reservation;
        await props.setNewReservationPrimer.call(undefined, {
          name,
          email,
          phoneNumber,
          durationOfStay,
          requestChannel: "Followup",
          cause: undefined,
        });
      }
    } catch (error) {
      throw error;
    }
  }, [props.close, props.setNewReservationPrimer, reservation]);

  const _handleScrollToEventDetail = React.useCallback(
    (idx: number) => {
      return eventRefs && eventRefs[idx]
        ? eventRefs[idx].scrollIntoView({ behavior: "smooth", block: "nearest" })
        : null;
    },
    [eventRefs]
  );

  const _handleOpenTableSelect = React.useCallback(() => {
    setIsDrawerVisible(true);
  }, []);

  const _handleCloseTableSelect = React.useCallback(() => {
    setIsDrawerVisible(false);
  }, []);

  const _handleTableSelection = React.useCallback(
    async (tables: Table[] | null) => {
      try {
        if (isWalkingIn) {
          _handleCloseTableSelect();
          setWalkInTables(() => {
            return tables;
          });
        } else {
          if (reservation) {
            if (tables && tables.length > 0) {
              props.assignTableToReservation(reservation, tables);
            } else {
              props.unassignTableToReservation(reservation);
            }
          }
        }
      } catch (error) {
        throw error;
      }
    },
    [_handleCloseTableSelect, isWalkingIn, props, reservation]
  );

  const _handleEmail = React.useCallback(
    async (mail?: string) => {
      try {
        if (reservation) {
          setIsEditingEmail(true);
          const emailResp = await getEmail(reservation);
          setEmail(emailResp);
        }
      } catch (error) {
        throw error;
      }
    },
    [getEmail, reservation]
  );

  const _handleShouldSendEmail = React.useCallback(
    async (should: number) => {
      try {
        if (reservation && !!should) {
          setShouldSendEmail(true);
          const emailResp = await getEmail(reservation);
          setEmail(emailResp);
        } else {
          setShouldSendEmail(false);
        }
      } catch (error) {
        throw error;
      }
    },
    [getEmail, reservation]
  );

  const _handleCall = React.useCallback(
    (number?: string) => {
      if (number && typeof number === "string") {
        window.location.href = `tel:${number || reservation!.phoneNumber}`;
      } else {
        window.location.href = `tel:${reservation!.phoneNumber}`;
      }
    },
    [reservation]
  );

  const _handleSettingTime = React.useCallback((oldDate: string, newTime: string) => {
    if (newTime.length >= 6) {
      const tempDate = new Date(oldDate);
      tempDate.setHours(+newTime.slice(0, 2));
      tempDate.setMinutes(+newTime.slice(3, 5));
      return tempDate.toISOString();
    } else return oldDate;
  }, []);

  const _handleWalkInGuestAmountAdd = React.useCallback(
    (key: keyof walkedInConfirmation["guestAmount"]) =>
      setWalkInGuestAmount((curr) => {
        return { ...curr, [key]: curr[key] + 1 };
      }),
    []
  );

  const _handleWalkInGuestAmountMinus = React.useCallback(
    (key: keyof walkedInConfirmation["guestAmount"]) =>
      setWalkInGuestAmount((curr) => {
        if (curr[key] !== 0) {
          return { ...curr, [key]: curr[key] - 1 };
        } else return curr;
      }),
    []
  );

  const _handleConfirm = React.useCallback(() => {
    if (reservation) {
      if (shouldSendEmail && email) {
        props.handleConfirm(reservation, shouldSendEmail, email);
      } else {
        props.handleConfirm(reservation, shouldSendEmail);
      }
    }
  }, [email, props, reservation, shouldSendEmail]);

  const transition: React.CSSProperties = React.useMemo(() => ({ transition: "all 300ms" }), []);

  const iconsForEvents = React.useMemo(() => getIconForEvent({ height: "90%", width: "90%", color: "white" }), []);

  const backgroundColor = React.useMemo(
    () => `${props.appColors.backgroundcolor}-700`,
    [props.appColors.backgroundcolor]
  );

  const handleValueChange = React.useCallback((key: keyof Partial<Reservation>) => {
    return (value: Partial<Reservation>[keyof Partial<Reservation>]) => {
      setEditedValues((curr) => ({ ...curr, [key]: value }));
    };
  }, []);

  const handleNoteChange = React.useMemo(() => handleValueChange("note"), [handleValueChange]);
  const handleNameChange = React.useMemo(() => handleValueChange("name"), [handleValueChange]);
  const handleEmailChange = React.useMemo(() => handleValueChange("email"), [handleValueChange]);
  const handlePhoneNumberChange = React.useMemo(() => handleValueChange("phoneNumber"), [handleValueChange]);

  const changeCancelReasonPhrase = React.useCallback((txt: string) => setCancelReasonPhrase(txt), []);

  const changeLeaveNotice = React.useCallback((txt) => setLeaveNotice(txt), []);

  const availableHours = React.useMemo(() => {
    if (!reservation) return [];
    const arrival = new Date(reservation?.dateOfArrival);
    const hours = arrival.toLocaleTimeString("de-de", { hour: "2-digit", minute: "2-digit" }).slice(0, 2);
    return [+hours - 1 + "", +hours + "", +hours + 1 + "", +hours + 2 + ""];
  }, [reservation]);
  const handleTimeChange = React.useCallback((time: string) => {
    if (time) {
      setWalkInTime((curr) => {
        return time;
      });
    }
  }, []);

  const changeEmailSubject = React.useCallback(
    (val?: string) => {
      setEmail({ ...email, subject: val || "" } as Email);
    },
    [email]
  );

  const changeEmailContent = React.useCallback(
    (val?: string) => {
      setEmail({ ...email, content: val || "" } as Email);
    },
    [email]
  );

  const _eventBlocks = React.useMemo(() => {
    return (events || []).map((event, index) => (
      <EventBlock
        key={event.id}
        appColors={props.appColors}
        event={event}
        icon={iconsForEvents[event.event]}
        setRef={(ref) => {
          if (ref !== null && (eventRefs[event.version] === null || eventRefs[event.version] === undefined)) {
            setEventRefs((refs) => {
              let tempRefs = [...refs];
              tempRefs[event.version] = ref;
              return tempRefs;
            });
          }
        }}
      ></EventBlock>
    ));
  }, [eventRefs, events, iconsForEvents, props.appColors]);

  return hasReservation && reservation ? (
    <div className="relative flex flex-1 flex-col justify-start items-start min-h-0 h-full max-h-full max-w-full overflow-hidden bg-red-500">
      <TableSelectDrawer
        appColors={props.appColors}
        handleClose={_handleCloseTableSelect}
        handleSelection={_handleTableSelection}
        isDrawerVisible={isDrawerVisible}
        isLocked={props.isLocked}
        reservation={reservation}
        tableList={props.tableList}
        transition={transition}
        appReservations={props.appReservations}
        preSelectedTables={isWalkingIn ? (walkInTables as Table[]) || undefined : (reservation.tables as Table[])}
        spaceOnBottom={props.appIsStandalone}
      ></TableSelectDrawer>
      <Popup
        beingDisplayed={isCancelling}
        close={() => setIsCancelling(false)}
        title={"Absagen"}
        ratio="5x5"
        appIsStandalone={props.appIsStandalone}
      >
        <div className="flex justify-around items-center flex-col w-full h-full bg-gray-200 rounded-lg overflow-hidden p-2 shadow-inner">
          <div className="flex w-full h-10">
            <SliderBlock
              buttons={ReservationCancelReasons.map((reas) => ({ label: reas }))}
              onSelect={(idx) => setCancelReasonIndex(idx)}
              selectedIndex={cancelReasonIndex}
              appColors={props.appColors}
              textSize={"xxs"}
              containerClasses={"rounded-lg bg-white shadow"}
            ></SliderBlock>
          </div>
          <div
            className="flex flex-2 w-full shadow-md mt-2 rounded-lg overflow-hidden"
            style={{
              minHeight: "3.5rem",
            }}
          >
            <CustomInput
              error={false}
              handleValueChange={changeCancelReasonPhrase}
              inputValue={cancelReasonPhrase}
              label={"Beschreibung"}
              size={"full"}
              selectedColor={props.appColors.backgroundcolor}
              alreadyFocused
            ></CustomInput>
          </div>
          <div className="flex justify-end items-center w-full h-11 mt-2">
            <ReservationButton
              unlockedHeight
              color={"red"}
              isLocked={props.isLocked}
              transition={transition}
              onClickHandler={async () => {
                if (!props.isLocked) {
                  props.handleCancel(reservation, ReservationCancelReasons[cancelReasonIndex], cancelReasonPhrase);
                }
              }}
              additionalClasses={"rounded-lg"}
            >
              Absagen
            </ReservationButton>
          </div>
        </div>
      </Popup>
      <Popup
        beingDisplayed={isLeaving}
        close={() => setIsLeaving(false)}
        title={"Kunde gegangen"}
        ratio="5x5"
        appIsStandalone={props.appIsStandalone}
      >
        <div className="flex justify-around items-center flex-col w-full h-full bg-gray-200 rounded-lg overflow-hidden p-2 shadow-inner">
          <div className="flex w-full h-10">
            <SliderBlock
              buttons={ReservationSatisfactionLevels.map((level) => ({ label: level }))}
              onSelect={(idx) => setSatisfactionIndex(idx)}
              selectedIndex={satisfactionIndex}
              appColors={props.appColors}
              textSize={"sm"}
              containerClasses={"rounded-lg bg-white shadow"}
              relativeSizedButtons
            ></SliderBlock>
          </div>
          <div
            className="flex flex-2 w-full shadow-md mt-2 rounded-lg overflow-hidden"
            style={{
              minHeight: "3.5rem",
            }}
          >
            <CustomInput
              error={false}
              handleValueChange={changeLeaveNotice}
              inputValue={leaveNotice}
              label={"Beschreibung"}
              size={"full"}
              selectedColor={props.appColors.backgroundcolor}
              alreadyFocused
            >
              {" "}
            </CustomInput>
          </div>
          <div className="flex justify-end items-center w-full h-11 mt-2">
            <ReservationButton
              unlockedHeight
              color={"red"}
              isLocked={props.isLocked}
              transition={transition}
              onClickHandler={async () => {
                if (!props.isLocked) {
                  props.handleLeave(reservation, {
                    leaveDateTime: new Date().toISOString(),
                    leaveNotice: leaveNotice,
                    satisfaction: satisfactionIndex + 1,
                  });
                }
              }}
              additionalClasses={"rounded-lg"}
            >
              Ist gegangen
            </ReservationButton>
          </div>
        </div>
      </Popup>
      <Popup
        beingDisplayed={isWalkingIn}
        close={() => setIsWalkingIn(false)}
        title={"Angekommen"}
        ratio="4x6"
        appIsStandalone={props.appIsStandalone}
      >
        <div className="flex justify-around items-center flex-col w-full h-full bg-gray-200 rounded-lg overflow-auto p-2 shadow-inner">
          <span className={`text-xxs uppercase font-semibold tracking-wide text-left w-full text-gray-500 px-1`}>
            Tische
          </span>
          <div className="flex w-full h-14 overflow-hidden rounded-lg shadow-md">
            <div className="flex w-full h-full overflow-auto bg-white rounded-lg px-1" onClick={_handleOpenTableSelect}>
              {isWalkingIn && walkInTables && walkInTables.length >= 1 ? (
                (walkInTables || []).map((tab) => (
                  <div key={tab.id} className="flex h-full w-20 py-1 pr-1 flex-shrink-0">
                    <SelectableTable
                      table={{ ...tab } as Table}
                      isGrayed={false}
                      appColors={props.appColors}
                      onClick={() => null}
                      unfixedSize
                      onReservationClick={() => null}
                    ></SelectableTable>
                  </div>
                ))
              ) : (
                <span
                  className={`flex justify-center items-center w-full h-full text-${props.appColors.backgroundcolor}-700 text-xs underline`}
                >
                  Kein Tisch
                </span>
              )}
            </div>
          </div>
          <span className={`text-xxs uppercase font-semibold tracking-wide text-left w-full text-gray-500 px-1 mt-2`}>
            Uhrzeit
          </span>
          <div className={`flex flex-col h-21 w-full justify-center items-center py-1`}>
            {isWalkingIn ? (
              <TimePicker
                height="10"
                textSize="xl"
                textColor={"gray"}
                appColors={props.appColors}
                selectTime={handleTimeChange}
                selectedTime={walkInTime}
                availableHours={availableHours}
              ></TimePicker>
            ) : null}
          </div>
          <span className={`text-xxs uppercase font-semibold tracking-wide text-left w-full text-gray-500 px-1 pb-1`}>
            Gästeanzahl
          </span>
          <GuestAmountEdit
            handleGuestAmountAdd={_handleWalkInGuestAmountAdd}
            handleGuestAmountMinus={_handleWalkInGuestAmountMinus}
            appColors={props.appColors}
            guestAmount={walkInGuestAmount}
          ></GuestAmountEdit>

          <div className="flex justify-end items-center w-full h-11 mt-2">
            <ReservationButton
              unlockedHeight
              color={"blue"}
              isLocked={props.isLocked}
              transition={transition}
              onClickHandler={async () => {
                if (!props.isLocked) {
                  const date = new Date(reservation.dateOfArrival);
                  const [hours, minutes, seconds] = walkInTime.split(":");
                  date.setHours(+hours);
                  date.setMinutes(+minutes);
                  date.setSeconds(+seconds);
                  props.handleWalkedInWithReservation(
                    reservation,
                    {
                      arrivalDateTime: date.toISOString(),
                      guestAmount: walkInGuestAmount,
                      tableIds: walkInTables ? walkInTables.map((tab) => tab.id) : [],
                    },
                    (walkInTables as Table[]) || []
                  );
                }
              }}
              additionalClasses={"rounded-lg"}
            >
              Erschienen
            </ReservationButton>
          </div>
        </div>
      </Popup>
      <Popup
        beingDisplayed={isShowingConfirmation}
        close={() => setIsShowingConfirmation(false)}
        title={"Bestätigung"}
        ratio={shouldSendEmail ? "1x9" : "7x3"}
        appIsStandalone={props.appIsStandalone}
      >
        <div className="flex justify-start items-center flex-col w-full h-full bg-gray-200 rounded-lg overflow-auto p-2 shadow-inner">
          <span className={`text-xxs uppercase font-semibold tracking-wide text-left w-full text-gray-500 px-1`}>
            Bestätigungsart
          </span>
          <SliderBlock
            buttons={emailButtons}
            onSelect={_handleShouldSendEmail}
            selectedIndex={shouldSendEmail ? 1 : 0}
            appColors={props.appColors}
            textSize={"sm"}
            containerClasses={"rounded-lg bg-white shadow"}
          />
          <DetailLine
            handleOnClick={() => {
              setIsEditing("personalInformation");
            }}
            appColors={props.appColors}
            value={reservation.email || "--"}
            className="mt-2"
            icon="MailIcon"
          />
          {shouldSendEmail ? (
            email ? (
              <div className="relative flex flex-1 flex-col bg-gray-200 rounded-lg w-full mt-1 shadow overflow-hidden">
                <span
                  className={`text-xxs uppercase font-semibold tracking-wide text-left w-full text-gray-500 px-1 mt-2`}
                >
                  Email
                </span>
                <CustomInput
                  selectedColor={props.appColors.backgroundcolor}
                  inputValue={email?.subject || ""}
                  handleValueChange={changeEmailSubject}
                  onRemove={changeEmailSubject}
                  label={"Betreff"}
                  error={false}
                ></CustomInput>
                <CustomInput
                  size="full"
                  selectedColor={props.appColors.backgroundcolor}
                  inputValue={email?.content || ""}
                  handleValueChange={changeEmailContent}
                  onRemove={changeEmailContent}
                  label={"Nachricht"}
                  error={false}
                  className="mt-1"
                ></CustomInput>
              </div>
            ) : (
              <div className="relative flex flex-col flex-1 bg-gray-200 rounded-lg w-full mt-1 overflow-hidden justify-center items-center">
                <RefreshIcon className="spin w-8 h-8 p-1 text-gray-600" />
                <span className="text-xxs text-gray-600 font-semibold tracking-wide uppercase">
                  Lade Email Inhalt...
                </span>
              </div>
            )
          ) : // <div className="relative flex flex-1 flex-col bg-gray-200 rounded-lg w-full mt-1 shadow overflow-hidden justify-center items-center">
          //   Not implemented
          // </div>
          null}
        </div>
        <div className="flex justify-end items-center w-full h-11 mt-2">
          <ReservationButton
            unlockedHeight
            color="green"
            isLocked={shouldSendEmail ? email === null && email === undefined : props.isLocked}
            transition={transition}
            onClickHandler={_handleConfirm}
            additionalClasses="rounded-lg"
          >
            Bestätigen
          </ReservationButton>
        </div>
      </Popup>
      <Popup
        beingDisplayed={isEditingEmail}
        close={() => setIsEditingEmail(false)}
        title={"Bestätigungs-Email senden"}
        ratio={isEditing ? "5x5" : "1x9"}
        appIsStandalone={props.appIsStandalone}
      >
        {email ? (
          <div className="flex justify-start items-center flex-col w-full h-full bg-gray-200 rounded-lg overflow-auto p-2 shadow-inner">
            <span className={`text-xxs uppercase font-semibold tracking-wide text-left w-full text-gray-500 px-1`}>
              Empfänger
            </span>
            <DetailLine
              handleOnClick={() => {
                setIsEditing("personalInformation");
              }}
              appColors={props.appColors}
              value={email?.recipients.join(", ") || "--"}
              className=""
              icon="MailIcon"
            />
            <span className={`text-xxs uppercase font-semibold tracking-wide text-left w-full text-gray-500 px-1 mt-2`}>
              Email
            </span>
            <CustomInput
              selectedColor={props.appColors.backgroundcolor}
              inputValue={email?.subject || ""}
              handleValueChange={changeEmailSubject}
              onRemove={changeEmailSubject}
              label={"Betreff"}
              error={false}
            ></CustomInput>
            <CustomInput
              size="full"
              selectedColor={props.appColors.backgroundcolor}
              inputValue={email?.content || ""}
              handleValueChange={changeEmailContent}
              onRemove={changeEmailContent}
              label={"Nachricht"}
              error={false}
              className="mt-1"
            ></CustomInput>
          </div>
        ) : (
          <div className="flex justify-start items-center flex-col w-full h-full bg-gray-200 rounded-lg overflow-auto p-2 shadow-inner">
            <div className="flex w-full h-full justify-center items-center flex-col">
              <RefreshIcon className="spin w-8 h-8 p-1 text-gray-600" />
              <span className="text-xxs text-gray-600 font-semibold tracking-wide uppercase">Lade Email Inhalt...</span>
            </div>
          </div>
        )}
        <div className="flex justify-end items-center w-full h-11 mt-2">
          <ReservationButton
            unlockedHeight
            color={"green"}
            isLocked={!email}
            transition={transition}
            onClickHandler={() => {
              if (email && reservation) {
                props.sendEmail(email, reservation.id).then(() => {
                  setIsEditingEmail(false);
                });
              }
            }}
            additionalClasses={"rounded-lg"}
          >
            Email senden
          </ReservationButton>
        </div>
      </Popup>
      <Popup
        beingDisplayed={isEditing !== null}
        close={() =>
          setEditedValues((curr) => {
            setIsEditing(null);
            return null;
          })
        }
        {...(isEditing ? editableFieldConfig[isEditing] : { ratio: "5x5", title: "" })}
        appIsStandalone={props.appIsStandalone}
      >
        <div className="flex justify-around items-center flex-col w-full max-h-full min-h-full h-full bg-gray-200 rounded-lg overflow-auto p-2 shadow-inner">
          {isEditing === "date" ? (
            <>
              <span
                className={`text-xxs uppercase font-semibold tracking-wide text-left w-full text-gray-500 px-1 mt-2`}
              >
                Uhrzeit
              </span>
              <div className={`flex flex-col h-21 w-full justify-center items-center py-1`}>
                <TimePicker
                  height="10"
                  textSize="xl"
                  textColor={"gray"}
                  appColors={props.appColors}
                  selectTime={(time) => {
                    if (time) {
                      setEditedValues((curr) => {
                        let newDate = "";
                        if (curr && curr.dateOfArrival) {
                          newDate = _handleSettingTime(curr.dateOfArrival, time);
                        } else {
                          newDate = _handleSettingTime(reservation.dateOfArrival, time);
                        }
                        return { ...curr, dateOfArrival: newDate };
                      });
                    }
                  }}
                  selectedTime={
                    new Date(
                      editedValues && editedValues.dateOfArrival
                        ? editedValues.dateOfArrival
                        : reservation.dateOfArrival
                    ).toLocaleString("de-de", { hour: "2-digit", minute: "2-digit" }) + ":00"
                  }
                  availableHours={availableHours}
                ></TimePicker>
              </div>
              <span className={`text-xxs uppercase font-semibold tracking-wide text-left w-full text-gray-500 px-1`}>
                Datum
              </span>
              <div className="flex flex-2 w-full mt-2 rounded-lg overflow-auto min-h-0">
                <DateTable
                  size={props.appIsMobile || props.appIsStandalone ? "full" : "dense"}
                  appColors={props.appColors}
                  getSummary={props.getSummary}
                  selectDate={(date) => {
                    setEditedValues((curr) => {
                      const tempDate = new Date(date);
                      let newDate = "";
                      if (curr && curr.dateOfArrival) {
                        const currDate = new Date(curr.dateOfArrival);
                        tempDate.setHours(currDate.getHours());
                        tempDate.setMinutes(currDate.getMinutes());
                        tempDate.setSeconds(0);
                        tempDate.setMilliseconds(0);
                        newDate = tempDate.toISOString();
                      } else {
                        newDate = tempDate.toISOString();
                      }
                      return { ...curr, dateOfArrival: newDate };
                    });
                  }}
                  appIsStandalone={props.appIsStandalone}
                  selectedDate={
                    editedValues && editedValues.dateOfArrival
                      ? editedValues.dateOfArrival.slice(0, 10)
                      : reservation.dateOfArrival.slice(0, 10)
                  }
                  shouldLoadSummary={true}
                ></DateTable>
              </div>
            </>
          ) : null}
          {isEditing === "personalInformation" ? (
            <>
              <div className="flex w-full py-1 h-14 flex-shrink-0">
                <CustomInput
                  error={false}
                  handleValueChange={handleNameChange}
                  inputValue={editedValues && editedValues.name ? editedValues.name : reservation.name}
                  label={"Name"}
                  selectedColor={props.appColors.backgroundcolor}
                  alreadyFocused
                ></CustomInput>
              </div>
              <div className="flex w-full py-1 h-14 flex-shrink-0">
                <CustomInput
                  error={false}
                  handleValueChange={handleEmailChange}
                  inputValue={
                    editedValues && editedValues.email ? editedValues.email : reservation.email ? reservation.email : ""
                  }
                  label={"Email"}
                  selectedColor={props.appColors.backgroundcolor}
                  alreadyFocused
                ></CustomInput>
                <div className="flex justify-center items-center w-12 h-12 pl-1 flex-shrink-0">
                  <Heroicons.Solid.MailIcon
                    className={`w-9 h-9 text-white bg-${props.appColors.backgroundcolor}-500 shadow-md rounded-md p-2`}
                    onClick={() => {
                      _handleEmail(
                        editedValues && editedValues.email
                          ? editedValues.email
                          : reservation.email
                          ? reservation.email
                          : ""
                      );
                    }}
                  />
                </div>
              </div>
              <div className="flex w-full py-1 h-14 flex-shrink-0">
                <CustomInput
                  error={false}
                  handleValueChange={handlePhoneNumberChange}
                  inputValue={
                    editedValues && editedValues.phoneNumber
                      ? editedValues.phoneNumber
                      : reservation.phoneNumber
                      ? reservation.phoneNumber
                      : ""
                  }
                  label={"Telefon"}
                  selectedColor={props.appColors.backgroundcolor}
                  alreadyFocused
                ></CustomInput>
                <div className="flex justify-center items-center w-12 h-12 pl-1 flex-shrink-0">
                  <Heroicons.Solid.PhoneIcon
                    className={`w-9 h-9 text-white bg-${props.appColors.backgroundcolor}-500 shadow-md rounded-md p-2`}
                    onClick={() =>
                      _handleCall(
                        editedValues && editedValues.phoneNumber
                          ? editedValues.phoneNumber
                          : reservation.phoneNumber
                          ? reservation.phoneNumber
                          : ""
                      )
                    }
                  />
                </div>
              </div>
              <div
                className="flex flex-1 py-1 w-full"
                style={{
                  minHeight: "3.5rem",
                }}
              >
                <CustomInput
                  error={false}
                  handleValueChange={handleNoteChange}
                  inputValue={
                    editedValues && editedValues.note ? editedValues.note : reservation.note ? reservation.note : ""
                  }
                  label={"Notiz"}
                  size={"full"}
                  selectedColor={props.appColors.backgroundcolor}
                  alreadyFocused
                ></CustomInput>
              </div>
            </>
          ) : null}
          {isEditing === "guestAmount" ? (
            <GuestAmountEdit
              handleGuestAmountAdd={(type) => {
                setEditedValues((curr) => {
                  let guestAmount = reservation.guestAmount;
                  if (curr && curr.guestAmount) {
                    guestAmount = { ...curr.guestAmount, [type]: curr.guestAmount[type] + 1 };
                  } else {
                    guestAmount = { ...reservation.guestAmount, [type]: reservation.guestAmount[type] + 1 };
                  }
                  return { ...curr, guestAmount };
                });
              }}
              handleGuestAmountMinus={(type) => {
                setEditedValues((curr) => {
                  let guestAmount = reservation.guestAmount;
                  if (curr && curr.guestAmount) {
                    guestAmount = { ...curr.guestAmount, [type]: curr.guestAmount[type] - 1 };
                  } else {
                    guestAmount = { ...reservation.guestAmount, [type]: reservation.guestAmount[type] - 1 };
                  }
                  if (guestAmount[type] < 0) {
                    guestAmount[type] = 0;
                  }
                  return { ...curr, guestAmount };
                });
              }}
              appColors={props.appColors}
              guestAmount={
                editedValues && editedValues.guestAmount ? editedValues.guestAmount : reservation.guestAmount
              }
            ></GuestAmountEdit>
          ) : null}
          <div className="flex justify-end items-center w-full h-11 mt-2">
            <ReservationButton
              unlockedHeight
              color={"green"}
              isLocked={props.isLocked}
              transition={transition}
              onClickHandler={async () => {
                if (!props.isLocked) {
                  setEditedValues((curr) => {
                    if (curr) {
                      if (curr.dateOfArrival) {
                        props.handleReschedule(reservation, curr.dateOfArrival);
                      }
                      if (curr.name || curr.email || curr.phoneNumber || curr.note) {
                        props.updateReservation({ ...reservation, ...curr });
                      }
                      if (curr.guestAmount) {
                        props.handleGuestAmountChange(reservation, curr.guestAmount);
                      }
                    }
                    setIsEditing((isEdit) => {
                      console.log(curr);
                      return null;
                    });
                    return null;
                  });
                }
              }}
              additionalClasses={"rounded-lg"}
            >
              Speichern
            </ReservationButton>
          </div>
        </div>
      </Popup>
      {props.close ? (
        <ViewHeader appColors={props.appColors}>
          <span className="flex flex-1 h-full justify-start items-center" onClick={props.close}>
            <CloseArrow color="white" appColors={props.appColors} close={() => null}></CloseArrow>
            <span>Zurück</span>
          </span>
        </ViewHeader>
      ) : null}
      <ViewTopSpace
        appColors={props.appColors}
        sideButton={{
          label:
            reservation.tables && reservation.tables.length >= 1
              ? `${
                  reservation.tables.length === 1
                    ? "Tisch " + reservation.tables[0].name
                    : reservation.tables.length + " Tische"
                }`
              : "Kein Tisch",
          onClick: () => {
            if (!props.isLocked) {
              _handleOpenTableSelect();
            }
          },
        }}
        backgroundIconProps={{ width: "55%", height: "60%" }}
        backgroundIcon="BookmarkIcon"
        backgroundIconPosition={{ transform: "rotate(15deg)", top: "0.5rem", left: "-1.75rem" }}
      >
        <div className="flex flex-1 flex-col w-full h-full justify-center items-start">
          <h3
            className={`flex flex-grow-0 flex-shrink-0 h-8 w-full text-${props.appColors.backgroundcolor}-100 items-center px-3 text-xl font-medium`}
          >
            <span className="truncate">{reservation.name}</span>
          </h3>
          <div className="flex flex-row space-x-3 pl-3">
            <InformationDetail appColors={props.appColors} icon="CalendarIcon">
              {new Date(reservation.dateOfArrival).toLocaleString("de-de", {
                month: "numeric",
                weekday: "short",
                day: "2-digit",
              })}
            </InformationDetail>
            <InformationDetail appColors={props.appColors} icon="ClockIcon">
              {
                new Date(reservation.dateOfArrival)
                  .toLocaleString("de-de", {
                    month: "numeric",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                  .split(",")[1]
              }
            </InformationDetail>
            {reservation.guestAmount && reservation.guestAmount.adultAmount <= 0 ? (
              <InformationDetail appColors={props.appColors} icon="UsersIcon">
                {typeof reservation.guestAmount === "string" || typeof reservation.guestAmount === "number"
                  ? `${reservation.guestAmount} Pers.`
                  : "Keine Angabe"}
              </InformationDetail>
            ) : null}
            {(reservation.guestAmount.adultAmount && reservation.guestAmount.adultAmount > 0) ||
            (reservation.guestAmount.childAmount && reservation.guestAmount.childAmount > 0) ? (
              <InformationDetail appColors={props.appColors} icon="UsersIcon">
                {reservation.guestAmount.childAmount
                  ? `${reservation.guestAmount.adultAmount} + ${reservation.guestAmount.childAmount}`
                  : reservation.guestAmount.adultAmount}
              </InformationDetail>
            ) : null}
          </div>
        </div>
      </ViewTopSpace>
      <div className={`inline-flex flex-grow-0 flex-shrink-0 min-h-0 pt-1 w-full bg-${backgroundColor}`}>
        <div
          className="flex flex-shrink-0 h-20 justify-start items-center overflow-hidden w-full max-w-full z-10"
          id="event"
        >
          {events.length <= 0 ? (
            <LoadingBar color={props.appColors.backgroundcolor}></LoadingBar>
          ) : (
            <EventTimeLine
              events={events}
              appColors={props.appColors}
              transition={transition}
              handleScrollTo={_handleScrollToEventDetail}
            ></EventTimeLine>
          )}
        </div>
      </div>
      <div className={`flex-shrink-0 h-3 bg-${backgroundColor} w-full`}></div>
      <div className={`flex h-2 flex-grow-0 flex-shrink-0 w-full bg-${backgroundColor} px-1`}>
        <div className="flex w-full h-full rounded-t-lg bg-white justify-around items-center  shadow-inner"></div>
      </div>
      <div className={`relative flex flex-col px-1 min-h-0 overflow-auto w-full bg-${backgroundColor} h-full`}>
        <div className="relative flex flex-col w-full h-full bg-white">
          <div
            className="relative flex flex-1 flex-col w-full overflow-auto min-h-0 h-full max-h-full"
            ref={setListRef}
          >
            <div className="relative inline-flex flex-col flex-shrink-0 bg-white min-h-full">
              <div
                ref={(ref) => (ref !== null && scrollAnchor === null ? setScrollAnchor(ref) : null)}
                className="absolute top-0"
              ></div>
              <div className="flex sticky top-0 w-full h-8 justify-between items-start px-1 pr-2 z-10 border-b bg-white">
                <span
                  className={`flex flex-3 justify-start items-center text-${props.appColors.backgroundcolor}-700 font-semibold leading-tight h-full pb-1`}
                  onClick={() => {
                    setIsEditing("personalInformation");
                  }}
                >
                  <Heroicons.Solid.UserIcon
                    className={`w-7 h-full text-${props.appColors.backgroundcolor}-500 p-1 ml-1`}
                  />
                  <span className="ml-3 truncate">{reservation.name}</span>
                </span>
                <div className="flex flex-1 justify-end h-full pb-1">
                  {reservation.email ? (
                    <InformationButton
                      appColors={props.appColors}
                      additionalClasses={""}
                      handleClick={_handleEmail}
                      spanClass={"h-full shadow"}
                    >
                      <div className="flex justify-center items-center px-3 h-full">
                        <Heroicons.Solid.MailIcon className="w-4 h-full text-white" />
                      </div>
                    </InformationButton>
                  ) : null}
                  {reservation.phoneNumber ? (
                    <InformationButton
                      appColors={props.appColors}
                      additionalClasses={""}
                      handleClick={_handleCall}
                      spanClass={"h-full shadow"}
                    >
                      <div className="flex justify-center items-center px-3 h-full">
                        <Heroicons.Solid.PhoneIcon className="w-4 h-full text-white" />
                      </div>
                    </InformationButton>
                  ) : null}
                </div>
              </div>
              <div className="relative flex flex-1 flex-col w-full h-full justify-start items-start content-start p-1 shadow-inner border-b border-gray-200 bg-gray-200">
                <DetailLine
                  handleOnClick={() => {
                    setEditedValues((curr) => {
                      setIsEditing("date");
                      return { dateOfArrival: reservation.dateOfArrival };
                    });
                  }}
                  appColors={props.appColors}
                  value={new Date(reservation.dateOfArrival).toLocaleDateString("de-de", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                    weekday: "long",
                  })}
                  icon="CalendarIcon"
                />
                <DetailLine
                  handleOnClick={() => {
                    setIsEditing("date");
                  }}
                  appColors={props.appColors}
                  value={new Date(reservation.dateOfArrival).toLocaleString("de-de", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                  icon="ClockIcon"
                />
                <DetailLine
                  handleOnClick={() => {
                    setIsEditing("personalInformation");
                  }}
                  appColors={props.appColors}
                  value={reservation.email || "--"}
                  icon="MailIcon"
                />
                <DetailLine
                  handleOnClick={() => {
                    setIsEditing("personalInformation");
                  }}
                  appColors={props.appColors}
                  value={reservation.phoneNumber || "--"}
                  icon="PhoneIcon"
                />
                <DetailLine
                  handleOnClick={() => {
                    setIsEditing("guestAmount");
                  }}
                  appColors={props.appColors}
                  value={`${reservation.guestAmount.adultAmount} Erw.
                    ${
                      reservation.guestAmount.childAmount ? " + " + reservation.guestAmount.childAmount + " Kin." : ""
                    }`}
                  icon="UsersIcon"
                />
                <DetailLine
                  handleOnClick={_handleOpenTableSelect}
                  appColors={props.appColors}
                  value={
                    reservation.tables && reservation.tables.length > 0
                      ? `Tisch ${reservation.tables.map((tab) => tab.name).join(", ")}`
                      : "--"
                  }
                  icon="LocationMarkerIcon"
                />
                <DetailLine
                  notStaticSized
                  handleOnClick={() => {
                    setIsEditing("personalInformation");
                  }}
                  appColors={props.appColors}
                  value={reservation.note || "--"}
                  icon="DocumentTextIcon"
                />
                <DetailLine
                  appColors={props.appColors}
                  value={`${ReservationStateLocalization[reservation.state]} (${ReservationStates[reservation.state]})`}
                  icon="ExclamationIcon"
                />
                <DetailLine
                  appColors={props.appColors}
                  value={reservation.requestChannel || "--"}
                  icon="LightBulbIcon"
                />
                <div className="flex h-15 w-full leading-tight text-gray-500 px-6">
                  <div className="inline-flex flex-1 flex-col justify-center items-center">
                    <span className="text-xxxs uppercase font-semibold text-gray-400 tracking-wide">Erstellt von</span>
                    <span className="text-xs">{reservation.createdBy}</span>
                  </div>
                  <div className="inline-flex flex-1 flex-col justify-center items-center">
                    <span className="text-xxxs uppercase font-semibold text-gray-400 tracking-wide">Geändert von</span>
                    <span className="text-xs">{reservation.modifiedBy}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-200 h-full w-full">
              {events ? (
                <div className="flex sticky top-0 w-full h-8 pt-1 justify-between items-start px-2 border-b flex-shrink-0 bg-white z-20">
                  <span
                    className={`flex flex-3 text-${props.appColors.backgroundcolor}-700 font-semibold leading-tight`}
                  >
                    Events
                  </span>
                  <div className="flex flex-1 justify-end">
                    {scrollAnchor ? (
                      <InformationButton
                        appColors={props.appColors}
                        additionalClasses={""}
                        handleClick={() => {
                          if (scrollAnchor) {
                            scrollAnchor.scrollIntoView({ behavior: "smooth", block: "nearest" });
                          }
                        }}
                      >
                        <div className="flex justify-center items-center px-2 min-w-0 h-6">
                          <Heroicons.Outline.ChevronUpIcon className="w-6 h-full" />
                        </div>
                      </InformationButton>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {_eventBlocks}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`min-h-0 ${
          props.appIsStandalone ? "h-20" : "h-14"
        } w-full bg-white flex-shrink-0 bg-${backgroundColor} px-1`}
      >
        <div
          className={`w-full bg-white shadow-md border-t ${
            props.appIsStandalone ? "h-full " : "flex justify-center items-center h-full flex-grow-0 flex-shrink-0"
          }`}
        >
          <InformationBar height={"12"} additionalClasses="">
            {isEditing ? null : (
              <div className="flex flex-shrink-0 justify-between items-center w-full h-full p-2">
                {+reservation!.state === 0 ? (
                  <ReservationButton
                    unlockedHeight
                    color={"green"}
                    isLocked={props.isLocked}
                    transition={transition}
                    onClickHandler={async () => {
                      if (!props.isLocked) {
                        setIsShowingConfirmation(true);
                      }
                    }}
                    additionalClasses={"rounded-lg"}
                  >
                    Bestätigen
                  </ReservationButton>
                ) : null}
                {+reservation!.state > 0 && +reservation!.state < 3 ? (
                  <ReservationButton
                    unlockedHeight
                    color={"blue"}
                    isLocked={props.isLocked}
                    transition={transition}
                    onClickHandler={async () => {
                      if (!props.isLocked) {
                        setIsWalkingIn(true);
                      }
                    }}
                    additionalClasses={"rounded-lg"}
                  >
                    Erschienen
                  </ReservationButton>
                ) : null}
                {+reservation!.state >= 0 && +reservation!.state < 3 ? (
                  <>
                    <div className="w-4"></div>
                    <ReservationButton
                      unlockedHeight
                      color={"red"}
                      isLocked={props.isLocked}
                      transition={transition}
                      onClickHandler={async () => {
                        if (!props.isLocked) {
                          setIsCancelling(true);
                        }
                      }}
                      additionalClasses={"rounded-lg"}
                    >
                      Absagen
                    </ReservationButton>
                  </>
                ) : null}
                {+reservation!.state > 2 ? (
                  <ReservationButton
                    unlockedHeight
                    color={"green"}
                    isLocked={false}
                    onClickHandler={_handleOnFollowUpReservation}
                    additionalClasses={"rounded-lg"}
                  >
                    Neue Reservierung
                  </ReservationButton>
                ) : null}
                {+reservation!.state > 2 && +reservation!.state < 5 ? (
                  <>
                    <div className="w-4"></div>
                    <ReservationButton
                      unlockedHeight
                      color={"red"}
                      isLocked={props.isLocked}
                      transition={transition}
                      onClickHandler={async () => {
                        if (!props.isLocked) {
                          setIsLeaving(true);
                        }
                      }}
                      additionalClasses={"rounded-lg"}
                    >
                      Ist gegangen
                    </ReservationButton>
                  </>
                ) : null}
              </div>
            )}
          </InformationBar>
        </div>
      </div>
    </div>
  ) : (
    <div className="inline-flex w-full h-full justify-center items-center text-red-500">
      <span>Fehler beim Laden</span>
    </div>
  );
};
