import { AccountInfo } from "@azure/msal-browser";

export type User = AccountInfo & {};

export const loadUser = (authContext: AccountInfo | null) =>
  new Promise((resolve, reject) => {
    try {
      if (authContext) {
        resolve({ user: authContext });
      } else throw new Error("User not loaded properly");
    } catch (error) {
      return reject({ caller: "loadUser", error: error });
    }
  }) as Promise<{ user: User }>;

// export const getUserProfilePhotoURL = (authContext: AuthenticationContext, saveLocation: string) =>
//   new Promise(async (resolve, reject) => {
//     const user = authContext.getCachedUser();
//     if (window && window.localStorage) {
//       const saved = window.localStorage.getItem(saveLocation + user.userName);
//       if (saved) {
//         const parsed = JSON.parse(saved);
//         resolve(parsed);
//       }
//     }
//     const cachedToken = authContext.getCachedToken("https://graph.microsoft.com/");
//     let blob: Blob | null = null;
//     if (cachedToken !== null) {
//       const token = cachedToken;
//       blob = await getProfileImageBlob(token!);
//       try {
//         const reader = new FileReader();
//         reader.onload = ((that: FileReader, ev: any) => {
//           if (window && window.localStorage) {
//             window.localStorage.setItem(saveLocation + user.userName, JSON.stringify(reader.result));
//           }
//           return resolve(reader.result);
//         }) as any;
//         reader.readAsDataURL(blob);
//       } catch (error) {
//         throw error;
//       }
//     } else {
//       authContext.acquireToken("https://graph.microsoft.com/", (errorDesc, token, error) => {
//         if (error) {
//           return reject({ caller: "getUserProfilePhotoURL", error: error });
//         } else if (token) {
//           getProfileImageBlob(token).then((blob) => {
//             try {
//               const reader = new FileReader();
//               reader.onload = ((that: FileReader, ev: any) => {
//                 if (window && window.localStorage) {
//                   window.localStorage.setItem(saveLocation + user.userName, JSON.stringify(reader.result));
//                 }
//                 return resolve(reader.result);
//               }) as any;
//               reader.readAsDataURL(blob);
//             } catch (error) {
//               throw error;
//             }
//           });
//         }
//       });
//     }
//   }) as Promise<string | ArrayBuffer | null>;

// const getProfileImageBlob = async (token: string) => {
//   try {
//     const url = "https://graph.microsoft.com/v1.0/me/photos/64X64/$value";
//     const resp = await fetch(url, {
//       headers: {
//         Authorization: "Bearer " + token,
//       },
//       method: "GET",
//     });
//     const blob = await resp.blob();
//     return blob;
//   } catch (error) {
//     throw new Error(error);
//   }
// };
