import * as React from "react";
import { TailwindBackgroundColors } from "../models/General";
import { WhiteBoxInput } from "./WhiteBoxInput";

interface CustomInputState {}

interface CustomInputProps {
  inputValue: string | number | undefined;
  error: boolean;
  label: string;
  selectedColor: TailwindBackgroundColors;
  handleValueChange: (value: string) => Promise<void> | void;
  setRef?: (ref: HTMLInputElement | HTMLTextAreaElement | null) => void;
  size?: "default" | "full" | "loose";
  alreadyFocused?: boolean;
  onRemove?: () => void;
  className?: string;
}

export class CustomInput extends React.Component<CustomInputProps, CustomInputState> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <WhiteBoxInput
        inputValue={
          this.props.inputValue
            ? typeof this.props.inputValue === "string"
              ? this.props.inputValue
              : this.props.inputValue + ""
            : null
        }
        label={this.props.label}
        onChange={this.props.handleValueChange}
        className={this.props.className}
        hasError={this.props.error}
        labelAlwaysVisible={this.props.alreadyFocused}
        onRemove={this.props.onRemove}
        size={this.props.size}
        onRef={this.props.setRef}
      ></WhiteBoxInput>
    );
  }
}
