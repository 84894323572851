import { FC, useEffect, useMemo } from "react";
import { useTheme } from "../../hooks/useTheme";
import { useToggle } from "../../hooks/useToggle";
import { Heroicon, Heroicons } from "../Heroicon/Heroicon";

type MenuSectionProps = {
  label: string;
  shortInfo?: string;
  icon: Heroicon;
  isOpen?: boolean;
};

const MenuSection: FC<MenuSectionProps> = (props) => {
  const { theme } = useTheme();
  const Icon = useMemo(() => Heroicons.Solid[props.icon], [props.icon]);
  const [isVisible, toggleVisibility, open, close] = useToggle(false);

  useEffect(() => {
    if (props.isOpen !== undefined) {
      if (props.isOpen) open();
      else close();
    }
  }, [close, open, props.isOpen]);

  return (
    <>
      <span
        className="flex h-3 text-xxs uppercase tracking-wide text-gray-500 w-full text-left px-2 mt-1 justify-center items-center content-start"
        onClick={toggleVisibility}
      >
        <Icon className="h-3 w-3 mr-1 text-gray-400 flex-shrink-0" />
        <span className="flex-shrink-0">{props.label}</span>
        <span className="ml-auto text-gray-400 truncate">{props.shortInfo ? `(${props.shortInfo})` : ""}</span>
        <Heroicons.Solid.ChevronLeftIcon
          className={`h-4 w-4 ml-2 transition-transform transform ease-in-out duration-200 ${
            isVisible ? "-rotate-90" : "rotate-0"
          } ${theme ? `text-${theme.backgroundcolor}-400` : ""} flex-shrink-0`}
        />
      </span>
      <div className="flex flex-1 flex-wrap w-full pt-1 pb-2 pl-6 pr-3">{isVisible ? props.children : null}</div>
    </>
  );
};
export default MenuSection;
