import { useEffect, useMemo, useState } from "react";

const useDayAmount = (date?: Date) => {
  const [currentDate, setCurrentDate] = useState<Date>(date ?? new Date());
  const dayAmount = useMemo(() => {
    const tempDate = new Date(currentDate);

    // set to first day of month so 31th does not turn into next month
    tempDate.setDate(1);

    // set to the next month
    tempDate.setMonth(tempDate.getMonth() + 1);

    // get last day of current month since month is +1
    tempDate.setDate(0);

    return tempDate.getDate();
  }, [currentDate]);

  useEffect(() => {
    if (date) setCurrentDate(date);
  }, [date]);

  return dayAmount;
};
export default useDayAmount;
