import { InteractionStatus } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { Fragment, useCallback, useLayoutEffect } from "react";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import ToasterHandler from "./components/ToasterHandler/ToasterHandler";
import { useSavedAccount } from "./hooks/useSavedAccount";
import { useServiceWorker } from "./hooks/useServiceworker";
import { UnauthenticatedMainView } from "./views/Unauthenticated/main/UnauthenticatedMainView";
import * as IDB from "idb-keyval";
import AuthenticatedView from "./views/Authenticated/AuthenticatedView";
import { AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";

const STEP_COUNT_STORAGE = "STEP_COUNT";

export let store: IDB.UseStore | null = null;

export const AppShell = () => {
  const fixHeight = useCallback(() => {
    if ("resizeTo" in window && typeof window.resizeTo === "function") {
      window.resizeTo(window.screen.availWidth, window.screen.availHeight);
    }
  }, []);

  useLayoutEffect(() => {
    const observer = new MutationObserver(fixHeight);
    observer.observe(document.documentElement, { subtree: true, attributes: true });
    return () => {
      observer.disconnect();
    };
  }, [fixHeight]);

  const { inProgress } = useMsal();
  const [savedAccount] = useSavedAccount();
  const account = useAccount({
    username: savedAccount?.username,
    homeAccountId: savedAccount?.homeAccountId,
    localAccountId: savedAccount?.localAccountId,
  });

  const isAuthenticated = useIsAuthenticated(account || undefined);

  useLayoutEffect(() => {
    if (inProgress !== InteractionStatus.None) {
      const count = localStorage.getItem(STEP_COUNT_STORAGE);
      const updatedCount = +(count ?? 0) + 1 + "";
      if (+updatedCount < 15) {
        localStorage.setItem(STEP_COUNT_STORAGE, updatedCount);
      } else {
        window.alert("Entschuldigung\r\nEs scheint ein Fehler aufgetreten zu sein");
        localStorage.clear();
        window.location.reload();
      }
    } else if (isAuthenticated) {
      const timeout = setTimeout(() => {
        localStorage.removeItem(STEP_COUNT_STORAGE);
      }, 5000);
      return () => {
        if (timeout) clearTimeout(timeout);
      };
    }
  }, [inProgress, isAuthenticated]);

  const sw = useServiceWorker();
  useLayoutEffect(() => {
    if (isAuthenticated) {
      sw.register();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sw.register, isAuthenticated]);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary appInsights={reactPlugin} onError={ErrorAppInsights}>
        <Fragment>
          <BrowserRouter>
            <UnauthenticatedTemplate>
              <UnauthenticatedMainView />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <Route path="/login">
                <Redirect to="/Reservierung" exact push />
              </Route>
              <ToasterHandler />
              <AuthenticatedView sw={sw} account={account} />
            </AuthenticatedTemplate>
          </BrowserRouter>
        </Fragment>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
};

const ErrorAppInsights = (error: any) => {
  return (
    <div className="flex flex-col justify-center items-center w-full h-full bg-gray-100">
      <h1 className="text-red-500 text-2xl h-12 border-b-2 border-red-800">AppInsights encountered an Error</h1>
      <button className="px-3 py-1 text-white bg-red-500 tracking-wide mt-2" onClick={window.location.reload}>
        Reload
      </button>
    </div>
  );
};
