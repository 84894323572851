import React from "react";

export interface ErrorViewProps {
  errorText: string | undefined;
}

export const ErrorView = (props: ErrorViewProps) => (
  <div className="flex flex-1 flex-col w-full h-full justify-center items-center">
    <h1 className="p-6">An Error occured.</h1>
    {props.errorText && <span>{props.errorText}</span>}
  </div>
);
