import * as React from "react";
import { Nachricht } from "../../clientApi/models/Nachricht";
import { Heroicon, Heroicons } from "../../components/Heroicon/Heroicon";
import { RoundButton } from "../../components/RoundButton";
import { useCurrentDate } from "../../hooks/useCurrentDate";
import { useTheme } from "../../hooks/useTheme";
import { Reservation } from "../../models/Reservation";

const checkReservationId = (id: string | undefined) =>
  id !== "00000000-0000-0000-0000-000000000000" && id !== undefined;

export const NachrichtBubble = (props: {
  nachricht: Nachricht.Client<any>;
  isLoading: boolean;
  isGettingReservation: boolean;
  isSelected: boolean;
  onAdd: (nachricht: Nachricht.Client<any>) => void;
  onRemove: (nachricht: Nachricht.Client<any>) => void;
  onCancel?: (nachricht: Nachricht.Client<any>) => void;
  onDetailRequest: (res: Reservation) => Promise<any>;
  onOpenNote: (nachricht: Nachricht.Client<any>) => void;
  onSelect: (nachricht: Nachricht.Client<any>) => any;
  onOpenEmail: () => any;
  causeTypeToIcon: Map<Nachricht.Type, Heroicon>;
  causeStateToIconAndColor: Map<keyof Nachricht.State, [Heroicon, string]>;
  initialReservation?: Reservation;
  callAmount?: number;
  onSearchFor?: (str: string) => any;
  frozenDate: Date;
}) => {
  const now = React.useMemo(
    () =>
      props.frozenDate.toLocaleDateString("de-de", {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit",
      }),
    [props.frozenDate]
  );
  const { theme } = useTheme();

  const causeHref: { [key in Nachricht.Type]: (from: string, subject: string) => string } = React.useMemo(
    () => ({
      Call: (contact) => `tel:${contact}`,
      Email: (contact, subject) => `mailto:${contact}?subject=RE:${subject}`,
      Form: (contact) => ``,
      MissedCall: (contact) => `tel:${contact}`,
      MissedCallMessage: (contact, subject) => `tel:${contact}`,
    }),
    []
  );

  const currentDate = React.useMemo(() => new Date(props.nachricht.timestamp), [props.nachricht.timestamp]);

  const [weekday, date] = React.useMemo(
    () =>
      currentDate
        .toLocaleString("de-de", {
          month: "2-digit",
          day: "2-digit",
          year: "2-digit",
          weekday: "short",
        })
        .split(","),
    [currentDate]
  );

  const day = React.useMemo(
    () =>
      now ===
      currentDate.toLocaleString("de-de", {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit",
      })
        ? "Heute"
        : null,
    [currentDate, now]
  );

  const time = React.useMemo(
    () =>
      currentDate.toLocaleString("de-de", {
        hour: "2-digit",
        minute: "2-digit",
      }),
    [currentDate]
  );

  const [reservation, setReservation] = React.useState<Reservation | undefined>(props.initialReservation);

  const _handleContactRequest = React.useCallback(() => {
    if (props.isSelected) {
      window.location.href = causeHref[props.nachricht.type](props.nachricht.from, props.nachricht.subject);
    }
  }, [causeHref, props.isSelected, props.nachricht.from, props.nachricht.subject, props.nachricht.type]);

  React.useEffect(() => {
    setReservation((c) => {
      if (!c && props.initialReservation) return props.initialReservation;
      else if (c && props.initialReservation) {
        if (c.modifiedBy !== props.initialReservation.modifiedBy) return props.initialReservation;
        if (c.note !== props.initialReservation.note) return props.initialReservation;
        if (c.dateOfArrival !== props.initialReservation?.dateOfArrival) return props.initialReservation;
      }
      return c;
    });
  }, [props.initialReservation]);

  const resDate = React.useMemo(
    () =>
      reservation
        ? new Date(reservation.dateOfArrival).toLocaleDateString("de-de", {
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          })
        : null,
    [reservation]
  );

  const dateToShow = React.useMemo(() => (resDate ? resDate.slice(0, 6) : null), [resDate]);

  const timeToShow = React.useMemo(() => (resDate ? resDate.slice(-5) : null), [resDate]);

  const guests = React.useMemo(
    () =>
      reservation
        ? `${reservation.guestAmount.adultAmount}${
            reservation.guestAmount.childAmount ? `+${reservation.guestAmount.childAmount}` : ""
          }`
        : null,
    [reservation]
  );

  const _handleClick = React.useCallback(() => {
    props.onSelect.call(undefined, props.nachricht);
  }, [props.nachricht, props.onSelect]);

  const isFullSize = React.useMemo(
    () => checkReservationId(props.nachricht.reservationId) || (props.nachricht.state === 2 && props.nachricht.note),
    [props.nachricht.note, props.nachricht.reservationId, props.nachricht.state]
  );

  const TypeIcon = React.useMemo(
    () => Heroicons.Outline[props.causeTypeToIcon.get(props.nachricht.type) || "QuestionMarkCircleIcon"],
    [props.causeTypeToIcon, props.nachricht.type]
  );
  const [StateIconName, stateColor] = React.useMemo(
    () =>
      props.causeStateToIconAndColor.get(props.nachricht.state) ||
      (["QuestionMarkCircleIcon", "red-500"] as [Heroicon, string]),
    [props.causeStateToIconAndColor, props.nachricht.state]
  );

  const StateIcon = React.useMemo(() => Heroicons.Outline[StateIconName], [StateIconName]);

  const title = React.useMemo(() => {
    if (props.nachricht.type === "Call" || props.nachricht.type === "MissedCall") {
      return props.nachricht.from;
    } else if (props.nachricht.type === "MissedCallMessage") {
      return `AB Nachricht    ${props.nachricht.from}`;
    } else return props.nachricht.subject;
  }, [props.nachricht.from, props.nachricht.subject, props.nachricht.type]);

  const stateLocalization: Nachricht.StateLocalization = React.useMemo(
    () => ({
      0: "Neu",
      1: "Erstellt",
      2: "Nichts",
      3: "Abgelehnt",
    }),
    []
  );

  const _preventDefault = React.useCallback((cb: (...p: any) => any) => {
    return (ev: React.MouseEvent) => {
      if (ev && "preventDefault" in ev && typeof ev.preventDefault === "function") ev.stopPropagation();
      if (cb && typeof cb === "function") return cb();
      return;
    };
  }, []);

  const _handleAddClick = React.useCallback(() => {
    if (props.isSelected) {
      props.onAdd(props.nachricht);
    } else return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onAdd, props.isSelected, props.nachricht]);

  const _handleRemoveClick = React.useCallback(
    () => (props.isSelected ? props.onRemove.call(undefined, props.nachricht) : null),
    [props.onRemove, props.isSelected, props.nachricht]
  );

  const _handleCancelClick = React.useCallback(
    () => (props.isSelected && props.onCancel ? props.onCancel.call(undefined, props.nachricht) : null),
    [props.isSelected, props.onCancel, props.nachricht]
  );

  const _handleModifyClick = React.useCallback(
    () => (props.isSelected && reservation ? props.onDetailRequest.call(undefined, reservation) : null),
    [props.onDetailRequest, props.isSelected, reservation]
  );

  const _handleOpenNote = React.useCallback(
    () => props.isSelected && props.onOpenNote.call(undefined, props.nachricht),
    [props.isSelected, props.nachricht, props.onOpenNote]
  );

  const onSearchForPhoneNumber = React.useCallback(
    (nr: string) => () => {
      if (props.onSearchFor) {
        props.onSearchFor.call(undefined, nr);
      }
    },
    [props.onSearchFor]
  );

  const modifiedDate = React.useMemo(
    () =>
      props.nachricht.modified
        ? props.nachricht.modified.toLocaleDateString("de-de", {
            day: "2-digit",
            month: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          })
        : "--",
    [props.nachricht.modified]
  );

  // React.useEffect(() => {
  //   if (checkReservationId(props.nachricht.reservationId)) {
  //     // console.log(props.nachricht, props.initialReservation);
  //   }
  // }, [props.initialReservation, props.nachricht]);

  return (
    <div
      className={`inline-flex justify-start items-center content-start w-full outline-none min-h-0 flex-shrink-0 max-h-full`}
      onClick={_handleClick}
      tabIndex={0}
    >
      <div
        className={`relative inline-flex justify-start items-center content-start w-full bg-${
          props.isSelected ? `${theme.backgroundcolor}-100` : "white"
        } rounded-lg shadow-md overflow-hidden h-full pr-1 top-0 right-0 transition-all duration-150 ease-in-out`}
      >
        {props.nachricht.modifiedBy ? (
          <div
            className={`absolute bottom-0 right-0 inline-flex justify-start items-center mr-2 z-20 space-x-1 text-gray-500 mb-1`}
          >
            <span className={`text-xs`}>{modifiedDate}</span>
            <Heroicons.Outline.PencilIcon className="w-3 h-3" />
            <span className={`text-xs`}>{props.nachricht.modifiedBy}</span>
          </div>
        ) : null}

        <div
          className={`relative inline-flex flex-grow-0 flex-col flex-shrink-0 justify-center items-center bg-${stateColor} w-10 h-full content-start`}
        >
          <span className={`w-5 h-5 flex-shrink-0 flex-grow-0 text-${theme.backgroundcolor}-100`}>
            {props.isLoading ? (
              <Heroicons.Outline.RefreshIcon className="w-full h-full animate-spin" />
            ) : (
              <StateIcon className={`w-full h-full text-${theme.backgroundcolor}-100`} />
            )}
          </span>
          <span className={`text-${theme.backgroundcolor}-100 text-xxs`}>
            {stateLocalization[props.nachricht.state]}
          </span>
        </div>

        <div className="absolute flex justify-end items-center h-full w-full">
          <TypeIcon
            className={` w-18 h-18 flex-shrink-0 flex-grow-0 text-${
              props.isSelected ? "white" : theme.backgroundcolor + "-100"
            } transform rotate rotate-6`}
          />
        </div>

        <div
          className={`relative flex flex-3 flex-col justify-start items-center w-full h-full px-3 pr-12 py-1 overflow-hidden z-10 transition-all duration-150 ease-in-out space-y-2 pt-2`}
        >
          <div
            className={`inline-flex min-h-0 justify-start items-center w-full text-base text-${theme.backgroundcolor}-800`}
            title={props.nachricht.from}
          >
            <span
              className={`inline-flex justify-start items-center ${
                props.callAmount && props.callAmount > 1
                  ? `text-${theme.backgroundcolor}-700 bg-${theme.backgroundcolor}-50 px-2 pr-2 -ml-2 py-px rounded tabular-nums `
                  : `text-${theme.backgroundcolor}-700`
              } space-x-1 pointer-events-auto`}
            >
              <TypeIcon className={`w-4 h-4 flex-shrink-0 flex-grow-0 -ml-1`} />
              {props.callAmount && props.callAmount > 1 ? (
                <span className="text-xs tracking-wide">
                  {props.callAmount}x (...{props.nachricht.from.slice(-4)})
                </span>
              ) : null}
            </span>

            <span className="pl-1 truncate flex-grow-0 leading-tight font-semibold pr-1">{title}</span>
            {reservation && props.nachricht.state > 0 ? (
              <div className="inline-flex justify-center items-center flex-grow-0">
                <Heroicons.Outline.UsersIcon className="w-4 h-3 flex-shrink-0 flex-grow-0" />
                <span className="text-xs pr-1 pl-1 truncate flex-grow-0 leading-tight whitespace-no-wrap">
                  {reservation.name}
                </span>
              </div>
            ) : null}
          </div>
          <div
            className={`inline-flex min-h-0 justify-start items-center w-full text-${theme.backgroundcolor}-600 ${
              props.nachricht.modifiedBy ? "pb-4" : "pb-1"
            }`}
          >
            <div className="flex flex-col w-full h-full -ml-1 space-y-2">
              <div className="flex flex-1 w-full justify-start items-center space-x-3">
                {day ? (
                  <div className="inline-flex justify-center items-center">
                    <Heroicons.Outline.CalendarIcon className="w-4 h-3 flex-shrink-0 flex-grow-0" />
                    <span className="text-xs pr-1 pl-1 leading-tight">{day}</span>
                  </div>
                ) : (
                  <div className="inline-flex justify-center items-center">
                    <Heroicons.Outline.CalendarIcon className="w-4 h-3 flex-shrink-0 flex-grow-0" />
                    <span className="text-xs pr-1 pl-1 leading-tight">{weekday}</span>
                    <span className="text-xs leading-tight">{date}</span>
                  </div>
                )}
                <div className="inline-flex justify-center items-center">
                  <Heroicons.Outline.ClockIcon className="w-4 h-3 flex-shrink-0 flex-grow-0" />
                  <span className="text-xs pr-1 pl-1 font-semibold leading-tight">{time}</span>
                </div>
              </div>
              {props.nachricht.state === 2 && props.nachricht.note ? (
                <div className={`flex flex-1 w-full justify-start items-center overflow-hidden`}>
                  <div className="inline-flex justify-center items-center truncate">
                    <Heroicons.Outline.ClipboardIcon className="w-4 h-3 flex-shrink-0 flex-grow-0" />
                    <span className="text-xs pr-1 pl-1 leading-tight truncate">{props.nachricht.note}</span>
                  </div>
                </div>
              ) : null}
              {props.nachricht.state > 0 && props.nachricht.state !== 2 && reservation ? (
                <div
                  className={`flex flex-1 w-full justify-start items-center space-x-1 ${
                    props.isGettingReservation ? `bg-${theme.backgroundcolor}-100 rounded-lg px-1 -ml-1` : ""
                  } flex-shrink-0`}
                >
                  <div
                    className={`w-3 h-3 ${
                      reservation || props.isGettingReservation ? "opacity-100" : "opacity-0"
                    } transition-all duration-150 ease-in-out flex-shrink-0`}
                  >
                    {props.isGettingReservation ? (
                      <Heroicons.Outline.RefreshIcon className="w-3 h-3 flex-shrink-0 animate-spin" />
                    ) : (
                      <Heroicons.Outline.BookmarkIcon className="w-3 h-3" />
                    )}
                  </div>
                  <div className="inline-flex justify-center items-center">
                    <Heroicons.Outline.CalendarIcon className="w-4 h-3 flex-shrink-0 flex-grow-0" />
                    <span className="text-xs pr-1 pl-1 leading-tight whitespace-no-wrap">{dateToShow}</span>
                  </div>
                  <div className="inline-flex justify-center items-center">
                    <Heroicons.Outline.ClockIcon className="w-4 h-3 flex-shrink-0 flex-grow-0" />
                    <span className="text-xs pr-1 pl-1 leading-tight">{timeToShow}</span>
                  </div>
                  <div className="inline-flex justify-center items-center">
                    <Heroicons.Outline.UsersIcon className="w-4 h-3 flex-shrink-0 flex-grow-0" />
                    <span className="text-xs pr-1 pl-1 leading-tight">{guests}</span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className={`absolute flex flex-2 flex-shrink-0 justify-end items-center h-full w-full px-1 z-10 overflow-hidden right-0 top-0 space-x-2 pr-2 cursor-pointer group`}
        >
          {props.callAmount && props.callAmount > 1 ? (
            <RoundButton
              key="callAmount_btn"
              onClick={props.isSelected ? _preventDefault(onSearchForPhoneNumber(props.nachricht.from)) : () => null}
              isVisible={props.isSelected}
              color={`${theme.backgroundcolor}-500`}
              hoverColor={`${theme.backgroundcolor}-600`}
              withEvent
            >
              <Heroicons.Outline.PhoneIncomingIcon className={`w-full h-full text-${theme.backgroundcolor}-100`} />
              <Heroicons.Outline.SearchIcon className={`w-full h-full text-${theme.backgroundcolor}-100`} />
            </RoundButton>
          ) : null}

          {props.nachricht.type !== "Form" &&
          props.nachricht.type !== "MissedCallMessage" &&
          props.nachricht.type !== "MissedCall" ? (
            <RoundButton
              key="type_btn"
              onClick={props.isSelected ? _preventDefault(_handleContactRequest) : () => null}
              isVisible={props.isSelected}
              color={`${theme.backgroundcolor}-500`}
              hoverColor={`${theme.backgroundcolor}-600`}
              withEvent
            >
              <TypeIcon className={`w-3/4 h-full text-${theme.backgroundcolor}-100`} />
            </RoundButton>
          ) : null}

          {props.nachricht.type === "MissedCallMessage" || props.nachricht.type === "MissedCall" ? (
            <RoundButton
              key="Call_btn"
              onClick={props.isSelected ? _preventDefault(_handleContactRequest) : () => null}
              isVisible={props.isSelected}
              color={`${theme.backgroundcolor}-500`}
              hoverColor={`${theme.backgroundcolor}-600`}
              withEvent
            >
              <Heroicons.Outline.PhoneIcon className={`w-3/4 h-full text-${theme.backgroundcolor}-100`} />
            </RoundButton>
          ) : null}

          {props.nachricht.body ? (
            <RoundButton
              key="body_btn"
              onClick={props.isSelected ? _preventDefault(props.onOpenEmail) : () => null}
              isVisible={props.isSelected}
              color={`${theme.backgroundcolor}-500`}
              hoverColor={`${theme.backgroundcolor}-600`}
              withEvent
            >
              <Heroicons.Outline.DocumentSearchIcon className={`w-3/4 h-full text-${theme.backgroundcolor}-100`} />
            </RoundButton>
          ) : null}
          {props.nachricht.state === 2 || props.nachricht.state === 0 ? (
            <RoundButton
              key="state_btn"
              onClick={_preventDefault(_handleAddClick)}
              isVisible={props.isSelected}
              color={`${theme.backgroundcolor}-500`}
              hoverColor={`${theme.backgroundcolor}-600`}
              withEvent
            >
              <Heroicons.Outline.PlusIcon className={`w-full h-full text-${theme.backgroundcolor}-100`} />
            </RoundButton>
          ) : null}

          {props.nachricht.state <= 0 ? (
            <RoundButton
              key="reject_btn"
              onClick={_preventDefault(_handleRemoveClick)}
              isVisible={props.isSelected}
              color={`${theme.backgroundcolor}-500`}
              hoverColor={`${theme.backgroundcolor}-600`}
              withEvent
            >
              <Heroicons.Outline.XIcon className={`w-full h-full text-${theme.backgroundcolor}-100`} />
            </RoundButton>
          ) : null}
          {props.nachricht.state <= 0 && props.onCancel ? (
            <RoundButton
              key="cancel_btn"
              onClick={_preventDefault(_handleCancelClick)}
              isVisible={props.isSelected}
              color={`${theme.backgroundcolor}-500`}
              hoverColor={`${theme.backgroundcolor}-600`}
              withEvent
            >
              <Heroicons.Outline.BanIcon className={`w-full h-full text-${theme.backgroundcolor}-100`} />
            </RoundButton>
          ) : null}
          {reservation && props.nachricht.state > 0 ? (
            <RoundButton
              key="res_btn"
              onClick={_preventDefault(_handleModifyClick)}
              isVisible={props.isSelected}
              color={`${theme.backgroundcolor}-500`}
              hoverColor={`${theme.backgroundcolor}-600`}
              withEvent
            >
              <Heroicons.Outline.BookmarkIcon className={`w-full h-full p-1 text-${theme.backgroundcolor}-100`} />
            </RoundButton>
          ) : null}
          {props.nachricht.state > 0 && props.nachricht.note ? (
            <RoundButton
              key="note_btn"
              onClick={_preventDefault(_handleOpenNote)}
              isVisible={props.isSelected}
              color={`${theme.backgroundcolor}-500`}
              hoverColor={`${theme.backgroundcolor}-600`}
              withEvent
            >
              <Heroicons.Outline.ClipboardIcon className={`w-full h-full p-1 text-${theme.backgroundcolor}-100`} />
            </RoundButton>
          ) : null}
          {props.isSelected ? null : (
            <Heroicons.Outline.DotsHorizontalIcon
              key="menu_btn"
              className={`absolute flex flex-grow-0 flex-shrink-0 justify-center items-center w-8 h-8 text-${theme.backgroundcolor}-400 group-hover:text-${theme.backgroundcolor}-600 p-1 group-hover:bg-${theme.backgroundcolor}-200 rounded-full transition-colors ease-in-out duration-150`}
            />
          )}
        </div>
      </div>
    </div>
  );
};
