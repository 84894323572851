import * as React from "react";
import { SummaryTable } from "../models/Summary";
import { AppColors } from "../models/General";
import { TimeColumn } from "./TimeColumn";
import { useTheme } from "../hooks/useTheme";

const defaultMaximum = 20;

export const TimeDiagram = (props: {
  Times: SummaryTable[0];
  appColors: AppColors;
  isSelected: boolean;
  date?: string;
}) => {
  const { Times, appColors } = props;
  const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(null);
  React.useEffect(() => {
    if (containerRef !== null) {
      containerRef.addEventListener("touchmove", (e) => e.stopPropagation(), { passive: false });
      return containerRef.removeEventListener("touchmove", (e) => e.stopPropagation());
    }
  }, [containerRef]);

  const keys: (keyof typeof Times)[] = React.useMemo(
    () =>
      (Object.keys(Times) as (keyof typeof Times)[]).filter(
        (key) => key !== "reservationTotal" && key !== "guestTotal"
      ),
    [Times]
  );

  const maximum: number = React.useMemo(() => {
    const calculatedMaxiumum = keys.reduce((maximum: number, time) => {
      if (Times[time]) {
        return +Times[time].guestTotal > maximum ? +Times[time].guestTotal : +maximum;
      }
      return +maximum;
    }, 0);
    if (calculatedMaxiumum < defaultMaximum) return defaultMaximum;
    else return calculatedMaxiumum;
  }, [Times, keys]);

  const _renderTimeColums = React.useMemo(() => {
    if (Times) {
      return keys.map((time, idx) => {
        if (Times && Times[time]) {
        } else return null;
        if (time === "reservationTotal" || time === "guestTotal") return null;
        return (
          <TimeColumn
            key={time}
            TableTime={Times[time]}
            time={time + ""}
            total={+Times["guestTotal"]}
            appColors={appColors}
            maximum={maximum}
          ></TimeColumn>
        );
      });
    }
  }, [Times, appColors, keys, maximum]);

  const { theme } = useTheme();

  return (
    <div ref={setContainerRef} className={`flex flex-col flex-grow-0 flex-shrink-0 w-full h-32 overflow-auto bg-white`}>
      <div className={`relative flex flex-1 flex-shrink-0 h-full overflow-auto py-1`}>{_renderTimeColums}</div>
    </div>
  );
};
