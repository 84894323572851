import * as React from "react";
import { AppColors, TailwindBackgroundColors } from "../models/General";
import { Reservation } from "../models/Reservation";

export const GuestAmountPicker = ({
  ...props
}: {
  appColors: AppColors;
  selectGuestAmount: (guests: Reservation["guestAmount"]) => void;
  selectedGuestAmount?: Reservation["guestAmount"];
  justifyAround?: boolean;
  inverted?: boolean;
}) => {
  const [adults, setAdults] = React.useState<Reservation["guestAmount"]["adultAmount"] | null>(
    props.selectedGuestAmount ? props.selectedGuestAmount.adultAmount : null
  );
  const [children, setChildren] = React.useState<Reservation["guestAmount"]["childAmount"] | null>(
    props.selectedGuestAmount ? props.selectedGuestAmount.childAmount : null
  );

  const { selectGuestAmount, selectedGuestAmount } = props;

  React.useEffect(() => {
    if (adults !== null && children !== null) {
      if (selectedGuestAmount) {
        if (selectedGuestAmount.adultAmount !== adults || selectedGuestAmount.childAmount !== children) {
          selectGuestAmount({
            adultAmount: adults,
            childAmount: children
          });
        } else {
        }
      } else {
        selectGuestAmount({
          adultAmount: adults,
          childAmount: children
        });
      }
    }
  }, [adults, children, selectGuestAmount, selectedGuestAmount]);

  return (
    <div className={`flex flex-1 flex-col items-start h-full w-full z-10 justify-start`}>
      <div className={`relative flex flex-1 flex-col w-full px-1 pb-2 pt-1 min-h-0 max-h-full h-full`}>
        <div
          className={`flex flex-col items-center w-full ${
            props.justifyAround ? "justify-around h-full" : "justify-start"
          }`}
        >
          <div className="inline-flex flex-col w-full items-start px-2 pb-2">
            <div className="flex justify-around items-start w-full" style={{ flex: 2 }}>
              {[1, 2, 3, 4, 5].map(entry => (
                <RoundButton
                  key={entry}
                  appColors={props.appColors}
                  isActive={adults ? entry === adults : false}
                  onClick={() => setAdults(entry)}
                  inverted={props.inverted}
                >
                  {entry}
                </RoundButton>
              ))}
            </div>
            <div className="flex pt-2 flex-col justify-around items-center w-full" style={{ flex: 1 }}>
              <input
                value={adults ? adults : ""}
                placeholder={"0"}
                onChange={ev => {
                  let valueAsNumber = parseInt(ev.target.value);
                  if (!isNaN(valueAsNumber)) {
                    return setAdults(valueAsNumber);
                  }
                  return setAdults(null);
                }}
                className={`w-32 px-3 outline-none bg-transparent border-b-2 border-${
                  props.inverted ? props.appColors.backgroundcolor + "-700" : props.appColors.backgroundcolor + "-100"
                } text-${props.inverted ? props.appColors.backgroundcolor + "-700" : "white"} text-2xl text-center`}
                type="text"
              />
              <span
                className={`pb-1 text-${
                  props.inverted ? props.appColors.backgroundcolor + "-600" : props.appColors.backgroundcolor + "-200"
                } text-xxs font-semibold tracking-wide uppercase`}
              >
                Erwachsene
              </span>
            </div>
          </div>
          <div className="inline-flex flex-col w-full items-start px-2">
            <div className="flex justify-around items-start w-full" style={{ flex: 2 }}>
              {[0, 1, 2, 3].map(entry => (
                <RoundButton
                  key={entry}
                  appColors={props.appColors}
                  isActive={children !== null ? entry === children : false}
                  onClick={() => setChildren(entry)}
                  inverted={props.inverted}
                >
                  {entry}
                </RoundButton>
              ))}
            </div>
            <div className="flex pt-2 flex-col justify-around items-center w-full" style={{ flex: 1 }}>
              <input
                value={children !== null ? children : ""}
                placeholder={"0"}
                onChange={ev => {
                  let valueAsNumber = parseInt(ev.target.value);
                  if (!isNaN(valueAsNumber)) {
                    return setChildren(valueAsNumber);
                  }
                  return setChildren(null);
                }}
                className={` w-32 px-3 outline-none bg-transparent border-b-2 border-${
                  props.inverted ? props.appColors.backgroundcolor + "-700" : props.appColors.backgroundcolor + "-100"
                } text-${props.inverted ? props.appColors.backgroundcolor + "-700" : "white"} text-2xl text-center`}
                type="text"
              />
              <span
                className={`pb-1 text-${
                  props.inverted ? props.appColors.backgroundcolor + "-600" : props.appColors.backgroundcolor + "-200"
                } text-xxs font-semibold tracking-wide uppercase`}
              >
                Kinder
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RoundButton: React.FC<{
  onClick?: () => any;
  appColors: AppColors;
  isActive: boolean;
  inverted?: boolean;
}> = props => {
  return (
    <div
      className={`flex h-12 w-12 flex-grow-0 flex-shrink-0 text-base justify-center items-center rounded-full border-2  ${
        props.isActive
          ? `text-${props.appColors.backgroundcolor}-500 bg-white shadow-md border-white`
          : `text-${props.inverted ? "gray-600" : "white"} hover:bg-white hover:text-${
              props.appColors.backgroundcolor
            }-500 shadow-none border-${props.inverted ? "gray-500" : props.appColors.backgroundcolor}-100`
      } bg-transparent cursor-pointer`}
      style={{ transition: "background-color 100ms, box-shadow 200ms" }}
      onClick={() => (props.onClick ? props.onClick() : null)}
    >
      {props.children}
    </div>
  );
};
