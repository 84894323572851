import { XIcon } from "@heroicons/react/solid";
import * as React from "react";

export type PopupProps = {
  title: string | JSX.Element;
  close: () => any;
  beingDisplayed: boolean;
  ratio?: "1x9" | "2x8" | "3x7" | "4x6" | "5x5" | "9x1" | "8x2" | "7x3" | "6x4";
  hideWhenNotDisplayed?: boolean;
  appIsStandalone?: boolean;
};

export const Popup: React.FC<PopupProps> = (props) => {
  const [isDisplayed, setIsDisplayed] = React.useState(false);

  const [upper, lower] = (props.ratio || "1x9").split("x");

  React.useEffect(() => {
    setIsDisplayed(props.beingDisplayed);
  }, [props.beingDisplayed]);

  return (
    <>
      <div
        onClick={props.close}
        className={`absolute bottom-0 flex flex-col justify-end items-end w-full h-full z-20 px-1 overflow-hidden bg-gray-400 bg-opacity-30 transition-all duration-200 ease-in-out ${
          props.hideWhenNotDisplayed && !isDisplayed ? "hidden" : ""
        } ${isDisplayed ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"}`}
      ></div>
      <div
        className={`absolute bottom-0 flex flex-col w-full h-full z-30 px-1 transition-transform transform duration-300 ease-in-out ${
          props.hideWhenNotDisplayed && !isDisplayed ? "hidden" : ""
        } ${isDisplayed ? "translate-y-0 pointer-events-auto" : "translate-y-full pointer-events-none"}`}
      >
        <div onClick={props.close} className={`flex flex-${upper} w-full`}></div>
        <div
          className={`flex flex-col flex-${lower} w-full bg-white rounded-t-xl shadow overflow-hidden py-2 px-2 bottom-0 transition-transform transform ease-in-out duration-300 ${
            isDisplayed ? "translate-y-0 pointer-events-auto" : "translate-y-full pointer-events-none"
          }`}
        >
          <div className="flex h-8 flex-grow-0 flex-shrink-0 w-full px-1 justify-between items-center pb-1 text-gray-700">
            {props.title}
            <XIcon className="w-6 h-6" onClick={props.close} />
          </div>
          <div className="flex flex-1 w-full flex-col min-h-0">{props.children}</div>
          {props.appIsStandalone ? <div className={`flex flex-shrink-0 flex-grow-0 w-full h-6 bg-white`}></div> : null}
        </div>
      </div>
    </>
  );
};
