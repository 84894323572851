import { useCallback, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { Heroicons } from "../../../components/Heroicon/Heroicon";
import { useTheme } from "../../../hooks/useTheme";
import { Reservation } from "../../../models/Reservation";
import { useAbspracheDetailViewModel } from "./AbspracheDetailViewModel";
import AbspracheDetailViewTypes from "./AbspracheDetailViewTypes";

const AbspracheDetailView = (props: AbspracheDetailViewTypes.Props) => {
  const viewModel = useAbspracheDetailViewModel(props);
  const {
    theme: { backgroundcolor },
  } = useTheme();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const relayCreate = useCallback((date: Date) => () => props.createAbsprache(date), [props.createAbsprache]);

  const relayAddRes = useCallback(
    (date: Date, res: Reservation) => () => props.AddResToAbsprache(date, res),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.AddResToAbsprache]
  );

  const isBusy = useMemo(
    () => props.isCreating || props.isPulling || props.isPulling,
    [props.isCreating, props.isPulling]
  );

  return (
    <>
      <header className="inline-flex flex-col justify-center items-start h-16 px-3 max-w-screen-sm w-full mx-auto my-2 mb-8">
        <NavLink
          to="/Absprache"
          className="inline-flex gap-2 text-sm font-semibold tracking-wide text-blue-500 cursor-pointer justify-center items-center"
        >
          <Heroicons.Outline.ChevronLeftIcon className="w-4 h-4" />
          <span>Absprachen</span>
        </NavLink>
        <span className="inline-flex text-xl font-black tracking-wider gap-1 items-baseline">
          <small className="font-normal">am</small>
          <span>{viewModel.currentDate.toLocaleDateString("de-de")}</span>
        </span>
      </header>

      {viewModel.hasMatch ? (
        // <a
        //   target="_blank"
        //   href={viewModel.directLink}
        //   className={`inline-flex justify-center items-center content-center text-center bg-${backgroundcolor}-500 text-${backgroundcolor}-100 hover:bg-${backgroundcolor}-600 px-3 py-2 w-48 mx-auto shadow-md text-xs tracking-wide leading-snug cursor-pointer rounded font-semibold outline-none focus:outline-none ${
        //     isBusy ? "animate-pulse" : ""
        //   }`}
        //   rel="noreferrer"
        // >
        //   Zur Absprache wechseln
        // </a>
        props.reservierungen.length ? (
          <ol className="inline-flex flex-col divide-y justify-start items-start content-start w-full min-h-0">
            {props.reservierungen.map((res) => {
              const {
                id,
                name,
                guestAmount: { adultAmount, childAmount },
              } = res;
              const hasFile = viewModel.fileIds.includes(id);
              const hasLink = viewModel.mappedFileUrls.has(id);
              const link = hasLink ? viewModel.convertLink(viewModel.mappedFileUrls.get(id)!) : null;

              return (
                <li
                  key={id}
                  className={`inline-flex w-full px-3 text-xs bg-white py-3 gap-3 hover:underline ${
                    props.addingResId === id ? "animate-pulse" : ""
                  }`}
                  onClick={hasFile ? undefined : relayAddRes(viewModel.currentDate, res)}
                >
                  <span>{name}</span>
                  <span>
                    {adultAmount} Erw. {childAmount ? `${childAmount} Kin.` : ""}
                  </span>
                  {hasLink ? (
                    <a
                      className="text-blue-500 ml-auto mr-3 font-semibold tracking-wide"
                      target="_blank"
                      rel="norefferer noreferrer"
                      href={link ?? "#"}
                    >
                      Link öffnen
                    </a>
                  ) : (
                    <button
                      type="button"
                      disabled={hasFile || props.addingResId === id}
                      className="ml-auto mr-3 text-blue-500 disabled:text-gray-500 font-semibold tracking-wide"
                    >
                      {hasFile ? "Lädt Link..." : props.addingResId === id ? "Erstellt..." : "Erstellen"}
                    </button>
                  )}
                </li>
              );
            })}
          </ol>
        ) : (
          <div className="inline-flex flex-col gap-2 p-4 mx-auto">
            <span className="text-xs font-semibold tracking-wide text-center w-full">
              Keine Reservierungen an diesem Tag
            </span>
            <NavLink className="text-center text-blue-500" to="/Reservierung/">
              Zu den Reservierungen
            </NavLink>
          </div>
        )
      ) : (
        <button
          type="button"
          className={`bg-${backgroundcolor}-500 text-${backgroundcolor}-100 hover:bg-${backgroundcolor}-600 px-3 py-2 w-48 mx-auto shadow-md text-xs tracking-wide leading-snug cursor-pointer rounded font-semibold outline-none focus:outline-none ${
            isBusy ? "animate-pulse" : ""
          }`}
          disabled={isBusy}
          onClick={relayCreate(viewModel.currentDate)}
        >
          {isBusy
            ? props.isCreating
              ? "Erstellt Dokument..."
              : props.isPulling
              ? "Lädt Absprachen vom Server..."
              : props.isLoading
              ? "Lädt Absprachen aus Datenbank..."
              : "Holt Link..."
            : "Absprachen für dieses Datum aktivieren"}
        </button>
      )}
    </>
  );
};
export default AbspracheDetailView;
