import * as React from "react";

export const RoundButton: React.FC<{
  isVisible: boolean;
  onClick: (ev?: any) => void;
  color: string;
  hoverColor: string;
  withEvent?: boolean;
}> = React.memo(function RoundButton({ color, hoverColor, isVisible, onClick, withEvent, children }) {
  const _handleClick = React.useCallback(
    (ev: any) => {
      if (isVisible) {
        if (withEvent) {
          onClick(ev);
        } else {
          onClick();
        }
      }
    },
    [isVisible, onClick, withEvent]
  );

  const classname = `flex flex-grow-0 flex-shrink-0 justify-center items-center w-8 h-8 rounded-full bg-${color} shadow-md p-1 hover:bg-${hoverColor} ${
    isVisible ? "opacity-100 " : "opacity-0 pointer-events-none"
  } transition-all duration-150 ease-in-out`;

  return (
    <div className={classname} onClick={_handleClick}>
      {children}
    </div>
  );
});
