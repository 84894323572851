import { useCallback, useEffect, useMemo, useState } from "react";
import Tools from "../Tools";
import useDayAmount from "./useDayAmount";

const formatDate = (date?: Date, ignoreTime?: boolean) => {
  if (ignoreTime) {
    const temp = date ? new Date(date) : new Date();
    temp.setHours(0);
    temp.setMinutes(0);
    temp.setSeconds(0);
    temp.setMilliseconds(0);
    return temp;
  } else {
    return date ? new Date(date) : new Date();
  }
};

export const useCurrentDate = (ignoreTime?: boolean, intervalInSec?: number) => {
  const [currentDate, setCurrentDate] = useState<Date>(formatDate(new Date(), ignoreTime));

  const updateTime = useCallback(() => {
    setCurrentDate(formatDate(new Date(), ignoreTime));
  }, [ignoreTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateTime();
    }, 1000 * (intervalInSec ?? 60));
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [ignoreTime, intervalInSec, updateTime]);

  const dayAmount = useDayAmount(currentDate);

  const formattedDate = useMemo(() => {
    const formatted = currentDate.toLocaleDateString("de-de", { month: "2-digit", day: "2-digit", year: "numeric" });
    return { display: formatted, isoLike: Tools.dateToIsoLike(currentDate) };
  }, [currentDate]);

  const isoLike = useMemo(() => Tools.dateToIsoLike(currentDate), [currentDate]);
  const frozenDate = useMemo(() => new Date(isoLike), [isoLike]);

  return [currentDate, dayAmount, formattedDate, frozenDate, updateTime] as [
    Date,
    number,
    { display: string; isoLike: string },
    Date,
    typeof updateTime
  ];
};
