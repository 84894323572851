import { RedirectRequest } from "@azure/msal-browser";
import { AuthenticationResult, AuthError } from "@azure/msal-common";
import { useMsal } from "@azure/msal-react";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useToggle } from "./useToggle";

type AuthRedirectHandlerProps = {
  config: RedirectRequest;
  invokeRequestOnLoad?: boolean;
  continueOnError?: (error: AuthError | null) => boolean;
};

const useAuthRedirectHandler = ({
  config,
  continueOnError: checkErrorValid,
  invokeRequestOnLoad,
}: AuthRedirectHandlerProps) => {
  const { instance } = useMsal();

  const [result, setResult] = useState<AuthenticationResult | null>(null);
  const [error, setError] = useState<AuthError | null>(null);

  const [isChecking, , startChecking, stopChecking] = useToggle(true);

  const handleRedirect = useCallback(async () => {
    try {
      startChecking();
      const result = await instance.handleRedirectPromise();
      if (result) {
        setError(() => {
          setResult(result);
          return null;
        });
      }
    } catch (e) {
      const error: AuthError | null = e as any;
      console.error(error);
      if (error) {
        setResult(() => {
          setError(error);
          return null;
        });
      }
    } finally {
      stopChecking();
    }
  }, [instance, startChecking, stopChecking]);

  useLayoutEffect(() => {
    handleRedirect();
  }, [handleRedirect]);

  useLayoutEffect(() => {
    if (invokeRequestOnLoad && !isChecking && (checkErrorValid ? checkErrorValid(error) : !error) && !result) {
      instance.loginRedirect({ ...config });
    }
  }, [checkErrorValid, config, error, instance, invokeRequestOnLoad, isChecking, result]);

  return { isChecking, result, error };
};
export default useAuthRedirectHandler;
