import * as React from "react";
import { AppColors } from "../models/General";

export const SliderBlock = (props: {
  appColors: AppColors;
  buttons: { label: string | number | JSX.Element }[];
  onSelect: (idx: number) => any;
  selectedIndex: number;
  unselectableBeforeIndex?: number;
  unselectableIndexes?: number[];
  relativeSizedButtons?: boolean;
  fullHeight?: boolean;
  textSize?: "xxs" | "xs" | "sm" | "lg" | "xl" | "2xl";
  containerClasses?: string;
}) => {
  const refs = React.useRef<(HTMLDivElement | null)[]>([]);

  const [activeIndex, setActiveIndex] = React.useState(props.selectedIndex);

  const hasLoaded = React.useRef(false);

  const { onSelect } = props;

  const [hasRendered, setHasRendered] = React.useState(false);

  const indexes = React.useMemo(() => props.buttons.map((_, idx) => idx), [props.buttons]);

  const hasUnselectables = React.useMemo(
    () => !!(props.unselectableBeforeIndex || props.unselectableIndexes),
    [props.unselectableBeforeIndex, props.unselectableIndexes]
  );
  const unselectables = React.useMemo(() => {
    if (hasUnselectables) {
      let unselectable: number[] = [];
      if (props.unselectableBeforeIndex) {
        unselectable = indexes.slice(-props.unselectableBeforeIndex);
      }
      if (props.unselectableIndexes) {
        unselectable = [...unselectable, ...props.unselectableIndexes];
      }
      return unselectable;
    }
    return [];
  }, [hasUnselectables, indexes, props.unselectableBeforeIndex, props.unselectableIndexes]);

  const _handleSelect = React.useCallback(
    (idx: number) => {
      onSelect(idx);
    },
    [onSelect]
  );

  const _changeIndex = React.useCallback(
    (idx: number) => {
      if (props.unselectableBeforeIndex !== undefined) {
        if (idx >= props.unselectableBeforeIndex) {
          setActiveIndex(idx);
        }
      } else if (props.unselectableIndexes !== undefined && props.unselectableIndexes.length > 0) {
        if (!props.unselectableIndexes.some((i) => idx === i)) {
          setActiveIndex(idx);
        }
      } else setActiveIndex(idx);
    },
    [props.unselectableBeforeIndex, props.unselectableIndexes]
  );

  React.useEffect(() => {
    if (!hasLoaded.current) {
      _handleSelect(activeIndex);
    } else {
      hasLoaded.current = true;
    }
  }, [_handleSelect, activeIndex]);

  React.useLayoutEffect(() => {
    if (refs.current) {
      setHasRendered(true);
    }
  }, [refs]);

  const style = React.useMemo(
    () =>
      hasRendered
        ? {
            transform: props.relativeSizedButtons
              ? `translate(${
                  refs.current[activeIndex]!.parentElement!.offsetLeft + refs.current[activeIndex]!.offsetLeft
                }px, 0px)`
              : `translate(${refs.current[activeIndex]!.offsetLeft}px, 0px)`,
            width: refs.current[activeIndex]!.clientWidth,
            height: refs.current[activeIndex]!.clientHeight,
          }
        : undefined,
    [activeIndex, hasRendered, props.relativeSizedButtons]
  );

  const buttons = React.useMemo(
    () =>
      props.buttons.map((btn, idx) => {
        const isGrayed = hasUnselectables ? unselectables.some((i) => i === idx) : false;
        return (
          <div
            key={idx}
            ref={props.relativeSizedButtons ? null : (ref) => (refs.current[idx] = ref)}
            onClick={() => _changeIndex(idx)}
            className={`relative z-10 flex flex-1 justify-center items-center bg-transparent rounded-lg text-${
              props.textSize || "sm"
            } leading-none transition-all duration-300 ease-in-out ${
              activeIndex === idx ? `text-${props.appColors.backgroundcolor}-100 font-semibold` : "text-gray-600"
            } ${isGrayed ? "bg-gray-300 pointer-events-none" : ""}`}
          >
            {props.relativeSizedButtons ? (
              <div
                ref={(ref) => (refs.current[idx] = ref)}
                className="flex justify-center items-center leading-none px-3 h-full"
              >
                {btn.label}
              </div>
            ) : (
              btn.label
            )}
          </div>
        );
      }),
    [
      _changeIndex,
      activeIndex,
      hasUnselectables,
      props.appColors.backgroundcolor,
      props.buttons,
      props.relativeSizedButtons,
      props.textSize,
      unselectables,
    ]
  );

  return (
    <div className={`relative flex ${props.fullHeight ? "h-full" : "h-10"} w-full p-1 ${props.containerClasses}`}>
      <div className="absolute w-full h-full rounded-lg overflow-hidden">
        {hasRendered && refs.current[activeIndex] && refs.current[activeIndex] !== null ? (
          <div
            className={`bg-${props.appColors.backgroundcolor}-500 text-${props.appColors.backgroundcolor}-500 shadow-md rounded-lg transition-all duration-300 ease-in`}
            style={style}
          ></div>
        ) : null}
      </div>
      <div className={`relative flex h-full w-full rounded-lg`}>{buttons}</div>
    </div>
  );
};
