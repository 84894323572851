import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Nachricht } from "../../clientApi/models/Nachricht";
import { useSettings } from "../../clientApi/SettingsProvider";
import { Heroicon, Heroicons } from "../../components/Heroicon/Heroicon";
import { InformationDetail } from "../../components/InformationDetail";
import { Popup } from "../../components/Popup";
import { ViewTopSpace } from "../../components/ViewTopSpace";
import { useTheme } from "../../hooks/useTheme";
import { Reservation } from "../../models/Reservation";
import { ModalTypes } from "../Modale/ModalTypes";
import NachrichtenModale from "../Modale/NachrichtenModale";
import { NachrichtBubble } from "./NachrichtBubble";
import { NachrichtenTypes } from "./NachrichtenTypes";
import { useNachrichtenViewModel } from "./NachrichtenViewModel";
import { SearchInput } from "./SearchInput";
import InfiniteScroll from "react-infinite-scroll-component";
import useNachrichtenHelper from "./useNachrichtenHelper";
import { AppColors } from "../../models/General";

const titles: { [key in ModalTypes.Nachrichten]: string } = {
  Body: "Inhalt",
  Note: "Notiz",
  Processing: "Bearbeiten",
  Cancel: "Absagen",
};

export const NachrichtenView = (props: NachrichtenTypes.props) => {
  const viewModel = useNachrichtenViewModel(props);
  const Settings = useSettings();
  const { theme } = useTheme();

  const { getAmountsByType } = useNachrichtenHelper();

  const [listRef, setListRef] = React.useState<any | null>(null);

  const [isDisplayingModal, setIsDisplayingModal] = useState<null | ModalTypes.Nachrichten>(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const popupTitle = useMemo(() => {
    if (isDisplayingModal) {
      return titles[isDisplayingModal];
    } else {
      return "";
    }
  }, [isDisplayingModal]);

  const causeStateToIconAndColor = React.useMemo(() => {
    return new Map<keyof Nachricht.State, [Heroicon, string]>([
      [0, ["ClockIcon", `${theme.backgroundcolor}-500`]],
      [1, ["CheckIcon", `${theme.backgroundcolor}-800`]],
      [2, ["XIcon", `${theme.backgroundcolor}-800`]],
      [3, ["BanIcon", `${theme.backgroundcolor}-800`]],
    ]);
  }, [theme.backgroundcolor]);

  const causeTypeToIcon = React.useMemo(() => {
    return new Map<Nachricht.Type, Heroicon>([
      ["Email", "MailIcon"],
      ["Call", "PhoneIcon"],
      ["Form", "BookmarkIcon"],
      ["MissedCall", "PhoneMissedCallIcon"],
      ["MissedCallMessage", "MicrophoneIcon"],
    ] as [Nachricht.Type, Heroicon][]);
  }, []);

  const infoByType = useMemo(() => {
    return getAmountsByType(viewModel.displayedNachrichten, viewModel.nachrichtenFilteredByType);
  }, [getAmountsByType, viewModel.displayedNachrichten, viewModel.nachrichtenFilteredByType]);

  const [callsAmount, callsProcessedAmount, callsUnprocessedAmount] = useMemo(
    () => infoByType.get("Call") || [0, 0, 0],
    [infoByType]
  );
  const [emailsAmount, emailsProcessedAmount, emailsUnprocessedAmount] = useMemo(
    () => infoByType.get("Email") || [0, 0, 0],
    [infoByType]
  );
  const [formsAmount, formsProcessedAmount, formsUnprocessedAmount] = useMemo(
    () => infoByType.get("Form") || [0, 0, 0],
    [infoByType]
  );

  const [missedCallAmount, missedCallProcessedAmount, missedCallUnprocessedAmount] = useMemo(
    () => infoByType.get("MissedCall") || [0, 0, 0],
    [infoByType]
  );
  const [missedCallMessagesAmount, missedCallMessagesProcessedAmount, missedCallMessagesUnprocessedAmount] = useMemo(
    () => infoByType.get("MissedCallMessage") || [0, 0, 0],
    [infoByType]
  );

  const closePopup = useCallback(() => {
    setIsPopupVisible(false);
    if (popUpTimeout.current) clearTimeout(popUpTimeout.current);
    popUpTimeout.current = setTimeout(() => {
      setIsDisplayingModal(null);
    }, 300);
  }, []);

  const popUpTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (isDisplayingModal) {
      setIsPopupVisible(true);
      return () => {
        setIsPopupVisible(false);
        if (popUpTimeout.current) clearTimeout(popUpTimeout.current);
        popUpTimeout.current = setTimeout(() => {
          setIsDisplayingModal(null);
        }, 300);
      };
    }
  }, [isDisplayingModal]);

  useLayoutEffect(() => {
    const element = document.querySelector(".infinite-scroll-component__outerdiv");
    if (element) {
      setListRef(element);
    }
  }, []);

  useLayoutEffect(() => {
    const test = document.querySelector(".infinite-scroll-component");
    if (test) {
      disableBodyScroll(test);
      return () => {
        enableBodyScroll(test);
      };
    }
  }, []);

  React.useEffect(() => {
    if (listRef) {
      disableBodyScroll(listRef);
      return () => {
        enableBodyScroll(listRef);
      };
    }
  }, [listRef]);

  const bgIconPos = React.useMemo(() => ({ transform: "rotate(15deg)", top: "0.5rem", left: "-1.75rem" }), []);
  const sideButton = React.useMemo(
    () => ({
      label:
        viewModel.isPullingNachrichten && viewModel.isLoadingNachrichten
          ? "Fragt & Lädt..."
          : viewModel.isPullingNachrichten
          ? "Fragt Server..."
          : viewModel.isLoadingNachrichten
          ? "Lädt..."
          : "Aktualisieren",
      onClick: () => {
        viewModel.refreshTime();
        viewModel.refreshNachrichten(viewModel.loadedDates);
      },
    }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      viewModel.isLoadingNachrichten,
      viewModel.isPullingNachrichten,
      viewModel.refreshNachrichten,
      viewModel.loadedDates,
      viewModel.refreshTime,
    ]
  );

  const isTabletMode = useMemo(() => Settings.displayMode === "Tablet", [Settings.displayMode]);

  const isANachrichtSelected = useMemo(() => viewModel.selectedNachricht !== null, [viewModel.selectedNachricht]);

  const showModal = useCallback(
    (modal: ModalTypes.Nachrichten) => () => {
      setIsDisplayingModal(modal);
    },
    []
  );

  const hideModal = useCallback(() => {
    setIsDisplayingModal(null);
  }, []);

  const onConfirmProcess = useCallback(
    async (nachricht: Nachricht.Client<any>, note: string | null) => {
      try {
        await viewModel.processNachricht(nachricht, note);
        hideModal();
      } catch (error) {
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hideModal, viewModel.processNachricht]
  );

  const onCancel = useCallback(
    async (nachricht: Nachricht.Client<any>, email: string | null) => {
      try {
        await viewModel.cancelNachricht.call(undefined, nachricht, email);
        hideModal();
      } catch (error) {
        throw error;
      }
    },
    [hideModal, viewModel.cancelNachricht]
  );

  const handleCall = useCallback((n: Nachricht.Client<any>) => {
    const phoneNumber = (n as any).phoneNumber || n.reservation?.phoneNumber;
    let tempPhone = phoneNumber;
    if (!tempPhone) {
      const regex = n.body?.match(/Telefon:[\s](\+?[0-9]*)/);
      tempPhone = regex ? (phoneNumber && phoneNumber !== "" ? phoneNumber : regex[1] ? regex[1] : null) : null;
      if (tempPhone === null && !n.from.includes("@") && n.from.includes("+")) {
        const match = n.from.replaceAll("-", "").match(/[\d+]+/g);
        tempPhone = match ? match.join("") : null;
      }
    }
    console.log({ tempPhone });
    if (tempPhone) {
      const temp = tempPhone.trim().replaceAll("/", "").replaceAll(" ", "");
      const a = window.document.createElement("a");
      a.href = `tel:${temp}`;
      a.classList.add(..."fixed top-0 left-0 opacity-0 w-0 h-0".split(" "));
      window.document.documentElement.append(a);
      a.click();
      setTimeout(() => {
        a.remove();
      }, 5000);
    }
  }, []);

  const { push } = useHistory();

  const onReservationOpen = useCallback(
    async (res: Reservation) => {
      try {
        const resp = await props.setAppState("appShouldShowReservationDetail", res);
        push({ pathname: "/Reservierung/" });
      } catch (error) {
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.setAppState, push]
  );

  const notizenByFrom = useMemo(() => {
    const calls = viewModel.nachrichtenFilteredByType.get("Call") || [];
    const emails = viewModel.nachrichtenFilteredByType.get("Email") || [];
    const missedCalls = viewModel.nachrichtenFilteredByType.get("MissedCallMessage") || [];
    const temp = [...calls, ...emails, ...missedCalls];
    const mapped: Map<string, string[]> = new Map();
    temp.forEach((nachricht) => {
      if (mapped.has(nachricht.from)) {
        const last = mapped.get(nachricht.from);
        if (last) {
          mapped.set(nachricht.from, [...last, nachricht.note]);
        } else {
          mapped.set(nachricht.from, [nachricht.note]);
        }
      } else {
        mapped.set(nachricht.from, [nachricht.note]);
      }
    });

    return mapped;
  }, [viewModel.nachrichtenFilteredByType]);

  const currentModal = useMemo(() => {
    const { Body, Processing, Note, Cancel } = NachrichtenModale;
    if (isDisplayingModal === null) {
      return <b>Error</b>;
    } else {
      if (viewModel.selectedNachricht) {
        if (isDisplayingModal === "Body") {
          return (
            <Body
              nachricht={viewModel.selectedNachricht}
              onCreateRes={viewModel.handleReservationCreation}
              onCall={handleCall}
            ></Body>
          );
        } else if (isDisplayingModal === "Note") {
          let notes = notizenByFrom.has(viewModel.selectedNachricht.from)
            ? notizenByFrom.get(viewModel.selectedNachricht.from) || []
            : [];
          notes = notes.filter((n) => n !== "" && n !== null && n !== viewModel.selectedNachricht?.note);
          return (
            <Note
              note={viewModel.selectedNachricht.note}
              otherNotes={notes.length ? notes : undefined}
              subTitle={`Andere Notizen zu ${viewModel.selectedNachricht.from}`}
            ></Note>
          );
        } else if (isDisplayingModal === "Processing") {
          return (
            <Processing
              nachricht={viewModel.selectedNachricht}
              canConfirm={viewModel.selectedNachricht.state === 0}
              onConfirm={onConfirmProcess}
            ></Processing>
          );
        } else if (isDisplayingModal === "Cancel") {
          return (
            <Cancel
              nachricht={viewModel.selectedNachricht}
              canCancel={viewModel.selectedNachricht.state === 0}
              onCancel={onCancel}
            ></Cancel>
          );
        } else return <b>Error</b>;
      }
      return <b>Error</b>;
    }
  }, [
    handleCall,
    isDisplayingModal,
    notizenByFrom,
    onCancel,
    onConfirmProcess,
    viewModel.handleReservationCreation,
    viewModel.selectedNachricht,
  ]);

  const bubbles = useMemo(
    () =>
      viewModel.displayedNachrichten.map((nachricht, idx) => (
        <NachrichtBubble
          key={nachricht.id}
          nachricht={nachricht}
          isLoading={viewModel.processingNachrichten.some((id) => id === nachricht.id)}
          isGettingReservation={
            viewModel.reservierungenByNachrichtId.get(nachricht.id) === null ||
            viewModel.loadingReservations.some((c) => c === nachricht.reservationId)
          }
          isSelected={isANachrichtSelected && viewModel.selectedNachricht!.id === nachricht.id}
          onOpenEmail={showModal("Body")}
          onSelect={viewModel.toggleNachrichtSelection}
          onAdd={viewModel.handleReservationCreation}
          onRemove={showModal("Processing")}
          // onCancel={showModal("Cancel")}
          onOpenNote={showModal("Note")}
          onDetailRequest={onReservationOpen}
          initialReservation={viewModel.reservierungenByNachrichtId.get(nachricht.id) || undefined}
          causeStateToIconAndColor={causeStateToIconAndColor}
          causeTypeToIcon={causeTypeToIcon}
          callAmount={viewModel.amountPerCall.get(nachricht.from) || 1}
          onSearchFor={viewModel.setSearchTerm}
          frozenDate={viewModel.frozenDate}
        />
      )),
    [
      causeStateToIconAndColor,
      causeTypeToIcon,
      isANachrichtSelected,
      onReservationOpen,
      showModal,
      viewModel.amountPerCall,
      viewModel.displayedNachrichten,
      viewModel.frozenDate,
      viewModel.handleReservationCreation,
      viewModel.loadingReservations,
      viewModel.processingNachrichten,
      viewModel.reservierungenByNachrichtId,
      viewModel.selectedNachricht,
      viewModel.setSearchTerm,
      viewModel.toggleNachrichtSelection,
    ]
  );

  return (
    <div
      className={`relative flex flex-1 flex-col justify-start items-start min-h-0 h-full max-h-screen max-w-full overflow-hidden -mt-10 pt-10`}
    >
      <Popup
        beingDisplayed={isPopupVisible}
        close={closePopup}
        title={popupTitle}
        ratio={"1x9"}
        appIsStandalone={Settings.isStandalone}
      >
        {currentModal}
      </Popup>
      <ViewTopSpace
        appColors={theme}
        backgroundIconProps={{ height: "60%", width: "50%" }}
        backgroundIcon="ChatAlt2Icon"
        backgroundIconPosition={bgIconPos}
        sideButton={sideButton}
        height={isTabletMode ? "12" : "20"}
      >
        <Header
          color={theme}
          deadline={viewModel.deadlineDate}
          today={viewModel.frozenDate}
          isTabletMode={isTabletMode}
          callsAmount={callsAmount}
          callsUnprocessedAmount={callsUnprocessedAmount}
          emailsAmount={emailsAmount}
          emailsUnprocessedAmount={emailsUnprocessedAmount}
          formsAmount={formsAmount}
          formsUnprocessedAmount={formsUnprocessedAmount}
          missedCallAmount={missedCallAmount}
          missedCallMessagesAmount={missedCallMessagesAmount}
          missedCallMessagesUnprocessedAmount={missedCallMessagesUnprocessedAmount}
          missedCallUnprocessedAmount={missedCallUnprocessedAmount}
        />
      </ViewTopSpace>
      <div
        className={`relative flex flex-col px-1 min-h-0 w-full bg-${theme.backgroundcolor}-700 flex-1 overflow-hidden min-h-0`}
      >
        <SearchInput
          causeStateToIconAndColor={causeStateToIconAndColor}
          causeTypeToIcon={causeTypeToIcon}
          filteredTypes={viewModel.filteredTypesAndStates}
          onFilterTypeClick={viewModel.filterToggleTypeOrState}
          resetSearch={viewModel.resetSearch}
          searchTerm={viewModel.searchTerm}
          setSearchTerm={viewModel.setSearchTerm}
        ></SearchInput>
        <div className="relative isolate inline-flex flex-col w-full min-h-0 flex-1 max-h-full">
          <InfiniteScroll
            className="relative inline-flex flex-col justify-start items-start content-start w-full h-full min-h-full max-h-full pb-48 pt-1 gap-1"
            height="100%"
            dataLength={viewModel.displayedNachrichten.length}
            next={viewModel.moveDateBack}
            hasMore
            loader={<Loader key="loader" />}
          >
            {bubbles}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

const Loader = () => (
  <div className="fixed inset-0 left-0 right-0 mx-auto top-12 inline-flex justify-center items-center bg-opacity-50 rounded-lg p-2 w-48 h-12 pb-2 bg-gray-100 z-40 pointer-events-none">
    <Heroicons.Outline.RefreshIcon
      className={`w-8 h-8 text-blue-600 transition-opacity duration-100 ease-in-out animate-spin`}
    />
  </div>
);

const Header = ({
  callsAmount,
  callsUnprocessedAmount,
  color,
  deadline,
  emailsAmount,
  emailsUnprocessedAmount,
  formsAmount,
  formsUnprocessedAmount,
  isTabletMode,
  missedCallAmount,
  missedCallMessagesAmount,
  missedCallMessagesUnprocessedAmount,
  missedCallUnprocessedAmount,
  today,
}: {
  isTabletMode: boolean;
  color: AppColors;
  today: Date;
  deadline: Date;
  callsAmount: number;
  callsUnprocessedAmount: number;
  emailsUnprocessedAmount: number;
  emailsAmount: number;
  formsUnprocessedAmount: number;
  formsAmount: number;
  missedCallUnprocessedAmount: number;
  missedCallAmount: number;
  missedCallMessagesUnprocessedAmount: number;
  missedCallMessagesAmount: number;
}) => {
  const dateString = useMemo(
    () => `${today.toLocaleDateString("de-de")} bis ${deadline.toLocaleDateString("de-de")}`,
    [deadline, today]
  );

  return (
    <div className="relative flex flex-1 flex-col w-full h-full justify-center items-start">
      {isTabletMode ? null : (
        <h3
          className={`flex flex-grow-0 flex-shrink-0 h-8 w-full text-${color.backgroundcolor}-100 items-center px-3 text-base font-medium`}
        >
          <span className="truncate">{dateString}</span>
        </h3>
      )}

      <div className="relative flex flex-row space-x-3 pl-3">
        <InformationDetail appColors={color} icon="PhoneIcon">
          {callsUnprocessedAmount} / {callsAmount}
        </InformationDetail>

        <InformationDetail appColors={color} icon="MailIcon">
          {emailsUnprocessedAmount} / {emailsAmount}
        </InformationDetail>

        <InformationDetail appColors={color} icon="BookmarkAltIcon">
          {formsUnprocessedAmount} / {formsAmount}
        </InformationDetail>
        <InformationDetail appColors={color} icon="PhoneMissedCallIcon">
          {missedCallUnprocessedAmount} / {missedCallAmount}
        </InformationDetail>
        <InformationDetail appColors={color} icon="MicrophoneIcon">
          {missedCallMessagesUnprocessedAmount} / {missedCallMessagesAmount}
        </InformationDetail>
      </div>
    </div>
  );
};

export type ViewModelType = ReturnType<typeof useNachrichtenViewModel>;
