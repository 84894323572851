import React, { useCallback, useEffect, useMemo, memo, useState, useLayoutEffect } from "react";
import { AppFunctions, AppState } from "../../App";
import { TailwindBackgroundColors, DisplayMode, getLocalstorageSize } from "../../models/General";
import { SliderBlock } from "../SliderBlock";
import { useSettings } from "../../clientApi/SettingsProvider";
import { useMsal } from "@azure/msal-react";
import { useTheme } from "../../hooks/useTheme";
import { Heroicons } from "../Heroicon/Heroicon";
import { CheckIcon, CogIcon, XIcon } from "@heroicons/react/solid";
import ClientApi from "../../clientApi/ClientApi";
import { useToasterHandler } from "../ToasterHandler/useToasterHandler";
import useStylesheet from "../../hooks/useStylesheet";
import Sizes, { Size } from "../../types/Sizes";
import UserMenuHeader from "./UserMenuHeader";
import MenuSection from "./MenuSection";
import { SAVED_ACCOUNT_LOCATION, useSavedAccount } from "../../hooks/useSavedAccount";

const enviromentBlocks = [{ label: "Produktiv" }, { label: "Testdaten" }];

const webSocketStates = ["Verbindet...", "Verbunden", "Schließt...", "Keine Verbindung"];

const modes: DisplayMode[] = ["Web", "Tablet", "Mobile"];
const displayModeBlocks = [
  {
    label: <Heroicons.Outline.DesktopComputerIcon className="w-4 h-4" />,
  },
  {
    label: <Heroicons.Outline.DeviceTabletIcon className="w-4 h-4" />,
  },
  {
    label: <Heroicons.Outline.DeviceMobileIcon className="w-4 h-4" />,
  },
];

const sizeModes: Size[] = ["small", "base", "big", "bigger", "biggest", "large"];
const sizeModeBlocks = [
  {
    label: <span className="text-xxs">A</span>,
  },
  {
    label: <span className="text-xs">A</span>,
  },
  {
    label: <span className="text-sm">A</span>,
  },
  {
    label: <span className="text-base">A</span>,
  },
  {
    label: <span className="text-lg">A</span>,
  },
  {
    label: <span className="text-xl">A</span>,
  },
];

const colSizes = [{ label: "Auto" }, { label: "1" }, { label: "2" }, { label: "3" }, { label: "4" }];

export const UserMenu = (props: {
  isVisible: boolean;
  setAppState: AppFunctions["setAppState"];
  appRestaurantId: AppState["appRestaurantId"];
  appDisplayMode: AppState["appDisplayMode"];
  appColSize: AppState["appColSize"];
  appColors: AppState["appColors"];
  appWebSocket: AppState["appWebSocket"];
  appInitialiseWebSocket: AppFunctions["appInitialiseWebsocket"];
  appVersion?: string;
  appSize: Size;
  possibleColors: TailwindBackgroundColors[];
  handleClose: () => any;
  handleColorChange: (color: TailwindBackgroundColors) => any;
  appUser: AppState["appUser"];
}) => {
  const Settings = useSettings();
  const [webSocketState, setWebSocketState] = useState<number>(0);
  const [localStorageSize, setLocalStorageSize] = useState(getLocalstorageSize());
  const { instance } = useMsal();

  const [account, , clearAccount] = useSavedAccount();

  const { setTheme } = useTheme();

  const { styleSheet, clearStyleSheet } = useStylesheet();

  const changeSize = useCallback(
    (size: number) => {
      if (styleSheet) {
        if (size !== -1) {
          clearStyleSheet();
          Sizes[size].map((rule) => styleSheet.insertRule(rule));
        }
      }
    },
    [clearStyleSheet, styleSheet]
  );

  const onColorChange = useCallback(
    (color: TailwindBackgroundColors) => () => {
      setTheme(color);
      props.handleColorChange.call(undefined, color);
    },
    [props.handleColorChange, setTheme]
  );

  useEffect(() => {
    if (props.appWebSocket) {
      const ws = props.appWebSocket;
      const _handleChange = () => setWebSocketState(ws.readyState);
      ws.addEventListener("open", _handleChange);
      ws.addEventListener("close", _handleChange);
      return () => {
        ws.removeEventListener("open", _handleChange);
        ws.removeEventListener("close", _handleChange);
      };
    }
  }, [props.appWebSocket]);

  const handleEnviromentSelect = useCallback(
    (idx: number) => {
      setTimeout(() => {
        if (!!idx && props.appRestaurantId !== "UnitTesting") {
          props.setAppState.call(undefined, "appRestaurantId", "UnitTesting");
          Settings.set.call(undefined, "mandant", "UnitTesting");
        } else if (!idx && props.appRestaurantId !== "GezeitenLaAmarone") {
          props.setAppState.call(undefined, "appRestaurantId", "GezeitenLaAmarone");
          Settings.set.call(undefined, "mandant", "GezeitenLaAmarone");
        }
      }, 300);
    },
    [Settings.set, props.appRestaurantId, props.setAppState]
  );

  const { set } = useSettings();

  const _handleDisplayMode = useCallback(
    (idx: number) => {
      setTimeout(() => {
        set("displayMode", modes[idx]);
        props.setAppState.call(undefined, "appDisplayMode", modes[idx]);
      }, 300);
    },
    [props.setAppState, set]
  );

  const _handleSizeChange = useCallback(
    (idx: number) => {
      setTimeout(() => {
        set("size", sizeModes[idx]);
        changeSize(idx);
      }, 300);
    },
    [changeSize, set]
  );

  const _handleColSize = useCallback(
    (idx: number) => {
      setTimeout(() => {
        props.setAppState.call(undefined, "appColSize", idx);
      }, 300);
    },
    [props.setAppState]
  );

  const _handleWebSocketRequest = useCallback(() => {
    return props.appInitialiseWebSocket.call(undefined, undefined, true);
  }, [props.appInitialiseWebSocket]);

  const _handleFlushCache = useCallback(() => {
    if (localStorage) {
      const clearQueue = window.confirm("Queue löschen?");
      const keys = Object.keys(localStorage).filter((key) => key.includes(`${props.appRestaurantId}_get`));
      keys.map((key) => localStorage.removeItem(key));
      if (clearQueue) {
        localStorage.setItem("MKGASTRO_QUEUE", JSON.stringify([]));
      }
      setLocalStorageSize(getLocalstorageSize());
    }
  }, [props.appRestaurantId]);

  const _handleEnviromentChange = () => {
    const env = window.prompt("Wechsel zu:", window.location.href);
    window.location.href = env || window.location.href;
  };

  const _shareData = React.useCallback(async (text: string, title: string) => {
    try {
      if (navigator && "share" in (navigator as any) && (navigator as any).share) {
        const share = await (navigator as any).share({
          text,
          title,
        });
        return;
      } else {
        const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(text);
        const downloadAnchor = document.createElement("a");
        downloadAnchor.setAttribute("href", dataStr);
        downloadAnchor.setAttribute("download", title + ".json");
        document.body.appendChild(downloadAnchor);
        downloadAnchor.click();
        downloadAnchor.remove();
        return;
      }
    } catch (error) {
      throw error;
    }
  }, []);

  const _shareQueue = useCallback(() => {
    const date = new Date().toISOString().slice(0, 10);
    const storage = localStorage.getItem("MKGASTRO_QUEUE");
    if (storage) {
      _shareData(storage, `Queue_${date}`);
    } else {
      window.alert("Keine Queue vorhanden");
    }
  }, [_shareData]);

  const selectedDisplayMode = useMemo(() => modes.indexOf(props.appDisplayMode), [props.appDisplayMode]);
  const selectedSizeMode = useMemo(() => sizeModes.indexOf(props.appSize), [props.appSize]);

  const colors = useMemo(
    () =>
      props.possibleColors.map((color, idx) => (
        <div
          key={color}
          className={`flex w-8 h-8 p-px mr-1 mt-1 shadow rounded cursor-pointer transition-all duration-200 ease-in-out`}
        >
          <div className={`relative w-full h-full bg-${color}-500 rounded`} onClick={onColorChange(color)}>
            {props.appColors.backgroundcolor === color ? (
              <div className="absolute w-full h-full flex justify-center items-center p-2 text-white">
                <CheckIcon className="w-full h-full" />
              </div>
            ) : null}
          </div>
        </div>
      )),
    [onColorChange, props.appColors.backgroundcolor, props.possibleColors]
  );

  const handleLogout = useCallback(async () => {
    try {
      clearAccount();
      const resp = await instance.logoutRedirect();
      return resp;
    } catch (error) {
      throw new Error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance.logoutRedirect, clearAccount]);

  const { addMessage } = useToasterHandler();
  const nukeCache = useCallback(async () => {
    try {
      await ClientApi.DANGEROUSLY_COMPLETLY_CLEAR(
        (msg) => {
          addMessage({
            title: "Nuke Cache - sessionStorage",
            text: msg,
            color: "blue",
            icon: "ShieldExclamationIcon",
          });
        },
        (msg) => {
          addMessage({
            title: "Nuke Cache - localStorage",
            text: msg,
            color: "blue",
            icon: "ShieldExclamationIcon",
          });
        },
        (msg) => {
          addMessage({
            title: "Nuke Cache - indexedDB",
            text: msg,
            color: "blue",
            icon: "ShieldExclamationIcon",
          });
        },
        (msg) => {
          addMessage({
            title: "Nuke Cache - Erfolgreich",
            text: msg,
            color: "green",
            icon: "ShieldExclamationIcon",
            delay: 4900,
          });
        }
      );
    } catch (error) {
      addMessage({
        title: "Nuke Cache",
        text: "Cache konnte nicht gelöscht werden und jetzt beschädigt sein",
        color: "red",
        icon: "ShieldExclamationIcon",
        delay: 5000000000,
      });
    }
  }, [addMessage]);

  return (
    <aside
      className={`absolute flex flex-col flex-shrink-0 w-64 bg-white rounded-bl-lg shadow-md top-0 right-0 mt-10 pb-2 transition-transform transform ease-in-out duration-300 delay-100 ${
        props.isVisible ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <UserMenuHeader color={props.appColors} onClose={props.handleClose} />
      <MenuSection label="Farbe" icon="ColorSwatchIcon" shortInfo={props.appColors.backgroundcolor}>
        {colors}
      </MenuSection>
      <MenuSection
        label="Umgebung"
        icon="LibraryIcon"
        shortInfo={enviromentBlocks[props.appRestaurantId === "UnitTesting" ? 1 : 0].label}
      >
        <div className="flex flex-1 w-full h-full bg-gray-100 shadow-inner rounded-lg">
          <SliderBlock
            textSize="xxs"
            buttons={enviromentBlocks}
            appColors={props.appColors}
            onSelect={handleEnviromentSelect}
            selectedIndex={props.appRestaurantId === "UnitTesting" ? 1 : 0}
          ></SliderBlock>
        </div>
      </MenuSection>
      <MenuSection
        label="Gerät"
        icon="EyeIcon"
        shortInfo={selectedDisplayMode === 0 ? "Desktop" : selectedDisplayMode === 1 ? "Tablet" : "Mobile"}
      >
        <div className="flex flex-1 w-full h-full bg-gray-100 shadow-inner rounded-lg">
          <SliderBlock
            textSize="xxs"
            buttons={displayModeBlocks}
            appColors={props.appColors}
            onSelect={_handleDisplayMode}
            selectedIndex={selectedDisplayMode}
          ></SliderBlock>
        </div>
      </MenuSection>
      <MenuSection label="Größe" icon="SearchIcon" shortInfo={sizeModes[selectedSizeMode] ?? "??"}>
        <div className="flex flex-1 w-full h-full bg-gray-100 shadow-inner rounded-lg">
          <SliderBlock
            textSize="xxs"
            buttons={sizeModeBlocks}
            appColors={props.appColors}
            onSelect={_handleSizeChange}
            selectedIndex={selectedSizeMode}
          ></SliderBlock>
        </div>
      </MenuSection>
      <MenuSection
        label="Benutzer"
        icon="UserCircleIcon"
        shortInfo={account?.name ?? account?.username ?? props.appUser?.name ?? props.appUser?.username ?? "--"}
      >
        <div className="inline-grid grid-cols-4 w-full min-h-0">
          <div className="flex flex-col flex-1 col-span-3 justify-start items-start leading-tight">
            <span className="text-xxs text-gray-500">Eingeloggt als: </span>
            <span className="text-xxs text-gray-500 mt-2">Name: </span>
            <span className="text-xxs font-semibold text-gray-600">{account?.name ?? props.appUser?.name ?? "--"}</span>
            <span className="text-xxs text-gray-500 mt-2">Username: </span>
            <span className="text-xxs font-semibold text-gray-600">
              {account?.username ?? props.appUser?.username ?? "--"}
            </span>
          </div>
          <span
            className={`ml-auto hover:text-red-500 hover:underline font-semibold cursor-pointer transition-color duration-200 ease-in-out text-xxs py-1 uppercase text-gray-500 ring-1 ring-gray-200 rounded text-center mr-1 px-2 my-auto`}
            onClick={handleLogout}
          >
            logout
          </span>
        </div>
      </MenuSection>
      <MenuSection
        label="Websocket"
        icon="WifiIcon"
        shortInfo={props.appWebSocket ? webSocketStates[webSocketState] : "--"}
      >
        <div className="inline-grid grid-cols-2 w-full min-h-0">
          <div className="flex flex-1 flex-col justify-start items-start">
            <span className="text-xxs text-gray-500">Status: </span>
            <span className="text-xxs font-semibold text-gray-600">
              {props.appWebSocket ? webSocketStates[webSocketState] : "--"}
            </span>
          </div>
          <span
            className={`ml-auto hover:text-red-500 hover:underline font-semibold cursor-pointer transition-color duration-200 ease-in-out text-xxs py-1 uppercase text-gray-500 ring-1 ring-gray-200 rounded text-center mr-1 px-2 my-auto`}
            onClick={_handleWebSocketRequest}
          >
            reconnect
          </span>
        </div>
      </MenuSection>
      <MenuSection
        label="Cache"
        icon="FolderIcon"
        shortInfo={props.appWebSocket ? webSocketStates[webSocketState] : "--"}
      >
        <div className="inline-grid grid-cols-2 justify-center items-center content-center w-full min-h-0">
          <div className="flex flex-1 flex-col justify-start items-start">
            <span className="text-xxs text-gray-500">Größe: </span>
            <span className="text-xxs font-semibold text-gray-600">{localStorageSize || "--"}</span>
          </div>
          <div className="flex flex-1 justify-start items-start pl-6 p-1 mt-1 leading-tight">
            <button
              className="px-3 py-1 text-xs font-semibold text-white bg-red-500 rounded-md shadow hover:bg-red-600 tracking-wide"
              onClick={nukeCache}
            >
              Komplett leeren
            </button>
          </div>
          <div className="flex flex-1 justify-start items-start pl-6 p-1 mt-1 leading-tight">
            <span className="text-gray-500 text-xs">Inhalt teilen</span>
            <div className="flex flex-1 flex-col justify-center items-end text-gray-600">
              <Heroicons.Solid.ShareIcon className="h-8 w-8 p-1" onClick={_shareQueue} />
            </div>
          </div>
          <span
            className={`ml-auto hover:text-red-500 hover:underline font-semibold cursor-pointer transition-color duration-200 ease-in-out text-xxs py-1 uppercase text-gray-500 ring-1 ring-gray-200 rounded text-center mr-1 px-2 my-auto`}
            onClick={_handleFlushCache}
          >
            Clear
          </span>
        </div>
      </MenuSection>
      <MenuSection label="Information" icon="InformationCircleIcon" shortInfo={props.appVersion ?? "--"}>
        <span className="text-xxs text-gray-500" onClick={() => window.prompt("Url", window.location.origin)}>
          {window.location.host}
        </span>
      </MenuSection>

      <div className="grid grid-cols-3 flex-1 gap-2 justify-start items-start pl-6 p-1 mt-1 leading-tight">
        <div className="flex flex-1 flex-col justify-start items-start">
          <span className="text-xxs text-gray-500">Window: </span>
          <span className="text-xxs font-semibold text-gray-600">
            {window.innerHeight} - {window.outerHeight}
          </span>
        </div>
        <div className="flex flex-1 flex-col justify-start items-start">
          <span className="text-xxs text-gray-500">App: </span>
          <span className="text-xxs font-semibold text-gray-600">
            {document.getElementById("App")?.clientHeight} - {document.getElementById("App")?.offsetHeight}
          </span>
        </div>
        <div className="flex flex-1 flex-col justify-start items-start">
          <span className="text-xxs text-gray-500">Document: </span>
          <span className="text-xxs font-semibold text-gray-600">
            {window?.document?.documentElement?.clientHeight} - {window?.document?.documentElement?.offsetHeight}
          </span>
        </div>
      </div>
    </aside>
  );
};
