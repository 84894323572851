import * as React from "react";
import { AppColors } from "../models/General";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

type SeperatorTableProps = {
  data: { [key: string]: { [key: string]: any[] } };
  numberOfColumns?: number;
  elementRender: (data: any) => JSX.Element;
  appColors: AppColors;
};

export const SeperatorTable = (props: SeperatorTableProps) => {
  const [headRefs, setHeadRefs] = React.useState<{ [key in typeof props.data & string]: HTMLSpanElement } | undefined>(
    {} as { [key in typeof props.data & string]: HTMLSpanElement }
  );

  const [hasScrollReachedEnd, setHasScrollReachedEnd] = React.useState(false);

  const [scrollRef, setScrollRef] = React.useState<HTMLDivElement | null>(null);

  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    if (isLoaded === false) {
      setIsLoaded(true);
    }
  }, [isLoaded]);

  React.useEffect(() => {
    if (scrollRef) {
      disableBodyScroll(scrollRef);
      return () => {
        enableBodyScroll(scrollRef);
      };
    }
  }, [scrollRef]);

  React.useLayoutEffect(() => {
    if (isLoaded && scrollRef !== null && scrollRef !== undefined) {
      scrollRef.addEventListener("scroll", _handleScrollEvent);
      return () => {
        return scrollRef.removeEventListener("scroll", _handleScrollEvent);
      };
    }
  }, [isLoaded, scrollRef]);

  const _handleScrollEvent = (ev: Event) => {
    if (ev.target) {
      const { scrollTop, scrollHeight, clientHeight } = ev.target as HTMLDivElement;
      setHasScrollReachedEnd(() => {
        const currentLocation = scrollHeight - scrollTop;
        if (currentLocation - 10 < clientHeight) {
          return true;
        } else {
          return false;
        }
      });
    }
  };

  const { numberOfColumns, elementRender } = props;

  const _elementRender: (data: any, index: string | number) => JSX.Element = React.useCallback(
    (data, index) => {
      return (
        <div
          className={`flex justify-center items-center flex-grow-0`}
          style={{
            flexBasis: numberOfColumns ? `calc(100% / ${numberOfColumns})` : "100%",
          }}
          key={index}
        >
          {elementRender(data)}
        </div>
      );
    },
    [elementRender, numberOfColumns]
  );

  const _elementGroupRender: (elementGroup: any[], index: string | number) => JSX.Element = React.useCallback(
    (elementGroup, index) => {
      return (
        <div className={`flex justify-start items-center flex-grow-0 w-full flex-wrap pb-3`} key={index}>
          {elementGroup.map((element, index) => _elementRender(element, index))}
        </div>
      );
    },
    [_elementRender]
  );

  const _handleHeaderClick = React.useCallback(
    (name: keyof { [key: string]: { [key: string]: any[] } }) => {
      if (headRefs && (headRefs as any)[name] && scrollRef) {
        const ref = (headRefs as any)[name];
        ref.scrollIntoView({ behavior: "smooth", block: "nearest" });
        // if (ref.parentElement) {
        //   ref.parentElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
        // } else {

        // }
      }
    },
    [headRefs, scrollRef]
  );

  const style = React.useMemo(
    () => ({
      height: "calc(100% + 6rem)",
      top: "-3rem",
    }),
    []
  );

  const entries = React.useMemo(
    () =>
      Object.keys(props.data).map((key, idx) => {
        return (
          <div className="relative" key={idx}>
            <div
              ref={(ref) => {
                if ((ref && (headRefs as any)[key] === null) || (headRefs as any)[key] === undefined)
                  return setHeadRefs((refs) => ({ ...refs, [key]: ref }));
              }}
              className="absolute w-px"
              style={style}
            ></div>
            <div
              className={`sticky top-0 flex h-8 flex-grow-0 flex-shrink-0 justify-start items-center px-2 shadow text-${props.appColors.backgroundcolor}-700 backdrop-blurred z-10 leading-none bg-opacity-80 bg-white`}
            >
              <span>{key}</span>
            </div>
            <div className={`flex py-2 flex-col`}>
              {Object.keys(props.data[key]).map((groupName, secondIndex) => {
                return _elementGroupRender(props.data[key][groupName as any], secondIndex);
              })}
            </div>
          </div>
        );
      }),
    [_elementGroupRender, headRefs, props.appColors.backgroundcolor, props.data, style]
  );

  const buttons = React.useMemo(
    () =>
      headRefs && Object.keys(props.data).length === Object.keys(headRefs).length
        ? Object.keys(props.data).map((item) => (
            <div
              className={`flex flex-1 h-12 justify-center items-center px-2 overflow-auto`}
              key={item}
              onClick={() => _handleHeaderClick(item)}
            >
              <span
                className={`flex justify-center items-center text-${props.appColors.backgroundcolor}-700 border-${props.appColors.backgroundcolor}-700 h-12 w-full flex-grow-0 border-b-2 text-xs`}
              >
                {item}
              </span>
            </div>
          ))
        : null,
    [_handleHeaderClick, headRefs, props.appColors.backgroundcolor, props.data]
  );

  return (
    <div className="relative flex flex-1 flex-col min-h-0 bg-gray-100">
      <div
        className={`absolute top-0 flex flex-shrink-0 flex-grow-0 justify-start items-start h-16 w-full transition-all duration-500 ease-in-out bg-white backdrop-blurred bg-opacity-80 z-20`}
      >
        <div className="flex flex-1 flex-shrink-0 h-12 max-w-full overflow-auto">{buttons}</div>
      </div>
      <div
        ref={setScrollRef}
        className={`relative inline-flex flex-col flex-1 min-h-0 max-h-full w-full flex-shrink-0 justify-start overflow-auto pt-16`}
      >
        {entries}
        <div
          className={`flex justify-around items-center h-16 w-full flex-shrink-0 bg-gray-200 pt-8 shadow-inner`}
        ></div>
      </div>
    </div>
  );
};
