import * as React from "react";
import { SummaryTableTime } from "../models/Summary";
import { AppColors } from "../models/General";

const base = {
  minWidth: "1rem",
  maxWidth: "25%",
};

export const TimeColumn = React.memo(function TimeColumn(props: {
  TableTime: SummaryTableTime[0];
  time: string;
  total: number;
  maximum: number;
  appColors: AppColors;
}) {
  const [columnRef, setColumnRef] = React.useState<HTMLDivElement | null>(null);
  const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(null);

  const maximum = React.useMemo(() => (props.maximum ? props.maximum : 1), [props.maximum]);
  const guestTotal = React.useMemo(() => +props.TableTime.guestTotal, [props.TableTime.guestTotal]);

  const timeString = React.useMemo(() => {
    if (props.time === "lädt") {
      return props.time;
    }
    const indexPoint = props.time.indexOf(":");
    if (props.time[indexPoint + 1] === "0") {
      return props.time.slice(0, 2);
    } else return props.time.slice(2, 5);
  }, [props.time]);

  const height = React.useMemo(
    () => ({
      height: !isNaN(guestTotal) ? `calc((100% / ${maximum}) * ${guestTotal})` : "1px",
    }),
    [guestTotal, maximum]
  );

  const shouldDisplayNumberInside = React.useMemo(
    () => !(height && columnRef && containerRef && columnRef.clientHeight < containerRef.clientHeight - 13),
    [columnRef, containerRef, height]
  );

  return (
    <div
      className={`relative flex flex-col flex-1 justify-end items-end text-xxs leading-tight text-${props.appColors.backgroundcolor}-700 z-10`}
      style={base}
    >
      <div ref={setContainerRef} className="flex justify-end items-end w-full h-full">
        <div
          ref={setColumnRef}
          style={height}
          className={`relative bg-${props.appColors.backgroundcolor}-400 border-t-2 border-${props.appColors.backgroundcolor}-300 w-full`}
        >
          <div className="absolute top-0 left-0 flex h-full w-full justify-center items-start">
            <span
              className={`inline-flex font-bold rounded leading-tight ${
                shouldDisplayNumberInside ? "text-white" : "-mt-4"
              }`}
            >
              {guestTotal || "--"}
            </span>
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col justify-center items-center z-10 w-full h-4 flex-shrink-0 flex-grow-0 leading-tight`}
      >
        <span className="font-semibold leading-none">{timeString}</span>
      </div>
    </div>
  );
});
