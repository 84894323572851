const isReachable = async (serverUrl: string) => {
  try {
    const wrappedResp = new Promise<Response>((resolve, reject) =>
      fetch(`${serverUrl}?checkTime=${+new Date()}`, {
        method: "HEAD",
        mode: "no-cors",
        cache: "no-store",
        headers: {
          "Cache-Control": "no-store",
          pragma: "no-cache",
        },
      })
        .then(resolve)
        .catch(reject)
    );
    const resp = await wrappedResp;
    if (resp && (resp.ok || resp.type === "opaque")) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export type QueueElement<T> = {
  value: T;
  reference: string;
};

const Queue = <T>({
  handleQueueElement,
  initialQueue,
  onError,
  onAdd,
  onQueueChange,
  maxFailAmount,
  shouldCheckConnection,
}: {
  handleQueueElement: (element: T, queue: QueueElement<T>[], errors: QueueElement<T>[]) => Promise<any> | any;
  initialQueue?: QueueElement<T>[];
  onError?: (error: any, element: T, errors: QueueElement<T>[]) => Promise<any> | any;
  onAdd?: (element: T, queue: QueueElement<T>[]) => Promise<any> | any;
  onQueueChange?: (queue: QueueElement<T>[], errors: QueueElement<T>[]) => Promise<any> | any;
  maxFailAmount: number;
  shouldCheckConnection: boolean;
}) => {
  let currentQueue: { value: T; reference: string }[] = initialQueue || [];
  let referenceErrors: { [key: string]: number } = {};
  let errors: typeof currentQueue = [];
  let queueBlocked = false;
  let isOnline = { is: false };

  const checkIfOnline = async () => {
    try {
      if (!shouldCheckConnection) return { ...isOnline, is: true };
      if (navigator && "onLine" in navigator && typeof navigator.onLine === "boolean") {
        if (navigator.onLine) {
          const resp = await isReachable(window.location.origin);
          if (resp) {
            isOnline = { ...isOnline, is: true };
          } else {
            isOnline = { ...isOnline, is: false };
          }
          return;
        } else {
          isOnline = { ...isOnline, is: false };
        }
        return;
      } else {
        const resp = await isReachable(window.location.origin);
        if (resp) {
          isOnline = { ...isOnline, is: true };
        } else {
          isOnline = { ...isOnline, is: false };
        }
        return;
      }
    } catch (error) {
      isOnline = { ...isOnline, is: false };
      throw error;
    }
  };

  const findInQueueByKey = <B extends keyof T>(key: B, value: T[B]) => {
    const matches = currentQueue.filter((item) => key in item.value && item.value[key] === value);
    return matches;
  };

  const findInQueueByReference = (reference: QueueElement<T>["reference"]) => {
    const matches = currentQueue.filter((item) => item.reference === reference);
    return matches;
  };

  const setQueue = (queue: QueueElement<T>[], withoutCallback?: boolean) => {
    currentQueue = queue;
    if (onQueueChange && !withoutCallback) {
      onQueueChange(currentQueue, errors);
    }
  };

  const setErrors = (queue: QueueElement<T>[], withoutCallback?: boolean) => {
    errors = queue;
    if (onQueueChange && !withoutCallback) {
      onQueueChange(currentQueue, errors);
    }
  };

  const handleQueueEntry = async (element: typeof currentQueue[0], rest: typeof currentQueue) => {
    try {
      const errorAmountForReference =
        referenceErrors[element.reference] !== undefined && referenceErrors[element.reference];
      if (errorAmountForReference >= maxFailAmount) {
        setQueue([...rest]);
        setErrors([...errors, element]);
        return;
      } else {
        await handleQueueElement(element.value, currentQueue, errors);
        return;
      }
    } catch (error) {
      throw error;
    }
  };

  const handleQueueError = async (element: typeof currentQueue[0], rest: typeof currentQueue, error: any) => {
    if (referenceErrors[element.reference] !== undefined) {
      referenceErrors = { ...referenceErrors, [element.reference]: referenceErrors[element.reference] + 1 };
      setQueue([element, ...rest]);
    } else if (referenceErrors[element.reference] === undefined) {
      referenceErrors = { ...referenceErrors, [element.reference]: 1 };
      setQueue([element, ...rest]);
    } else throw new Error("Error in writing Error");
    if (onError) onError(error, element.value, errors);
    throw error;
  };

  const pumpQueue = async () => {
    try {
      if (currentQueue[0]) {
        const [element, ...rest] = currentQueue;
        setQueue([...rest], true);
        queueBlocked = true;
        try {
          await handleQueueEntry(element, rest);
        } catch (error) {
          await handleQueueError(element, rest, error);
        }
        setQueue(currentQueue);
      } else return;
    } catch (error) {
      console.error(error);
    } finally {
      queueBlocked = false;
    }
  };

  const addToQueue = (element: typeof currentQueue[0]) => {
    setQueue([...currentQueue, element]);
    if (onAdd) onAdd(element.value, currentQueue);
  };

  window.addEventListener("online", checkIfOnline);
  window.addEventListener("offline", checkIfOnline);

  checkIfOnline();

  setInterval(checkIfOnline, 5000);

  setInterval(() => {
    if (!queueBlocked && isOnline.is) pumpQueue();
  }, 50);

  return {
    addToQueue,
    Errors: errors,
    CurrentQueue: currentQueue,
    ReferenceErrors: referenceErrors,
    findInQueueByKey,
    findInQueueByReference,
  };
};

export default { Queue };
