import ClientApi, { Savelocation } from "./ClientApi";
import CommandQueue from "./CommandQueue";
import PersistentStorageRepository from "./PersistentStorageRepository";
import QueueProvider, { QueueElement } from "./QueueProvider";

export const _dispatchEvent = <T>(event: string, detail?: T) => {
  if (detail) {
    const eventToDispatch = new CustomEvent<typeof detail>(event, { detail });
    return window.dispatchEvent(eventToDispatch);
  } else {
    const eventToDispatch = new Event(event);
    return window.dispatchEvent(eventToDispatch);
  }
};

const Events = {
  QUERY_QUEUE_UPDATE: "QUERY_QUEUE_UPDATE",
};

export type QueryQueueItem<T extends keyof typeof ClientApi.ServerQueries> = {
  query: T;
  props: Parameters<typeof ClientApi.ServerQueries[T]>;
  reference: Savelocation;
  date?: Date;
};

const QUERY_QUEUE = "QUERY_QUEUE";

const handleQueryQueueElement = async <T extends keyof typeof ClientApi.ServerQueries>(
  element: QueryQueueItem<T>,
  queue: QueueElement<QueryQueueItem<T>>[]
) => {
  try {
    // Prüfe ob in der Response IDs von der Commandqueue vorhanden sind - Ändere diese nicht
    // Aktualisiere den Rest

    if (element.query in ClientApi.ServerQueries) {
      // Führe Query aus
      const resp: any[] = await (ClientApi.ServerQueries[element.query] as any).apply(null, element.props as any);
      const temp: any[] = (await PersistentStorageRepository.get(element.reference, element.date)) || [];
      // Finde CommandIds
      const tempIds = ((await CommandQueue.getCommandTempIds()) || []).map((i) => i.id);
      const queueIds = CommandQueue.getIds().map((i) => i.reference);
      const loadingIds = Array.from(new Set([...tempIds, ...queueIds]));
      // Wenn das Element gerade berarbeitet wird, ersetze es nicht
      const newSave = resp.reduce((save, item) => {
        if (loadingIds.some((i) => i === item.id)) {
          if (temp.some((it) => it.id === item.id)) {
            const match = temp.find((it) => it.id === item.id);
            if (match) {
              return [...save, match];
            } else {
              return [...save, item];
            }
          } else {
            return [...save, item];
          }
        } else {
          return [...save, item];
        }
      }, []);

      await PersistentStorageRepository.write(element.reference, element.date, newSave);
    } else throw new Error(`Query ${element.query} does not exist.`);
  } catch (error) {
    throw error;
  }
};

const persistsCommandQueue = async (queue: QueueElement<QueryQueueItem<any>>[]) => {
  try {
    await PersistentStorageRepository.write(QUERY_QUEUE, undefined, queue);
    _dispatchEvent(Events.QUERY_QUEUE_UPDATE);
    return;
  } catch (error) {
    throw error;
  }
};

const queryQueue = QueueProvider.Queue<QueryQueueItem<any>>({
  handleQueueElement: handleQueryQueueElement,
  onQueueChange: persistsCommandQueue,
  maxFailAmount: 20,
  shouldCheckConnection: true,
});

const findInQueue = (reference: string) => {
  const matches = queryQueue.findInQueueByReference(reference);
  return matches;
};

const hasQueryDate = async (query: string, date?: Date) => {
  try {
    const queue = await getQueue();
    const contains = queue.some(
      (i) => i.value?.query === query && i.value?.date?.toISOString().slice(0, 10) === date?.toISOString().slice(0, 10)
    );
    return contains;
  } catch (error) {
    throw error;
  }
};

const addToQueryQueue = async <T extends keyof typeof ClientApi.ServerQueries>(item: QueryQueueItem<T>) => {
  try {
    // Prüfe ob Query bereits gequeued ist
    // Füge Query gegebenenfalls hinzu
    if (await hasQueryDate(item.query, item.date)) {
      return;
    } else {
      queryQueue.addToQueue({ reference: item.query, value: item });
    }
  } catch (error) {
    throw error;
  }
};

const getQueue = async () => {
  try {
    const queue = await PersistentStorageRepository.get<QueueElement<QueryQueueItem<any>>[]>(QUERY_QUEUE, undefined);
    return queue || [];
  } catch (error) {
    throw error;
  }
};

const initialise = async () => {
  try {
    const oldQueue = await PersistentStorageRepository.get(QUERY_QUEUE);
    if (oldQueue && Array.isArray(oldQueue)) {
      for (const item of oldQueue) {
        queryQueue.addToQueue(item);
      }
    }
  } catch (error) {
    throw error;
  }
};

const QueryQueue = {
  addToQueryQueue,
  initialise,
  getQueue,
  Constants: {
    QUERY_QUEUE,
    Array: [QUERY_QUEUE],
  },
  findInQueue,
  Events,
  hasQueryDate,
};

export default QueryQueue;
