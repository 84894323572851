import React from "react";
import { AppColors } from "../models/General";

export const SubNav: React.FC<{ colors: AppColors }> = props => (
  <div className="flex w-full bg-gray-200 flex-grow-0 flex-shrink-0 h-10 px-2 text-sm">
    {(props.children &&
      (props.children as any).map((element: JSX.Element, idx: number) =>
        React.cloneElement(element, { colors: props.colors, key: idx })
      )) ||
      (props.children && props.children)}
  </div>
);

interface SubNavButtonProps {
  onClick: () => void;
  key?: string | number;
  isActive?: boolean;
  colors?: AppColors;
}

export const SubNavButton: React.FC<SubNavButtonProps> = props => (
  <div
    key={props.key}
    onClick={props.onClick}
    className={`flex flex-grow-0 w-24 h-full min-h-full pl-1 justify-center items-center hover:bg-gray-400 select-none cursor-pointer border-b-2 ${
      props.isActive ? "border-" + (props.colors && props.colors.backgroundcolor) + "-600" : "border-transparent"
    }`}
    style={{ transition: "all 100ms" }}
  >
    {props.children}
  </div>
);
