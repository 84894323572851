import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import SettingsProvider from "./clientApi/SettingsProvider";

const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey:
      SettingsProvider.get("mandant") === "UnitTesting"
        ? "cdae06de-fa47-44e0-8bdd-bc32ac55f984"
        : "5cc8be5d-6e97-4bf9-ae4a-9cc7353453eb",
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();

export { reactPlugin, appInsights };
