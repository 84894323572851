import * as React from "react";
import { AppColors } from "../models/General";
import { InformationBaseClass } from "./InformationDetail";

export const InformationButton: React.FC<{
  appColors: AppColors;
  color?: string;
  additionalClasses?: string;
  spanClass?: string;
  big?: boolean;
  handleClick: () => any;
}> = props => {
  return (
    <div
      className={`flex flex-grow-0 justify-center items-center h-full px-1 ${props.additionalClasses}`}
      onClick={props.handleClick}
    >
      <span
        className={`${props.big ? "p-2 px-3 " + InformationBaseClass : InformationBaseClass} ${
          props.color
            ? `bg-${props.color}`
            : `bg-${props.appColors.backgroundcolor}-500 hover:bg-${props.appColors.backgroundcolor}-400`
        } text-white ${props.spanClass} cursor-pointer`}
        style={{
          transition: "background-color 140ms"
        }}
      >
        {props.children}
      </span>
    </div>
  );
};
