import { BookmarkIcon, MailIcon, PhoneIcon } from "@heroicons/react/solid";
import * as ScrollLock from "body-scroll-lock";
import * as React from "react";
import ClientApi from "../../clientApi/ClientApi";
import { Nachricht } from "../../clientApi/models/Nachricht";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { ReservationButton } from "../../components/ReservationButton";

export const NachrichtenBody = (props: {
  nachricht: Nachricht.Client<any>;
  onReply?: (n: Nachricht.Client<any>) => void;
  onCall?: (n: Nachricht.Client<any>) => void;
  onCreateRes?: (n: Nachricht.Client<any>) => void;
}) => {
  const [currentAudio, setCurrentAudio] = React.useState<Blob | null>(null);
  const [currentAudioBlobUrl, setCurrentAudioBlobUrl] = React.useState<string | null>(null);
  const blobURLRef = React.useRef<string | null>(null);

  const [hasErrorLoadingAudio, setHasErrorLoadingAudio] = React.useState<boolean>(false);

  const [isLoadingAudio, setIsLoadingAudio] = React.useState<boolean | null>(true);

  const [emailBodyRef, setEmailBodyRef] = React.useState<HTMLIFrameElement | null>(null);

  const getAudioToCause = React.useCallback(async (nachricht: Nachricht.Client<any>) => {
    try {
      setHasErrorLoadingAudio(() => {
        setIsLoadingAudio(true);
        return false;
      });
      const blob = await ClientApi.Queries.getCallMessage(nachricht);
      setIsLoadingAudio(() => {
        setCurrentAudio(blob);
        return null;
      });
    } catch (error) {
      setHasErrorLoadingAudio(true);
      throw error;
    } finally {
      setIsLoadingAudio(false);
    }
  }, []);

  const loadAudio = React.useCallback(() => {
    try {
      if (props.nachricht.type === "MissedCallMessage") {
        getAudioToCause(props.nachricht);
      }
    } catch (error) {
      console.error(error);
    }
  }, [getAudioToCause, props.nachricht]);

  const handleOnReply = React.useCallback(() => {
    if (props.onReply) {
      props.onReply.call(undefined, props.nachricht);
    } else {
      window.location.href = `mailto:${props.nachricht.from}?subject=RE:${props.nachricht.subject}`;
    }
  }, [props.nachricht, props.onReply]);

  const handleOnCreateRes = React.useCallback(() => {
    if (props.onCreateRes) {
      props.onCreateRes.call(undefined, props.nachricht);
    }
  }, [props.nachricht, props.onCreateRes]);

  const handleOnCall = React.useCallback(() => {
    if (props.onCall) {
      props.onCall(props.nachricht);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nachricht, props.onCall]);

  React.useEffect(() => {
    loadAudio();
  }, [loadAudio]);

  React.useEffect(() => {
    if (
      currentAudio &&
      (URL || window.URL) &&
      ("createObjectURL" in URL || "createObjectURL" in window.URL) &&
      (typeof URL.createObjectURL === "function" || typeof window.URL.createObjectURL === "function")
    ) {
      console.log("generating");
      const tempURL = URL || window.URL;
      const blobURL = tempURL.createObjectURL(currentAudio);
      console.log(blobURL);
      setCurrentAudioBlobUrl(blobURL);
    }
  }, [currentAudio]);

  React.useEffect(() => {
    if (currentAudioBlobUrl) {
      blobURLRef.current = currentAudioBlobUrl;
      return () => {
        if (
          (URL || window.URL) &&
          ("revokeObjectURL" in URL || "revokeObjectURL" in window.URL) &&
          (typeof URL.createObjectURL === "function" || typeof window.URL.createObjectURL === "function")
        ) {
          console.log("revoking");
          const tempURL = URL || window.URL;
          if (blobURLRef.current || currentAudioBlobUrl) {
            tempURL.revokeObjectURL(blobURLRef.current || currentAudioBlobUrl);
          }
        }
      };
    }
  }, [currentAudioBlobUrl]);

  const [isRendered, setIsRendered] = React.useState<boolean>(false);

  React.useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      setIsRendered(true);
      return () => {
        setIsRendered(false);
      };
    }, 25);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, []);

  React.useLayoutEffect(() => {
    if (isRendered) {
      const timeout = setTimeout(() => {
        if (emailBodyRef) {
          ScrollLock.disableBodyScroll(emailBodyRef);
          return () => {
            ScrollLock.enableBodyScroll(emailBodyRef);
          };
        }
      }, 25);
      return () => {
        if (timeout) clearTimeout(timeout);
      };
    }
  }, [emailBodyRef, isRendered]);

  React.useEffect(() => {
    if (emailBodyRef && props.nachricht && props.nachricht.body) {
      const content = emailBodyRef.contentDocument;
      if (emailBodyRef.contentEditable) {
        if (content) {
          content.open("text/html", "replace");
          content.write(`<html><body>${props.nachricht.body}</body></html>`);
          content.close();
        }
      } else {
        window.alert("Fehler beim laden der Email");
      }
    }
  }, [emailBodyRef, props.nachricht]);

  const audioSource = React.useMemo(
    () =>
      currentAudio && currentAudioBlobUrl ? <source src={currentAudioBlobUrl} type={currentAudio.type}></source> : null,
    [currentAudio, currentAudioBlobUrl]
  );

  const isDisplayingReplyButton = React.useMemo(() => props.nachricht && props.nachricht.from, [props.nachricht]);

  return (
    <div className="flex flex-1 flex-col bg-gray-200 shadow-inner rounded-lg p-1 min-h-0 overflow-hidden">
      <div className="inline-flex flex-col w-full min-h-0 bg-white rounded p-3 py-2 mb-1 shadow">
        <span className="inline-flex w-full justify-between items-base mb-1">
          <span className="text-blue-600 text-sm tracking-wide truncate pr-2">
            {props.nachricht?.from
              ? props.nachricht.from.includes("wix")
                ? props.nachricht.reservation?.name ?? props.nachricht?.from ?? "--"
                : props.nachricht?.from ?? "--"
              : "--"}
          </span>
          <span className="text-sm text-gray-600 flex-shrink-0">
            {props.nachricht?.timestamp
              ? new Date(props.nachricht.timestamp).toLocaleDateString("de-de", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : null}
          </span>
        </span>

        <span className="text-sm text-gray-800 leading-snug">{props.nachricht?.subject ?? "--"}</span>
      </div>
      {props.nachricht.body && isRendered ? (
        <iframe
          ref={setEmailBodyRef}
          className="flex flex-9 w-full h-full rounded-lg shadow-md border bg-white"
          frameBorder="0"
          title="emailbody"
          // srcDoc={selectedCause.body || undefined}
        ></iframe>
      ) : (
        <div className="flex flex-9 justify-center items-center">No Textbody found</div>
      )}

      {props.nachricht.type === "MissedCallMessage" ? (
        <div className="flex flex-2 w-full justify-center items-center p-2">
          {audioSource && !isLoadingAudio ? (
            <audio controls className="w-full">
              {audioSource}
            </audio>
          ) : isLoadingAudio === true ? (
            <LoadingIndicator label={"Lade Audio..."}></LoadingIndicator>
          ) : hasErrorLoadingAudio ? (
            <div className="inline-flex flex-col justify-center items-center content-start gap-2">
              <span className="text-sm text-gray-600">Ein Fehler ist aufgetreten</span>
              <button
                onClick={loadAudio}
                className="text-sm font-semibold tracking-wide text-white bg-blue-500 hover:bg-blue-600 px-4 py-1 rounded shadow"
              >
                Erneut versuchen
              </button>
            </div>
          ) : null}
        </div>
      ) : null}

      <div className="flex flex-col w-full pt-2 pb-2 space-y-2">
        {isDisplayingReplyButton && props.onCall ? (
          <ReservationButton
            noHeight
            color="blue"
            isLocked={false}
            onClickHandler={handleOnCall}
            additionalClasses={"rounded-lg transition-all ease-in-out duration-200 h-10 flex-shrink-0"}
          >
            <PhoneIcon className="w-6 h-6 ml-2" />
            <span className="w-full text-center pr-8">Anrufen</span>
          </ReservationButton>
        ) : null}
        {isDisplayingReplyButton ? (
          <ReservationButton
            noHeight
            color="blue"
            isLocked={false}
            onClickHandler={handleOnReply}
            additionalClasses={"rounded-lg transition-all ease-in-out duration-200 h-10 flex-shrink-0"}
          >
            <MailIcon className="w-6 h-6 ml-2" />
            <span className="w-full text-center pr-8">Antworten</span>
          </ReservationButton>
        ) : null}
        {isDisplayingReplyButton && props.onCreateRes ? (
          <ReservationButton
            noHeight
            color="green"
            isLocked={false}
            onClickHandler={handleOnCreateRes}
            additionalClasses={"rounded-lg transition-all ease-in-out duration-200 h-10 flex-shrink-0"}
          >
            <BookmarkIcon className="w-6 h-6 ml-2" />
            <span className="w-full text-center pr-8">Reservierung erstellen</span>
          </ReservationButton>
        ) : null}
      </div>
    </div>
  );
};
