import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { AppColors } from "../models/General";

export interface AppLinkProps extends Partial<AppColors>, NavLinkProps {}

export const AppLink: React.FunctionComponent<AppLinkProps> = (props) => (
  <NavLink
    activeStyle={{ color: props.textcoloroncolor }}
    style={{ transition: "all 250ms" }}
    activeClassName={`relative bg-${props.backgroundcolor}-600 tracking-widest md:tracking-normal`}
    className={`inline-flex px-4 justify-center items-center no-underline tracking-normal bg-transparent text-xl md:text-xs text-${props.backgroundcolor}-100 md:text-${props.backgroundcolor}-200 hover:text-${props.textcoloroncolor} h-full overflow-hidden`}
    {...props}
  >
    {typeof props.children === "string" ? <span className="truncate">{props.children}</span> : props.children}
  </NavLink>
);
