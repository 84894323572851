import * as React from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useTheme } from "../hooks/useTheme";
import { XIcon } from "@heroicons/react/solid";

export const WhiteBoxInput = (props: {
  onChange: (value: string) => void;
  inputValue: string | null;
  label: string;
  size?: "default" | "full" | "loose";
  labelAlwaysVisible?: boolean;
  onRemove?: (reset: null) => void;
  className?: string;
  onRef?: (input: HTMLInputElement | HTMLTextAreaElement | null) => void;
  onEnter?: (value: string) => void;
  hasError?: boolean;
  width?: "flex" | "full";
}) => {
  const { theme } = useTheme();
  const [value, setValue] = React.useState<string | null>(props.inputValue);
  const [ref, setRef] = React.useState<HTMLInputElement | HTMLTextAreaElement | null>(null);
  const [inputFocued, setInputFocused] = React.useState(false);

  const inputValue = React.useMemo(() => value || "", [value]);

  const isValueFilled = React.useMemo(() => value !== null && value !== "", [value]);

  const isFocused = React.useMemo(
    () => props.labelAlwaysVisible || isValueFilled || inputFocued,
    [inputFocued, isValueFilled, props.labelAlwaysVisible]
  );
  const isDisplayingRemove = React.useMemo(() => props.onRemove && isValueFilled, [isValueFilled, props.onRemove]);

  const isTextArea = React.useMemo(() => props.size === "full" || props.size === "loose", [props.size]);

  const handleOnRemove = React.useCallback(() => {
    if (props.onRemove) {
      props.onRemove.call(undefined, null);
    }
  }, [props.onRemove]);

  const handleOnChange = React.useCallback(
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if ("onChange" in props && ev && "target" in ev) {
        const val = ev.target.value;
        props.onChange.call(undefined, val);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChange]
  );

  const handleKeyPress = React.useCallback(
    (ev: React.KeyboardEvent) => {
      if (props.onEnter && ev && "key" in ev && isValueFilled) {
        if (ev.key === "Enter") {
          props.onEnter.call(undefined, value!);
        }
      }
    },
    [isValueFilled, props.onEnter, value]
  );

  const handleFocus = React.useCallback(() => {
    setInputFocused(true);
  }, []);

  const handleBlur = React.useCallback(() => {
    setInputFocused(false);
  }, []);

  React.useEffect(() => {
    if (ref) {
      disableBodyScroll(ref);
      return () => {
        enableBodyScroll(ref);
      };
    }
  }, [ref]);

  React.useEffect(() => {
    if (props.onRef) {
      props.onRef.call(undefined, ref);
    }
  }, [props.onRef, ref]);

  React.useEffect(() => {
    setValue(props.inputValue);
  }, [props.inputValue]);

  return React.useMemo(
    () => (
      <div
        className={`relative ${props.width === "flex" ? "flex-1" : "w-full"} bg-white ${
          props.size === "full" ? "h-full" : props.size === "loose" ? "" : "h-12"
        } hover:shadow-md ${isFocused ? `shadow-md` : `shadow`} ${
          props.hasError ? "border-2 border-red-500" : ""
        } rounded ${props.className} transition-all duration-300 ease-in-out`}
      >
        <span
          onClick={handleOnRemove}
          className={`flex ${
            isTextArea ? "h-6 mt-3 justify-start items-start " : "h-full justify-center items-center"
          } w-6 absolute top-0 right-0 mr-2 z-10 transition-all duration-150 ease-in-out ${
            isDisplayingRemove ? `opacity-100` : "opacity-0 pointer-events-none"
          }`}
        >
          <XIcon
            className={`flex h-6 w-6 transition-all duration-150 delay-75 rounded-full p-1 border-1 ${
              isDisplayingRemove
                ? `border-${theme.backgroundcolor}-300 text-${theme.backgroundcolor}-400 bg-${theme.backgroundcolor}-100 hover:bg-opacity-90`
                : `border-${theme.backgroundcolor}-100 text-${theme.backgroundcolor}-200 bg-white`
            } `}
          />
        </span>

        {isTextArea ? (
          <textarea
            ref={setRef}
            id={props.label}
            value={inputValue}
            placeholder={isFocused ? "" : props.label}
            onChange={handleOnChange}
            onKeyPress={handleKeyPress}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={`relative w-full h-full border-none rounded text-base bg-transparent outline-none shadow-none ${
              isFocused
                ? `px-2 pt-5 text-${theme.backgroundcolor}-900`
                : `px-2 pt-4 text-${theme.backgroundcolor}-900 overflow-auto`
            } transition-all duration-200 ease-in-out`}
          />
        ) : (
          <input
            ref={setRef}
            id={props.label}
            type="text"
            value={inputValue}
            placeholder={isFocused ? "" : props.label}
            onChange={handleOnChange}
            onKeyPress={handleKeyPress}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={`relative w-full h-full border-none rounded text-base bg-transparent outline-none shadow-none ${
              isFocused ? `px-2 pt-3 text-${theme.backgroundcolor}-900` : `px-2 text-${theme.backgroundcolor}-900`
            } transition-all duration-200 ease-in-out`}
          />
        )}

        <label
          htmlFor={props.label}
          className={`absolute top-1 left-0 text-xs ${
            isFocused
              ? `translate-y-px opacity-100 text-${props.hasError ? "red" : theme.backgroundcolor}-500`
              : "translate-y-1/2 text-gray-400 opacity-0"
          } transition-all transform duration-150 ease-in-out ml-2`}
        >
          {props.label}
        </label>
      </div>
    ),
    [
      props.width,
      props.size,
      props.hasError,
      props.className,
      props.label,
      isFocused,
      handleOnRemove,
      isTextArea,
      isDisplayingRemove,
      theme.backgroundcolor,
      inputValue,
      handleOnChange,
      handleKeyPress,
      handleFocus,
      handleBlur,
    ]
  );
};
