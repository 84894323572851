import * as React from "react";
import { AppFunctions, AppState } from "../App";
import { useSettings } from "../clientApi/SettingsProvider";
import { ReservierungViewModelTypes } from "./Reservierung/ReservierungViewTypes";

type TestProps = {} & AppState & AppFunctions;

export const Test = (props: ReservierungViewModelTypes.Props) => {
  const Settings = useSettings();

  const [isAtEnd, setIsAtEnd] = React.useState<boolean>(false);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setIsAtEnd((c) => !c);
    }, 5000);
    return () => {
      if (interval) clearInterval(interval);
    };
  });

  return (
    <div className="inline-flex flex-col w-full h-full min-h-0 justify-center items-center content-center">
      <div className="isolate relative inline-flex w-64 h-16 min-h-0 bg-gray-900 overflow-hidden">
        <div className="absolute inset-0 inline-flex w-96 mx-auto h-32 my-auto min-h-0 z-10">
          <div className="inline-flex w-16 transform backdrop-filter backdrop-blur-7 scale-150"></div>
          <div className="inline-flex w-16 transform backdrop-filter backdrop-blur-7 scale-150"></div>
          <div className="inline-flex w-16 transform backdrop-filter backdrop-blur-7 scale-150"></div>
          <div className="inline-flex w-16 transform backdrop-filter backdrop-blur-7 scale-150"></div>
        </div>
        <div className="inline-flex w-full h-full min-h-0">
          <div
            className={`inline-flex w-4 h-4 bg-white my-auto transform origin-center transition-transform ease-in-out -ml-2 ${
              isAtEnd ? "translate-x-64" : "translate-x-0"
            }`}
            style={{
              transitionDuration: "5000ms",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};
