import { AuthError, InteractionType } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import AuthConfig from "../../../AuthConfig";
import { useSavedAccount } from "../../../hooks/useSavedAccount";
import { LoginViewTypes } from "./LoginViewTypes";

const AuthRequest = {
  ...AuthConfig.loginRequest,
};

export const useLoginViewModel = (props: LoginViewTypes.Props) => {
  const { replace } = useHistory();
  const { instance } = useMsal();
  const [account, setAccount] = useSavedAccount();

  const { error, result } = useMsalAuthentication(InteractionType.Redirect, {
    ...AuthRequest,
    account: account || undefined,
  });

  const navigateToForgotPassword = useCallback(() => {
    replace("/resetPassword");
  }, [replace]);

  const handlePasswordReset = useCallback(
    (error: AuthError) => {
      if (
        error.message.includes("AADB2C90118") ||
        error.errorCode.includes("AADB2C90118") ||
        error.errorMessage.includes("AADB2C90118")
      ) {
        navigateToForgotPassword();
        return true;
      } else {
        try {
          const json = JSON.stringify(error);
          if (json.toLocaleLowerCase().includes("password") && json.toLocaleLowerCase().includes("forgotten")) {
            navigateToForgotPassword();
            return true;
          } else {
            throw new Error(`${error}`);
          }
        } catch (error) {
          return false;
        }
      }
    },
    [navigateToForgotPassword]
  );

  const handleUserCancelled = useCallback(
    (error: AuthError) => {
      if (error.name.includes("user_cancelled") || error.errorCode.includes("user_cancelled")) {
        replace("/cancelledLogin");
        return true;
      } else return false;
    },
    [replace]
  );

  useEffect(() => {
    if (result) {
      setAccount(result.account);
      instance.setActiveAccount(result.account);
      replace("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance.setActiveAccount, result]);

  useEffect(() => {
    if (error) {
      let redirect = handlePasswordReset(error);
      if (!redirect) redirect = handleUserCancelled(error);
      if (!redirect) window.alert(error);
    }
  }, [error, handlePasswordReset, handleUserCancelled]);

  useEffect(() => {
    const to = setTimeout(() => {
      instance.logout({
        postLogoutRedirectUri: `${window.location.origin}/login`,
      });
    }, 5000);
    return () => {
      if (to) clearTimeout(to);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance.logout]);

  return {};
};
