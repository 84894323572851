import * as IDB from "idb-keyval";
import * as uuid from "uuid";
import QueueProvider, { QueueElement } from "./QueueProvider";
import SettingsProvider, { AppSettings } from "./SettingsProvider";

const findEventForElement = async (id: string) => {
  try {
    return EventCache.Events.find((e) => e.reference.id === id);
  } catch (error) {
    throw error;
  }
};

const findElementForEvent = async <T, B extends keyof T>(id: string, repo: T, get: B) => {
  const _get: (...p: any) => Promise<any> = repo[get] as any;
  try {
    const match = EventCache.Events.find((e) => e.id === id);
    if (match) {
      console.log(match);
      const {
        reference: { id, storage, date, location },
      } = match;
      let storedMatchLocation = undefined;
      if (!date) {
        if (location) {
          storedMatchLocation = await _get(location);
        } else {
          storedMatchLocation = await _get(`${SettingsProvider.get("mandant")}_${"default"}_${storage}`);
        }
      } else {
        if (location) {
          storedMatchLocation = await _get(location);
        } else {
          storedMatchLocation = await _get(
            `${SettingsProvider.get("mandant")}_${date ? date.toISOString().slice(0, 10) : "default"}_${storage}`
          );
        }
      }
      if (storedMatchLocation) {
        if (Array.isArray(storedMatchLocation) && !id.includes(storage)) {
          return storedMatchLocation.find((e) => e.id === id);
        } else {
          return storedMatchLocation;
        }
      }
    } else return null;
  } catch (error) {
    throw error;
  }
};

export const _dispatchEvent = <T>(event: string, detail?: T) => {
  if (detail) {
    const eventToDispatch = new CustomEvent<typeof detail>(event, { detail });
    return window.dispatchEvent(eventToDispatch);
  } else {
    const eventToDispatch = new Event(event);
    return window.dispatchEvent(eventToDispatch);
  }
};

const EVENT_STORE = "EVENT_STORE";
const EVENT_DB = "EVENT_DB";
const EVENT_KEY = "EVENTS";
const EVENT_QUEUE = "EVENT_QUEUE";

const CLIENT_VERSION = "CLIENT_VERSION";
const SERVER_VERSION = "SERVER_VERSION";

const Events = {
  EVENTS_INITIALIZED: "EVENTS_INITIALIZED",
  EVENTS_UPDATED: "EVENTS_UPDATED",
  EVENTS_VERSION: "EVENTS_VERSION",
};

// Change ServerVersion on new Data from Server

let CachedEventQueue: QueueElement<ClientEvent>[] = [];

const _saveCachedQueue = (queue: typeof CachedEventQueue, date: Date) => {
  _set(getSavedQueue(date), queue);
};

const EventQueue = QueueProvider.Queue<ClientEvent>({
  handleQueueElement: async (ev, que, errors) => {
    _addToEventStore(ev, SettingsProvider.get("date"));
    CachedEventQueue = que;
    _saveCachedQueue(CachedEventQueue, SettingsProvider.get("date"));
  },
  initialQueue: CachedEventQueue,
  onAdd: (el, que) => {
    CachedEventQueue = que;
    _saveCachedQueue(CachedEventQueue, SettingsProvider.get("date"));
  },
  onError: (error, el, err) => {
    console.error(error);
  },
  maxFailAmount: 20,
  shouldCheckConnection: false,
});

let EventCache = {
  ClientVersion: 0,
  ServerVersion: 0,
  Events: [] as ClientEvent[],
};

// Zählung, ClientVersion, Verweis auf Element, Server ID, ServerVersion, ServerDatum?
export type ClientEvent = {
  id: string;
  type: string;
  clientVersion: number;
  reference: { storage: string; date?: Date; id: string; location: string | null };
  serverId: string | null;
  serverVersion: string | number | null;
  serverDate: Date | null;
};

const _get = async <T>(location: string, shouldBe?: T) => {
  try {
    const value = await IDB.get<T | undefined | null>(location, eventStore);
    if (shouldBe !== undefined) {
      if (value === shouldBe) {
        return value;
      } else {
        return undefined;
      }
    }
    return value;
  } catch (error) {
    throw error;
  }
};

const _set = async (location: string, value: any) => {
  try {
    return await IDB.set(location, value, eventStore);
  } catch (error) {
    throw error;
  }
};

let eventStore: IDB.UseStore | undefined = undefined;

const _getLocation = (date: Date, str: string) =>
  `${SettingsProvider.get("mandant")}_${date.toISOString().slice(0, 10)}_${str}`;
const getStoreLocation = (date: Date) => _getLocation(date, EVENT_KEY);
const getClientVersionLocation = (date: Date) => _getLocation(date, CLIENT_VERSION);
const getServerVersionLocation = (date: Date) => _getLocation(date, SERVER_VERSION);
const getSavedQueue = (date: Date) => _getLocation(date, EVENT_QUEUE);

const changeServerVersion = async (date: Date, value: number) => {
  try {
    await _set(getServerVersionLocation(date), value);
    _dispatchEvent(Events.EVENTS_VERSION, "SERVER");
    return value;
  } catch (error) {
    throw error;
  }
};

const _changeClientVersion = async (date: Date, change: "increment" | "decrement" | "custom", customValue?: string) => {
  try {
    const currentClientVersion = await _get<number>(getClientVersionLocation(date));
    let newVersion = 0;
    if (currentClientVersion) {
      EventCache = { ...EventCache, ClientVersion: currentClientVersion };
      const [isIncrement, isDecrement, isCustom] = [
        change === "increment",
        change === "decrement",
        change === "custom",
      ];
      if (isIncrement) {
        newVersion = EventCache.ClientVersion + 1;
        await _set(getClientVersionLocation(date), newVersion);
      } else if (isDecrement) {
        newVersion = EventCache.ClientVersion - 1;
        await _set(getClientVersionLocation(date), newVersion);
      } else if (isCustom) {
        if (customValue !== undefined) {
          const change = +customValue;
          if (isNaN(change)) {
            throw new Error(`Custom Change ${change} is NaN`);
          } else {
            newVersion = EventCache.ClientVersion + change;
            await _set(getClientVersionLocation(date), newVersion);
          }
        } else {
          throw new Error("Custom Changevalue was not defined while changing Client Version");
        }
      } else throw new Error(`Change direction ${change} does not exist`);
      EventCache = { ...EventCache, ClientVersion: newVersion };
      _dispatchEvent(Events.EVENTS_VERSION, "CLIENT");
      return newVersion;
    } else {
      await _set(getClientVersionLocation(date), 1);
      return 1;
    }
  } catch (error) {
    throw error;
  }
};

const _decrementClientVersion = async (date: Date) => {
  try {
    return await _changeClientVersion(date, "decrement");
  } catch (error) {
    throw error;
  }
};

const _incrementClientVersion = async (date: Date) => {
  try {
    return await _changeClientVersion(date, "increment");
  } catch (error) {
    throw error;
  }
};

const _addToEventStore = async (event: ClientEvent, date: Date) => {
  try {
    if (eventStore) {
      const version = await _incrementClientVersion(date);
      let tempEvent: ClientEvent = { ...event, clientVersion: version };
      const currentEvents = await _get<ClientEvent[]>(getStoreLocation(date));
      if (currentEvents) {
        EventCache = { ...EventCache, Events: [tempEvent, ...currentEvents] };
        await _set(getStoreLocation(date), [tempEvent, ...currentEvents]);
        _dispatchEvent(Events.EVENTS_UPDATED);
      }
      return true;
    } else {
      await _decrementClientVersion(date);
      throw new Error("Eventstore was not initialized");
    }
  } catch (error) {
    throw error;
  }
};

const _modifyInEventStore = async (
  date: Date,
  changes: Pick<Partial<ClientEvent>, "serverDate" | "serverId" | "serverVersion">,
  id: string
) => {
  try {
    const currentEvents = await _get<ClientEvent[]>(getStoreLocation(date));
    if (currentEvents) {
      let tempEvents = [...currentEvents];
      let tempEvent: ClientEvent | undefined = tempEvents.find((e) => e.id === id);
      tempEvents = tempEvents.map((e) => {
        if (e.id === id) {
          return { ...e, ...changes };
        } else return e;
      });
      if (tempEvent) {
        await _set(getStoreLocation(date), tempEvents);
        EventCache = { ...EventCache, Events: [...tempEvents] };
        return tempEvent;
      } else throw new Error(`No event with id ${id} found`);
    } else throw new Error(`No events exist on date ${date.toISOString().slice(0, 10)}`);
  } catch (error) {
    throw error;
  }
};

const createEventStore = async () => {
  try {
    eventStore = IDB.createStore(EVENT_DB, EVENT_STORE);
    return eventStore;
  } catch (error) {
    throw error;
  }
};

const createEvent = (type: string, reference: ClientEvent["reference"]) => {
  const tempEvent: ClientEvent = {
    clientVersion: 0,
    reference: { ...reference, location: mapLocation(reference.storage, reference.date) },
    serverDate: null,
    serverId: null,
    serverVersion: null,
    type,
    id: uuid.v4(),
  };
  return tempEvent;
};

const changeEventStore = async (date: Date) => {
  try {
    let cachedEvents = await _get<ClientEvent[]>(getStoreLocation(date));
    let serverVersion = await _get<number>(getServerVersionLocation(date));
    let clientVersion = await _get<number>(getClientVersionLocation(date));
    if (clientVersion === undefined || clientVersion === null) {
      await _set(getClientVersionLocation(date), 0);
      clientVersion = 0;
    }
    if (serverVersion === undefined || serverVersion === null) {
      await _set(getServerVersionLocation(date), 0);
      serverVersion = 0;
    }
    if (cachedEvents) {
      EventCache = {
        ...EventCache,
        Events: cachedEvents,
        ClientVersion: clientVersion,
        ServerVersion: serverVersion,
      };
      return EventCache;
    } else {
      await _set(getStoreLocation(date), []);
      EventCache = { ...EventCache, Events: [] };
      return EventCache;
    }
  } catch (error) {
    throw error;
  } finally {
    _dispatchEvent(Events.EVENTS_VERSION, "CLIENT");
    _dispatchEvent(Events.EVENTS_VERSION, "SERVER");
    _dispatchEvent(Events.EVENTS_UPDATED);
  }
};

const _intialize = async (date: Date) => {
  try {
    await createEventStore();
    if (eventStore) {
      await changeEventStore(date);
      _dispatchEvent(Events.EVENTS_INITIALIZED);
      window.addEventListener(SettingsProvider.Events.SETTINGS_UPDATED, ((ev: CustomEvent<keyof AppSettings>) => {
        const { detail } = ev;
        const upperDetail = detail.toLocaleUpperCase();
        if (upperDetail === "DATE" || upperDetail === "MANDANT") {
          changeEventStore(SettingsProvider.get("date"));
        }
      }) as any);
    } else throw new Error("Error creating EventStore");
  } catch (error) {
    throw error;
  }
};

const add = async (type: string, reference: ClientEvent["reference"]) => {
  try {
    if (EventQueue) {
      const event = createEvent(type, reference);
      EventQueue.addToQueue({
        reference: type,
        value: event,
      });
      return event.id;
    } else throw new Error("EventQueue does not exist");
  } catch (error) {
    throw error;
  }
};

const modify = async (
  date: Date,
  id: string,
  changes: Pick<Partial<ClientEvent>, "serverDate" | "serverId" | "serverVersion">
) => {
  try {
    const element = await _modifyInEventStore(date, changes, id);
    if (changes.serverVersion) await changeServerVersion(date, +changes.serverVersion);
    _dispatchEvent(Events.EVENTS_UPDATED);
    return element;
  } catch (error) {
    throw error;
  }
};

const mapLocation = (key: string, date?: Date) => {
  const identifier = date ? date.toISOString().slice(0, 10) : "default";
  return `${SettingsProvider.get("mandant")}_${identifier}_${key}`;
};

const get = <T extends keyof typeof EventCache>(key: T) => {
  return EventCache[key];
};

(() => {
  _intialize(SettingsProvider.get("date"));
})();

export default { Events, add, get, modify, findElementForEvent, findEventForElement, eventStore };
