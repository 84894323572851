import * as React from "react";
import { useTheme } from "../hooks/useTheme";
import { AppColors } from "../models/General";
import { SummaryTableTime } from "../models/Summary";
import { Heroicons } from "./Heroicon/Heroicon";
import { TimeDiagram } from "./TimeDiagram";

export const DateTimeModule = (props: {
  appColors: AppColors;
  dateOfDay: string | number;
  summaryTableTime: SummaryTableTime | null | undefined;
  onClick: (date: string) => void;
  isSelected: boolean;
  reference?: (ref: HTMLDivElement | null) => void;
  event?: string;
}) => {
  const { theme } = useTheme();
  const currentDate = React.useMemo(
    () =>
      new Date(props.dateOfDay).toLocaleString("de-de", {
        month: "2-digit",
        day: "2-digit",
        weekday: "short",
      }),
    [props.dateOfDay]
  );

  const _handleClick = React.useCallback(() => {
    return props.onClick.call(undefined, props.dateOfDay + "");
  }, [props.dateOfDay, props.onClick]);

  const [day, date] = React.useMemo(
    () => (currentDate.includes(",") ? currentDate.split(",") : currentDate.split(" ")),
    [currentDate]
  );

  const diagram = React.useMemo(
    () =>
      props.summaryTableTime ? (
        <TimeDiagram
          Times={props.summaryTableTime}
          appColors={theme}
          isSelected={props.isSelected}
          date={date}
        ></TimeDiagram>
      ) : null,
    [date, props.isSelected, props.summaryTableTime, theme]
  );

  const _handleRef = React.useCallback(
    (ref: HTMLDivElement | null) => {
      if (props.reference) props.reference.call(undefined, ref);
    },
    [props.reference]
  );

  const totalReservations = React.useMemo(
    () => props.summaryTableTime?.reservationTotal || 0,
    [props.summaryTableTime]
  );
  const totalGuests = React.useMemo(() => props.summaryTableTime?.guestTotal || 0, [props.summaryTableTime]);

  if (currentDate) {
    return (
      <div
        ref={_handleRef}
        className={`inline-flex flex-1 flex-col w-full h-full bg-white cursor-pointer group`}
        onClick={_handleClick}
      >
        <div
          className={`relative inline-flex flex-shrink-0 w-full justify-start items-center px-2 py-1 cursor-pointer ${
            props.isSelected
              ? `bg-${theme.backgroundcolor}-400 group-hover:bg-${theme.backgroundcolor}-500`
              : `bg-white group-hover:bg-${theme.backgroundcolor}-100`
          } transition-colors duration-200 ease-in-out`}
        >
          <div className="absolute top-0 left-0 z-0 pt-1">
            <span
              className={`inline bg-gradient-to-r ${
                props.isSelected
                  ? `from-gray-100 to-gray-50`
                  : `from-${theme.backgroundcolor}-600 to-${theme.backgroundcolor}-400`
              } bg-clip-text text-transparent font-semibold tabular-nums text-sm xxs:text-xl mx-2 space-x-2`}
            >
              <span>{date?.trim()}</span>
              <small className="font-black">{day}</small>
            </span>
          </div>
          <div
            className={`inline-flex justify-end items-center ${
              props.isSelected ? `text-${theme.backgroundcolor}-100` : `text-${theme.backgroundcolor}-700`
            } text-xs tabular-nums w-full pt-1 xxs:pt-2`}
          >
            <Heroicons.Outline.BookmarkIcon className="flex flex-grow-0 flex-shrink-0 w-3 h-3" />
            <span className="pl-1">{totalReservations}</span>
            <Heroicons.Outline.UsersIcon className="flex flex-grow-0 flex-shrink-0 w-3 h-3 ml-2" />
            <span className="pl-1">{totalGuests}</span>
          </div>
        </div>
        <div
          className={`inline-flex w-full h-5 flex-shrink-0 items-center px-2 tracking-wide text-xs font-semibold ${
            props.event
              ? `bg-${theme.backgroundcolor}-50 text-${theme.backgroundcolor}-600`
              : "bg-gray-50 text-gray-300"
          }`}
        >
          <Heroicons.Outline.CalendarIcon className="w-3 h-3 mr-1" />
          <span>{props.event ?? "Kein Event"}</span>
        </div>
        {diagram}

        <div className="flex flex-1 flex-row justify-between items-center"></div>
      </div>
    );
  } else return null;
};
