import * as React from "react";
import { GastroEvent, getIconForEvent, EventTypesLocalization } from "../models/GastroEvent";
import { AppColors } from "../models/General";
import { CloseArrow } from "./CloseArrow";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

type EventTimeLineprops = {
  events: GastroEvent[];
  appColors: AppColors;
  transition: React.CSSProperties;
  handleScrollTo: (idx: number) => void;
};

const [height, width] = ["90%", "90%"];
const iconForEvent = getIconForEvent({ height, width });

export const EventTimeLine = ({ events, appColors, transition, handleScrollTo: handleScroll }: EventTimeLineprops) => {
  const [scrollRef, setScrollRef] = React.useState<HTMLDivElement | null>(null);

  React.useLayoutEffect(() => {
    if (scrollRef) {
      disableBodyScroll(scrollRef, { allowTouchMove: (el) => el.id === "event" });
      return () => {
        enableBodyScroll(scrollRef);
      };
    }
  }, [scrollRef]);

  return (
    <div
      ref={(ref) => (ref !== null ? setScrollRef(ref) : null)}
      className="flex flex-grow-0 flex-shrink-0 h-full min-h-0 w-full min-w-0 overflow-x-auto pl-2 z-10"
      id="event"
    >
      {events
        ? events.map((event, idx) => {
            const [date, time] = new Date(event.timestamp)
              .toLocaleString("de-de", {
                month: "numeric",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })
              .split(",");
            return (
              <div
                className={`flex flex-grow-0 flex-shrink-0 justify-center items-center min-h-0 min-w-0 h-full px-1`}
                key={event.id}
                id="event"
              >
                {idx > 0 ? (
                  <div className="flex flex-shrink-0 flex-grow-0 h-full w-10 pb-10">
                    <CloseArrow
                      color={`${appColors.backgroundcolor}-100`}
                      appColors={appColors}
                      close={() => null}
                    ></CloseArrow>
                  </div>
                ) : null}
                <div
                  className="flex flex-col justify-center items-center flex-1 h-full"
                  onClick={() => handleScroll(event.version)}
                >
                  <div className={`flex flex-1 justify-center items-center`}>
                    <div
                      className={`flex flex-grow-0 w-8 h-8 bg-${appColors.backgroundcolor}-500 text-white rounded-lg shadow-md justify-center items-center overflow-hidden p-1 cursor-pointer`}
                    >
                      {iconForEvent[event.event]}
                    </div>
                  </div>
                  <div
                    className={`inline-flex flex-1 flex-col w-full max-w-full min-h-0 max-h-full justify-center items-center text-white`}
                  >
                    <span className="text-xs leading-tight truncate font-semibold" title={event.createdBy}>
                      {EventTypesLocalization[event.event]}
                    </span>
                    {/* <span className="text-xxs leading-tight truncate" title={event.createdBy}>
                      {event.createdBy}
                    </span> */}
                    <span className="text-xs leading-tight">
                      {date} - {time}
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        : null}
      <div className="flex-shrink-0 flex-grow-0 w-2 h-full"></div>
    </div>
  );
};
