import * as React from "react";
import { TailwindBackgroundColors } from "../models/General";

export const ReservationButton: React.FC<{
  isLocked: boolean;
  transition?: React.CSSProperties;
  onClickHandler: () => any | Promise<any>;
  color: TailwindBackgroundColors;
  unlockedHeight?: boolean;
  noHeight?: boolean;
  additionalClasses?: string;
}> = React.memo(function ReservationButton({
  isLocked,
  transition,
  onClickHandler,
  children,
  color,
  unlockedHeight,
  noHeight,
  ...props
}) {
  const textSize = "text-sm md:text-base";
  return (
    <div
      className={`flex justify-between items-center ${
        noHeight ? "" : unlockedHeight ? "h-full" : "flex-grow-0 h-10 md:h-21"
      } w-full`}
    >
      {isLocked ? (
        <div
          className={`flex flex-1 ${
            unlockedHeight ? "h-full" : "h-10 md:h-12"
          } justify-center items-center border-gray-500 bg-white border-4 text-gray-500 ${textSize} ${
            props.additionalClasses
          } ${transition ? "" : "transition-all duration-200 ease-in-out"}`}
          style={transition}
          onClick={() => {}}
        >
          Lädt...
        </div>
      ) : (
        <div
          tabIndex={0}
          className={`flex flex-1 ${
            unlockedHeight ? "h-full" : "h-10 md:h-12"
          } justify-center items-center bg-${color}-500 text-white hover:bg-${color}-600 focus:underline cursor-pointer hover:shadow ${textSize} ${
            props.additionalClasses
          } ${transition ? "" : "transition-all duration-200 ease-in-out"}`}
          style={transition}
          onClick={async () => {
            if (!isLocked) {
              await onClickHandler();
            }
          }}
          onKeyUp={async (ev) => {
            if (ev.key === "Enter") {
              if (!isLocked) {
                await onClickHandler();
              }
            }
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
});
