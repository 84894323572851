import * as uuid from "uuid";
import { ApiCall } from "../models/Controller";
import * as Queue from "../models/Queue";
import { NewReservation, Reservation, walkedInConfirmation } from "../models/Reservation";
import { SummaryTable } from "../models/Summary";
import { Table } from "../models/Table";
import { Email } from "../models/Email";
import { commandProps } from "./Commands";

export const getAllReservationsForDate = async (restaurantId: string, date: string) => {
  try {
    const handledResponse = await ApiCall<{ reservations: Reservation[] }>(
      `Reservations?restaurantId=${restaurantId}&date=${date}`,
      "GET",
      undefined,
      (response: Reservation[]) => {
        return { reservations: response || [] };
      }
    );
    return handledResponse;
  } catch (error) {
    throw error;
  }
};

export const getReservationCall = async (restaurantId: string, reservationId: Reservation["id"]) => {
  try {
    const handledResponse = await ApiCall<Reservation>(
      `Reservations/${reservationId}?restaurantId=${restaurantId}`,
      "GET"
    );
    return handledResponse;
  } catch (error) {
    throw error;
  }
};

const getAllSavedReservationLocations = (restaurantId: string) => {
  try {
    if (window && window.localStorage) {
      const allLocations = Object.keys(localStorage).filter((key) =>
        key.includes(`${restaurantId}_getReservationsForDate_`)
      );
      return [...allLocations].sort().reverse();
    } else throw new Error("Window not loaded.");
  } catch (error) {
    throw new Error(error);
  }
};

const findInAllReservationLocations = (restaurantId: string, searchString: string) => {
  try {
    const locations = getAllSavedReservationLocations(restaurantId);
    const savedReservationsJSON = locations.map((key) => window.localStorage.getItem(key));
    const matchingJSON = savedReservationsJSON.filter((json) => json !== null && json.includes(searchString));
    if (matchingJSON.length > 0) {
      return matchingJSON.map((json) => (json ? JSON.parse(json) : [])) as Reservation[][];
    } else return [];
  } catch (error) {
    throw new Error(error);
  }
};

export const getReservationFromStorage = (restaurantId: string, reservationId: Reservation["id"]) => {
  let possibleMatchLocations = findInAllReservationLocations(restaurantId, reservationId + "");
  possibleMatchLocations = possibleMatchLocations.filter((res) => res.some((ele) => ele.id === reservationId));
  if (possibleMatchLocations.length > 0) {
    const possibleMatch = possibleMatchLocations[0].find((res) => res.id === reservationId);
    return possibleMatch;
  } else return null;
};

export const getReservation = (
  restaurantId: string,
  reservationId: Reservation["id"],
  callBack: (res: Reservation, isFresh: boolean) => any,
  getCached?: boolean
) => {
  const getRes = async () => {
    try {
      const resp = await getReservationCall(restaurantId, reservationId);
      if (resp && resp.id) {
        const reservationsOfDayLocation = getReservationsStorageLocation({
          restaurantId,
          date: resp.dateOfArrival.slice(0, 10),
        });
        try {
          if (window && window.localStorage && window.localStorage.getItem) {
            const reservations = window.localStorage.getItem(reservationsOfDayLocation);
            if (reservations) {
              const parsed: Reservation[] = JSON.parse(reservations);
              if (parsed.length > 0 && parsed.some((ele) => ele.id === reservationId)) {
                const updated = parsed.map((res) => (res.id === reservationId ? { ...res, ...resp } : res));
                window.localStorage.setItem(reservationsOfDayLocation, JSON.stringify(updated));
              } else {
                window.localStorage.setItem(reservationsOfDayLocation, JSON.stringify([...parsed, resp]));
              }
            } else {
              window.localStorage.setItem(reservationsOfDayLocation, JSON.stringify([resp]));
            }
          }
        } catch (error) {
          return callBack(resp, true);
        }
        return callBack(resp, true);
      }
    } catch (error) {
      if (error + "" === "404") {
        console.log(error);
      } else {
        let number = 0;
        if (window && window.sessionStorage) {
          const location = `${restaurantId}_${reservationId}_tries`;
          const storage = window.sessionStorage.getItem(location);
          if (storage) {
            number = +storage + 1;
          } else {
            number = number + 1;
          }
          window.sessionStorage.setItem(location, JSON.stringify(number));
        }
        setTimeout(() => {
          if (number <= 50) {
            getRes();
          }
        }, 500 + 100 * number);
      }
    }
  };

  const possibleMatch = getReservationFromStorage(restaurantId, reservationId);
  if (callBack !== undefined && possibleMatch !== null && possibleMatch !== undefined) {
    callBack(possibleMatch, false);
  }
  if (!getCached) {
    getRes();
  }
};

export const getReservationSummaryForMonth = async (restaurantId: string, year: number, month: number) => {
  try {
    const handledResponse = await ApiCall<{ reservationSummary: SummaryTable }>(
      `reservations/GetReservationSummaryForMonth?restaurantId=${restaurantId}&year=${year}&month=${month}`,
      "GET",
      undefined,
      (response: SummaryTable) => {
        return { reservationSummary: response || {} };
      }
    );
    return handledResponse;
  } catch (error) {
    throw error;
  }
};

type putNewReservationCall = commandProps<
  { reservation: NewReservation; email?: Email },
  { shouldConfirm: boolean | undefined; createEmail: boolean | undefined }
>;

export const putNewReservationCall = async (props: putNewReservationCall) =>
  await ApiCall<Reservation>(
    `reservations?restaurantId=${props.restaurantId}${props.shouldConfirm ? "&confirm=true" : ""}${
      props.createEmail ? "&createEmail=true" : ""
    }`,
    "PUT",
    JSON.stringify(props.body)
  );

export const putNewReservation = async (
  restaurantId: string,
  newReservation: NewReservation,
  shouldConfirm?: boolean,
  email?: Email,
  createEmail?: boolean
) => {
  // const newGuid = uuid.v4();
  // Queue.addToQueue<putNewReservationCall>({
  //   guid: newGuid,
  //   command: "putNewReservation",
  //   data: { restaurantId: restaurantId, body: newReservation }
  // });
  // return { ...newReservation, id: newGuid } as Reservation;
  return await putNewReservationCall({
    restaurantId: restaurantId,
    body: { reservation: newReservation, email: email },
    shouldConfirm: shouldConfirm,
    createEmail: createEmail,
  });
};

type walkedInWithoutReservationCall = commandProps<{ newReservation: NewReservation; tableIds: Table["id"][] }, {}>;

export const walkedInWithoutReservationCall = async (props: walkedInWithoutReservationCall) =>
  await ApiCall<Reservation>(
    `reservations/WalkInWithoutReservation?restaurantId=${props.restaurantId}`,
    "POST",
    JSON.stringify(props.body)
  );

export const walkedInWithoutReservation = async (
  restaurantId: string,
  newReservation: NewReservation,
  tableIds?: Table["id"][]
) => {
  return await walkedInWithoutReservationCall({
    restaurantId: restaurantId,
    body: { newReservation: newReservation, tableIds: tableIds || [] },
  });
};

type updateReservationCall = commandProps<Reservation, { res: Reservation }>;

export const updateReservationCall = async (props: updateReservationCall) =>
  await ApiCall<Reservation>(
    `reservations/${props.res.id}?restaurantId=${props.restaurantId}`,
    "POST",
    JSON.stringify(props.body),
    undefined,
    { ...props.res }
  );

export const updateReservation = async (restaurantId: string, res: Reservation) => {
  Queue.addToQueue<updateReservationCall>({
    guid: uuid.v4(),
    command: "updateReservation",
    data: { restaurantId, body: res, res: res },
  });
  return res;
};

type walkedInWithReservationCall = commandProps<walkedInConfirmation, { id: Reservation["id"] }>;

export const walkedInWithReservationCall = async (props: walkedInWithReservationCall) =>
  await ApiCall<void>(
    `reservations/${props.id}/WalkInWithReservation?restaurantId=${props.restaurantId}`,
    "POST",
    JSON.stringify(props.body),
    undefined,
    { id: props.id }
  );

export const walkedInWithReservation = async (
  restaurantId: string,
  reservation: Reservation,
  walkedInConfirmation: walkedInConfirmation,
  tables: Table[]
) => {
  Queue.addToQueue<walkedInWithReservationCall>({
    guid: uuid.v4(),
    command: "walkedInWithReservation",
    data: { restaurantId, body: walkedInConfirmation, id: reservation.id },
  });
  return {
    ...reservation,
    state: 3,
    ...walkedInConfirmation,
    tables: tables,
  } as Reservation;
};

type confirmReservationCall = commandProps<
  { reservation: Reservation; email?: Email },
  { id: Reservation["id"]; createEmail: boolean }
>;

export const confirmReservationCall = async (props: confirmReservationCall) =>
  await ApiCall<Reservation>(
    `reservations/${props.id}/ConfirmReservation?restaurantId=${props.restaurantId}${
      props.createEmail ? "&createEmail=true" : ""
    }`,
    "POST",
    JSON.stringify(props.body),
    undefined,
    { ...props.body.reservation }
  );

export const confirmReservation = async (
  restaurantId: string,
  reservation: Reservation,
  createEmail: boolean,
  email?: Email
) => {
  Queue.addToQueue<confirmReservationCall>({
    guid: uuid.v4(),
    command: "confirmReservation",
    data: { restaurantId, id: reservation.id, body: { reservation, email }, createEmail: createEmail },
  });
  return { ...reservation, state: 1 } as Reservation;
};

type rescheduleReservationCall = commandProps<string, { id: Reservation["id"] }>;

export const rescheduleReservationCall = async (props: rescheduleReservationCall) =>
  await ApiCall<void>(
    `reservations/${props.id}/Reschedule?restaurantId=${props.restaurantId}`,
    "POST",
    JSON.stringify(props.body),
    undefined,
    { id: props.id }
  );

export const rescheduleReservation = async (restaurantId: string, reservation: Reservation, isoDate: string) => {
  Queue.addToQueue<rescheduleReservationCall>({
    guid: uuid.v4(),
    command: "rescheduleReservation",
    data: { restaurantId, id: reservation.id, body: isoDate },
  });
  return { ...reservation, dateOfArrival: isoDate } as Reservation;
};

type cancelReservationCall = commandProps<{ reason: string; reasonPhrase: string }, { id: Reservation["id"] }>;

export const cancelReservationCall = async (props: cancelReservationCall) =>
  await ApiCall<void>(
    `reservations/${props.id}?restaurantId=${props.restaurantId}`,
    "DELETE",
    JSON.stringify(props.body),
    undefined,
    { id: props.id }
  );

export const cancelReservation = async (
  restaurantId: string,
  reservation: Reservation,
  reason: string,
  reasonPhrase: string
) => {
  Queue.addToQueue<cancelReservationCall>({
    guid: uuid.v4(),
    command: "cancelReservation",
    data: { restaurantId, id: reservation.id, body: { reason, reasonPhrase } },
  });
  return { ...reservation, state: 5 } as Reservation;
};

type deleteReservationCall = commandProps<undefined, { id: Reservation["id"] }>;

export const deleteReservationCall = async (props: deleteReservationCall) =>
  await ApiCall<void>(
    `reservations/${props.id}/Delete?restaurantId=${props.restaurantId}`,
    "DELETE",
    undefined,
    undefined,
    { id: props.id }
  );

export const deleteReservation = async (restaurantId: string, { id }: Reservation) => {
  Queue.addToQueue<deleteReservationCall>({
    guid: uuid.v4(),
    command: "deleteReservation",
    data: { restaurantId, id, body: undefined },
  });
  return;
};

type guestLeftReservationCall = commandProps<Reservation, { reservation: Reservation }>;

export const guestLeftReservationCall = async (props: guestLeftReservationCall) =>
  await ApiCall<void>(
    `reservations/${props.reservation.id}/leave?restaurantId=${props.restaurantId}`,
    "POST",
    JSON.stringify(props.body),
    undefined,
    { id: props.reservation.id }
  );

export const guestLeftReservation = async (restaurantId: string, reservation: Reservation) => {
  Queue.addToQueue<guestLeftReservationCall>({
    guid: uuid.v4(),
    command: "guestLeftReservation",
    data: { restaurantId, reservation, body: reservation },
  });
  return { ...reservation, state: 6 } as Reservation;
};

type updateGuestAmountOnReservationCall = commandProps<Reservation["guestAmount"], { id: Reservation["id"] }>;

export const updateGuestAmountOnReservationCall = async (props: updateGuestAmountOnReservationCall) =>
  await ApiCall<void>(
    `reservations/${props.id}/UpdateGuestAmount?restaurantId=${props.restaurantId}`,
    "POST",
    JSON.stringify(props.body),
    undefined,
    { id: props.id }
  );

export const updateGuestAmountOnReservation = async (
  restaurantId: string,
  reservation: Reservation,
  guestAmount: Reservation["guestAmount"]
) => {
  Queue.addToQueue<updateGuestAmountOnReservationCall>({
    guid: uuid.v4(),
    command: "updateGuestAmountOnReservation",
    data: { restaurantId, id: reservation.id, body: guestAmount },
  });
  return { ...reservation, guestAmount: guestAmount };
};

export const getSummariesStorageLocation = ({
  restaurantId,
  year,
  month,
}: {
  restaurantId: string;
  year: number;
  month: number;
}) => `${restaurantId}_getSummariesForMonth_${year}_${month}`;

export const saveSummaryInStorage = ({
  restaurantId,
  year,
  month,
  summary,
}: {
  restaurantId: string;
  year: number;
  month: number;
  summary: SummaryTable;
}) => {
  const storageLocation = getSummariesStorageLocation({ restaurantId: restaurantId, year: year, month: month });
  window.localStorage.setItem(storageLocation, JSON.stringify(summary));
  return storageLocation;
};
export type getSummariesCallBack = (
  summary: SummaryTable | undefined,
  year: number,
  month: number,
  isFresh: boolean
) => any;
export const getSummariesForMonth = (
  { restaurantId, year, month }: { restaurantId: string; year: number; month: number },
  cb: getSummariesCallBack
) => {
  const getSummary = async () => {
    try {
      const resp = await getReservationSummaryForMonth(restaurantId, year, month);
      saveSummaryInStorage({ restaurantId: restaurantId, year: year, month: month, summary: resp.reservationSummary });
      return cb(resp.reservationSummary, year, month, true);
    } catch (error) {
      setTimeout(() => {
        getSummary();
      }, 1500);
    }
  };

  const storageLocation = getSummariesStorageLocation({ restaurantId: restaurantId, year: year, month: month });

  try {
    if (window && window.localStorage && window.localStorage.getItem) {
      const summary = window.localStorage.getItem(storageLocation);
      if (summary !== null) {
        const parsed = JSON.parse(summary) as SummaryTable;
        cb(parsed, year, month, false);
      } else {
        cb(undefined, year, month, false);
      }
      getSummary();
    }
  } catch (error) {
    throw error;
  }
};

const checkQueueForReservations = (reservations: Reservation[], date: string) => {
  const queue = Queue.getQueue();
  const queueMessages = queue
    .filter((message) =>
      reservationCommands.some((key) => key === message.command) &&
      message.data &&
      (message.data as commandProps<any, any>).body.id
        ? reservations.some((res) => (message.data as commandProps<any, any>).body.id === res.id)
        : false
    )
    .map((res) => res.data.body);
  return [...reservations, ...queueMessages];
};

export const getReservationsStorageLocation = ({ restaurantId, date }: { restaurantId: string; date: string }) =>
  `${restaurantId}_getReservationsForDate_${date}`;

export const saveReservationsInStorage = ({
  restaurantId,
  date,
  reservations,
}: {
  restaurantId: string;
  date: string;
  reservations: Reservation[];
}) => {
  const storageLocation = getReservationsStorageLocation({ restaurantId: restaurantId, date: date });
  window.localStorage.setItem(storageLocation, JSON.stringify(reservations));
  return storageLocation;
};

export const getReservationsForDate = ({ restaurantId, date }: { restaurantId: string; date: string }, cb: any) => {
  const getReservations = async () => {
    try {
      const resp = await getAllReservationsForDate(restaurantId, date);
      saveReservationsInStorage({ restaurantId: restaurantId, date: date, reservations: resp.reservations });
      return cb(checkQueueForReservations(resp.reservations, date), date, true);
    } catch (error) {
      setTimeout(() => {
        getReservations();
      }, 1500);
    }
  };

  const storageLocation = getReservationsStorageLocation({ restaurantId: restaurantId, date: date });

  try {
    if (window && window.localStorage && window.localStorage.getItem) {
      const reservations = window.localStorage.getItem(storageLocation);
      if (reservations !== null) {
        const parsed = JSON.parse(reservations) as Reservation[];
        cb(checkQueueForReservations(parsed, date), date, false);
      } else {
        cb(checkQueueForReservations([], date), date, false);
      }
      getReservations();
    }
  } catch (error) {
    throw error;
  }
};

export const getEmail = async (restaurantId: string, reservation: Reservation) => {
  try {
    const handledResponse = await ApiCall<Email>(
      `Reservations/GetEmail?restaurantId=${restaurantId}`,
      "POST",
      JSON.stringify(reservation),
      (response: Email) => {
        return response;
      }
    );
    return handledResponse;
  } catch (error) {
    throw error;
  }
};

type sendEmailCall = commandProps<Email, { resId: Reservation["id"] }>;

export const sendEmailCall = async (props: sendEmailCall) =>
  await ApiCall<void>(
    `Reservations/${props.resId}/SendConfirmationEmail?restaurantId=${props.restaurantId}`,
    "POST",
    JSON.stringify(props.body)
  );

// export const sendEmail = async (restaurantId: string, email: Email) => {
//   Queue.addToQueue<sendEmailCall>({
//     guid: uuid.v4(),
//     command: "sendEmail",
//     data: { restaurantId, body: email },
//   });
//   return;
// };

export const sendEmail = async (restaurantId: string, email: Email, resId: Reservation["id"]) => {
  try {
    const handledResponse = await ApiCall<undefined>(
      `Reservations/${resId}/SendConfirmationEmail?restaurantId=${restaurantId}`,
      "POST",
      JSON.stringify(email)
    );
    return handledResponse;
    //Return something?
    //...
  } catch (error) {
    throw error;
  }
};

export type reservationCommands =
  | "putNewReservation"
  | "updateReservation"
  | "walkedInWithReservation"
  | "confirmReservation"
  | "rescheduleReservation"
  | "cancelReservation"
  | "deleteReservation"
  | "guestLeftReservation"
  | "updateGuestAmountOnReservation"
  | "sendEmail";

export const reservationCommands: reservationCommands[] = [
  "putNewReservation",
  "cancelReservation",
  "confirmReservation",
  "deleteReservation",
  "guestLeftReservation",
  "rescheduleReservation",
  "updateGuestAmountOnReservation",
  "updateReservation",
  "walkedInWithReservation",
  "sendEmail",
];
