import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PCA } from "../../..";
import AuthConfig from "../../../AuthConfig";
import SettingsProvider from "../../../clientApi/SettingsProvider";
import { useToasterHandler } from "../../../components/ToasterHandler/useToasterHandler";
import { getSavedAccount } from "../../../hooks/useSavedAccount";
import { useToggle } from "../../../hooks/useToggle";
import Tools from "../../../Tools";
import { ExportDayViewTypes } from "./ExportDayViewTypes";

export const useExportDayViewModel = (props: ExportDayViewTypes.Props) => {
  const { replace } = useHistory();
  const { date: dateString } = useParams<ExportDayViewTypes.Params>();

  const { addMessage } = useToasterHandler();

  const [file, setFile] = useState<File | Blob | null>(null);

  const [isLoading, , startLoading, stopLoading] = useToggle();
  const [hasErrorOccured, setHasErrorOccured] = useState(false);

  const [hasRequestedFile, setHasRequestedFile] = useState(false);

  const currentDate = useMemo(
    () => (dateString ? new Date(dateString) : props.selectedDate),
    [dateString, props.selectedDate]
  );

  const moveToDate = useCallback(
    (date: Date) => {
      const isoLike = Tools.dateToIsoLike(date);
      replace(`/export/day/${isoLike}`);
    },
    [replace]
  );

  const getToken = useCallback(async () => {
    try {
      const savedAccount = PCA.getActiveAccount() || PCA.getAllAccounts().find((a) => a) || getSavedAccount();
      const { accessToken } = await PCA.acquireTokenSilent({
        ...AuthConfig.apiRequest.reservierungen,
        account: savedAccount || undefined,
      });
      return accessToken;
    } catch (error) {
      throw error;
    }
  }, []);

  const downloadExportFile = useCallback(
    async (date: Date) => {
      try {
        setHasRequestedFile(() => {
          setHasErrorOccured(false);
          return true;
        });
        startLoading();
        const mandant = SettingsProvider.get("mandant");
        const isoLike = Tools.dateToIsoLike(date);
        const params = new URLSearchParams();
        params.append("date", isoLike);
        params.append("restaurantId", mandant);
        const token = await getToken();
        const response = await fetch(
          window.location.host.includes("localhost")
            ? `https://localhost:44324/api/reservations/excel?${params.toString()}`
            : `/api/reservations/excel?${params.toString()}`,
          {
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const file = await response.blob();
        console.log({ file });
        return file;
      } catch (error) {
        setHasErrorOccured(true);
        addMessage({
          title: `${downloadExportFile.name}`,
          text: `Fehler beim Abrufen der Datei\r\n\r\n${error}`,
          color: "red",
          icon: "ExclamationCircleIcon",
        });
        throw error;
      } finally {
        stopLoading();
      }
    },
    [addMessage, getToken, startLoading, stopLoading]
  );

  const downloadFileForToday = useCallback(async () => {
    try {
      const resp = await downloadExportFile(currentDate);
      setFile(resp);
    } catch (error) {
      throw error;
    }
  }, [currentDate, downloadExportFile]);

  useEffect(() => {
    if (currentDate) {
      setFile(() => {
        setHasRequestedFile(false);
        return null;
      });
    }
  }, [currentDate]);

  useEffect(() => {
    if (hasErrorOccured) setHasRequestedFile(true);
  }, [hasErrorOccured]);

  useEffect(() => {
    if (!dateString) moveToDate(currentDate);
  }, [currentDate, dateString, moveToDate]);

  return { currentDate, moveToDate, isLoading, hasErrorOccured, downloadFileForToday, file, hasRequestedFile };
};
