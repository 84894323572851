import { AccountInfo } from "@azure/msal-browser";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import AuthConfig from "../AuthConfig";
import B2C from "../b2cpolicies";
import { PCA } from "../index";
import { Graph } from "../models/graphWrapper";
import { useBlobUrl } from "./useBlobUrl";
import { getSavedAccount } from "./useSavedAccount";

export const useGraph = () => {
  const callApi = useCallback(
    async <T>(url: string, method: Request["method"], returnType: "none" | "json" | "blob", body?: object) => {
      try {
        const savedAccount = PCA.getActiveAccount() || PCA.getAllAccounts().find((a) => a) || getSavedAccount();

        const { accessToken } = await PCA.acquireTokenSilent({
          ...AuthConfig.graphRequest,
          account: savedAccount || undefined,
        });

        console.log({ savedAccount, accessToken });

        const resp = await fetch(`https://graph.microsoft.com/v1.0/${url}`, {
          method,
          headers: {
            authorization: `Bearer ${accessToken}`,
            "Content-type": "application/json",
          },
          body: body ? JSON.stringify(body) : undefined,
        });

        if (resp.ok) {
          if (returnType !== "none") {
            try {
              if (returnType in resp) {
                const parsed: Graph.ServerWrapper<T> = await resp[returnType]();
                if ("value" in parsed) {
                  return parsed.value;
                } else return parsed as any as T;
              } else throw new Error(`Unable to perform [${returnType}] on response`);
            } catch (error) {
              throw new Error(`Could not parse Request - ${error}`);
            }
          } else return null as any as T;
        } else throw new Error(`Response did not indicate success - ${resp.status} - ${resp.statusText}`);
      } catch (error) {
        throw new Error(error);
      }
    },
    []
  );

  return { callApi };
};

const userImgStorage = "ACCOUNT_IMAGE";
export const useProfileImage = () => {
  const savedAccount: AccountInfo | null =
    PCA.getActiveAccount() || PCA.getAllAccounts().find((a) => a) || getSavedAccount();
  const [imgBlob, setImgBlob] = useState<Blob | null>(null);
  const [imgBase64, setImgBase64] = useState<string | null>(localStorage[userImgStorage]);
  const blobUrl = useBlobUrl(imgBlob);

  const email = useMemo(
    () =>
      savedAccount?.username.includes("@")
        ? savedAccount.username
        : savedAccount?.idTokenClaims && "emails" in savedAccount?.idTokenClaims
        ? (savedAccount?.idTokenClaims as any)["emails"][0]
        : null,
    [savedAccount?.idTokenClaims, savedAccount?.username]
  );

  const { callApi } = useGraph();

  const onLoadEndGetBase64 = useCallback(
    (
        resolve: (value: typeof imgBase64 | PromiseLike<typeof imgBase64>) => void,
        reject: (value: typeof imgBase64 | PromiseLike<typeof imgBase64>) => void
      ) =>
      (ev: ProgressEvent<FileReader>) => {
        if (ev.target && typeof ev.target.result === "string") {
          resolve(ev.target.result);
        } else reject("Did not Work");
      },
    []
  );

  const getBlobAsBase64 = useCallback(
    async (blob: Blob) => {
      try {
        const reader = new FileReader();
        const base64 = await new Promise<typeof imgBase64>((resolve, reject) => {
          reader.addEventListener("loadend", onLoadEndGetBase64(resolve, reject));
          reader.readAsDataURL(blob);
        });
        return base64;
      } catch (error) {
        console.error(error);
      }
    },
    [onLoadEndGetBase64]
  );

  const saveInLocalStorage = useCallback((base64: string) => {
    localStorage.setItem(userImgStorage, base64);
  }, []);

  const getUserProfilePhotoURL = useCallback(async () => {
    try {
      if (!email) throw new Error("no Email");
      const resp = await callApi<Blob>(`${B2C.authorityDomain}/users/${email}/photos/64X64/$value`, "GET", "blob");
      if (resp.type.includes("image")) {
        const base64 = await getBlobAsBase64(resp);
        setImgBlob(() => {
          if (base64) {
            setImgBase64(base64);
          }
          return resp;
        });
      }
      return resp;
    } catch (error) {
      console.error(error);
    }
  }, [callApi, email, getBlobAsBase64]);

  useEffect(() => {
    if (imgBlob && imgBase64) {
      saveInLocalStorage(imgBase64);
    }
  }, [imgBase64, imgBlob, saveInLocalStorage]);

  useLayoutEffect(() => {
    getUserProfilePhotoURL();
  }, [getUserProfilePhotoURL]);

  const returnValue = useMemo(() => blobUrl || imgBase64, [blobUrl, imgBase64]);
  return returnValue;
};
