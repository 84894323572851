import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import React from "react";
import ReactDOM from "react-dom";
import smoothscroll from "smoothscroll-polyfill";
import { AppShell } from "./AppShell";
import AuthConfig from "./AuthConfig";

// import * as serviceWorker from "./serviceWorker";

export const PCA = new PublicClientApplication(AuthConfig.base);

smoothscroll.polyfill();

const AppProvider = () => {
  return (
    <MsalProvider instance={PCA}>
      <React.StrictMode>
        <AppShell />
      </React.StrictMode>
    </MsalProvider>
  );
};

ReactDOM.render(<AppProvider />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
