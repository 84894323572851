import PersistentStorageRepository from "./PersistentStorageRepository";
import SettingsProvider, { AppSettings } from "./SettingsProvider";

const mapLocation = (key: string, date?: Date) => {
  const identifier = date ? date.toISOString().slice(0, 10) : "default";
  const mandant = SettingsProvider.get("mandant");
  return `${mandant}_${identifier}_${key}`;
};

let CacheData: any = {};

const clearCache = () => {
  const emptyCache = (Object.keys(CacheData) as (keyof typeof CacheData)[]).reduce((obj, key) => {
    return { ...obj, [key]: null };
  }, {} as typeof CacheData);
  CacheData = emptyCache;
  return emptyCache;
};

const Events = {
  CACHE_UPDATED: "CACHE_UPDATED",
};

const handleSettingsChange = (event: CustomEvent<keyof AppSettings>) => {
  const { detail } = event;
  if (detail.toUpperCase() === "DATE" || detail.toUpperCase() === "MANDANT") {
    // clearCache();
    // loadFromPersistentStorageByKeys(SettingsProvider.get("date"));
  }
};

const handlePersistentStorageChange = async (event: CustomEvent<string>, date: Date) => {
  try {
    const key = event.detail.toLowerCase();
    const storedValue = await PersistentStorageRepository.get<any>(key, date);
    write(key as keyof Cache, storedValue);
  } catch (error) {
    throw error;
  }
};

const loadFromPersistentStorageByKeys = async (date: Date) => {
  try {
    const keys = Object.keys(CacheData) as (keyof Cache)[];
    keys.forEach(async (entry) => {
      const value = await PersistentStorageRepository.get<Cache[typeof entry]>(entry, date);
      write(entry, value || []);
    });
  } catch (error) {
    throw error;
  }
};

const initialise = (date: Date) => {
  loadFromPersistentStorageByKeys(date);
  window.addEventListener(PersistentStorageRepository.Events.STORAGE_UPDATED, (ev: any) =>
    handlePersistentStorageChange(ev, date)
  );
  window.addEventListener(SettingsProvider.Events.SETTINGS_UPDATED, handleSettingsChange as any);
};

const get = async <T extends string, K>(key: T, date?: Date, notFromPersistent?: boolean): Promise<K | null> => {
  try {
    const location = mapLocation(key, date);
    if (!notFromPersistent) {
      const storedValue = await PersistentStorageRepository.get<K>(key, date);
      if (storedValue) {
        CacheData[location] = storedValue;
        return storedValue;
      }
    }
    return CacheData[location];
  } catch (error) {
    throw error;
  }
};

const directlyGet = <T extends string, K>(key: T, date?: Date): K | null => {
  return CacheData[mapLocation(key, date)];
};

const getMultiple = async <T extends string, K>(keys: T[], date: Date): Promise<K[]> => {
  try {
    let collected: K[] = [];
    await Promise.all(
      keys.map(async (key) => {
        const location = mapLocation(key, date);
        try {
          const storedValue = await PersistentStorageRepository.get<K[]>(key, date);
          if (storedValue) {
            CacheData[location] = storedValue;
          }
          if (CacheData[location]) {
            collected = [...collected, ...CacheData[location]];
          }
        } catch (error) {
          throw error;
        }
      })
    );
    return collected;
  } catch (error) {
    throw error;
  }
};

const write: <T extends keyof typeof CacheData>(key: T, newData: typeof CacheData[T], date?: Date) => void = (
  key,
  newData,
  date
) => {
  const location = mapLocation(key + "", date);
  CacheData = { ...CacheData, [location]: newData };
  const cacheUpdated = new CustomEvent<string>(Events.CACHE_UPDATED, {
    detail: (key + "").toUpperCase(),
  });
  return window.dispatchEvent(cacheUpdated);
};

initialise(SettingsProvider.get("date"));

export default { write, get, directlyGet, getMultiple, Events, CacheData };
