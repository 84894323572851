import { NavLink } from "react-router-dom";
import { useWelcomeViewModel } from "./WelcomeViewModel";
import { WelcomeViewTypes } from "./WelcomeViewTypes";
import Background from "../../../img/jessie-mccall-guXX_Wm-wnY-unsplash.jpg";
import { useFadeInComponent } from "../../../hooks/useFadeInComponent";
import { useToggle } from "../../../hooks/useToggle";

export const WelcomeView = (props: WelcomeViewTypes.Props) => {
  const viewModel = useWelcomeViewModel(props);

  const [isImageLoaded, , imageHasLoaded] = useToggle();

  const { opacityClasses } = useFadeInComponent(isImageLoaded);

  return (
    <section className="inline-flex flex-col w-full h-full min-h-0 justify-start items-start content-start">
      <img
        onLoad={imageHasLoaded}
        src={Background}
        alt=""
        className={`fixed inset-0 w-full h-full object-cover ${opacityClasses} duration-[2500ms] ease-in-out`}
      />
      <h3 className="relative text-4xl w-full text-center mt-12 font-black bg-gradient-to-r from-rose-500 to-rose-800 bg-clip-text tracking-widest text-transparent leading-tight">
        Herzlich Willkommen
      </h3>
      {/* <div className="relative inline-flex w-24 h-[3px] mx-auto bg-gradient-to-r from-rose-500 to-rose-800 mt-6"></div> */}
      <div className="absolute bottom-0 w-full bg-gray-600 bg-opacity-50 mix-blend-hue backdrop-blur-sm h-48"></div>
      <div className="absolute bottom-0 mb-20 inline-flex w-full justify-center items-center content-start">
        <NavLink
          className="px-6 py-2 bg-rose-500 hover:bg-rose-600 hover:underline text-white font-semibold tracking-wider"
          to="/login"
        >
          Jetzt anmelden
        </NavLink>
      </div>
    </section>
  );
};
