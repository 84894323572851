import * as React from "react";

export class ErrorBoundary extends React.Component<{}, { hasError: boolean; error: string; errorInfo: string }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: "", errorInfo: "" };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="flex flex-col justify-center items-center w-full h-full bg-gray-100">
          <h1 className="text-red-500 text-2xl h-12 border-b-2 border-red-800">An Error Occured</h1>
          <h1 className="text-red-500 text-sm text-gray-700 py-1">
            {typeof this.state.errorInfo === "object"
              ? JSON.stringify(this.state.errorInfo, undefined, 2)
              : typeof this.state.errorInfo === "string"
              ? this.state.errorInfo
              : null}
          </h1>
          <h1 className="text-red-500 text-sm text-gray-700">
            {typeof this.state.error === "object"
              ? JSON.stringify(this.state.error, undefined, 2)
              : typeof this.state.error === "string"
              ? this.state.error
              : null}
          </h1>
        </div>
      );
    }

    return this.props.children;
  }
}
