import { NachrichtenBody } from "./NachrichtenBody";
import { NachrichtenCancel } from "./NachrichtenCancel";
import { NachrichtenNotiz } from "./NachrichtenNotiz";
import { NachrichtenProcessing } from "./NachrichtenProcessing";

const Body = NachrichtenBody;
const Note = NachrichtenNotiz;
const Processing = NachrichtenProcessing;
const Cancel = NachrichtenCancel;

export default { Body, Note, Processing, Cancel };
