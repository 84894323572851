import React from "react";
import { useTheme } from "../hooks/useTheme";

export const LoadingIndicator = (props: { label?: string }) => {
  const { theme } = useTheme();

  return (
    <div className="flex w-full h-full flex-col justify-center items-center">
      <div className="relative flex w-24 h-24 rounded-full bg-gray-300 shadow-inner p-2 overflow-hidden">
        <div className="absolute flex top-0 left-0 w-full h-full justify-center items-start animate-spin rounded-full shadow-inner overflow-hidden">
          <div className={`h-12 w-12 bg-${theme.backgroundcolor}-500 transform rounded-lg shadow-md`}></div>
        </div>
        <div className="relative flex justify-center items-center text-center leading-none w-full h-full bg-gray-100 rounded-full shadow-inner">
          <span className="text-gray-500 text-xxs uppercase font-semibold tracking-wide">
            {props.label || "Loading..."}
          </span>
        </div>
      </div>
    </div>
  );
};
