import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useToasterHandler } from "../components/ToasterHandler/useToasterHandler";
import { useToggle } from "./useToggle";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);
const isServiceWorkerSupported = navigator && "serviceWorker" in navigator;
const isProduction = process.env.NODE_ENV === "production";
const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

export const useServiceWorker = () => {
  const { addMessage } = useToasterHandler();

  const [serviceWorkerRegistration, setServiceWorkerRegistration] = useState<ServiceWorkerRegistration | null>(null);

  const [isRegistering, setIsRegistering] = useState(false);
  const [isCached, setIsCached] = useState(false);
  const [isCaching, setIsCaching] = useState(false);
  const [hasFoundUpdate, setHasFoundUpdate] = useState(false);
  const [isUpdateAvailable, , updateIsAvailable, updateisNotAvailable] = useToggle(false);
  const [shouldCheckForUpdate, setShouldCheckForUpdates] = useState(false);
  const [updateCheckPing, setUpdateCheckPing] = useState(false);

  const unregister = useCallback(async () => {
    try {
      if (serviceWorkerRegistration) {
        const unreg = await serviceWorkerRegistration.unregister();
        setIsCached(() => {
          setServiceWorkerRegistration(null);
          return false;
        });

        addMessage({
          title: "ServiceWorker - unregister",
          text: `Serviceworker was unregistered`,
          color: "blue",
          icon: "ExclamationIcon",
        });
      } else throw new Error("Serviceworker Registration is already unregistered");
    } catch (error) {
      addMessage({
        title: "ServiceWorker - unregister",
        text: `Fehler\r\n${error}`,
        color: "red",
        icon: "ExclamationCircleIcon",
      });
    }
  }, [addMessage, serviceWorkerRegistration]);

  const registerValidSW = useCallback(async () => {
    try {
      // Registrierung anstoßen
      const registration = await navigator.serviceWorker.register(swUrl);
      setServiceWorkerRegistration(() => {
        return registration;
      });
    } catch (error) {
      addMessage({
        title: "ServiceWorker - registerValidSW",
        text: `Fehler\r\n${error}`,
        color: "red",
        icon: "ExclamationCircleIcon",
      });
    } finally {
      setIsRegistering(false);
    }
  }, [addMessage]);

  const handleLocalHost = useCallback(
    async (swUrl: string) => {
      try {
        // Check if the service worker can be found. If it can't reload the page.
        const resp = await fetch(swUrl, {
          headers: { "Service-Worker": "script" },
        });
        // Ensure service worker exists, and that we really are getting a JS file.
        const contentType = resp.headers.get("content-type");
        if (
          resp.status === 404 ||
          (contentType != null && contentType.indexOf("javascript") === -1) ||
          contentType === null
        ) {
          console.log("Service Worker could not be requested");
          // No service worker found. Probably a different app. Reload the page.
          navigator.serviceWorker.ready.then((registration) => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
          return false;
        } else {
          // Service worker found. Proceed as normal.
          return true;
        }
      } catch (error) {
        addMessage({
          title: "ServiceWorker - handleLocalhost",
          text: `Error while handling localHost\r\n\r\n${error}`,
          color: "red",
          icon: "ExclamationCircleIcon",
        });
      }
    },
    [addMessage]
  );

  const checkForLocalHost = useCallback(async () => {
    try {
      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        const check = await handleLocalHost(swUrl);
        return !check;
      }
      return true;
    } catch (error) {
      addMessage({
        title: "ServiceWorker - startRegistration",
        text: `ServiceWorker konnte nicht registriert werden\r\n\r\n ${error}`,
        color: "red",
        icon: "ExclamationCircleIcon",
      });
      return false;
    }
  }, [addMessage, handleLocalHost]);

  const preRegisterCheck = useCallback(
    (isServiceWorkerSupported: boolean, isProduction: boolean) => {
      try {
        if (!isServiceWorkerSupported) {
          throw new Error("Serviceworker does not seem to be supported - did not find serviceWorker on navigator");
        }
        if (!isProduction) {
          throw new Error("process ENV is not production");
        }
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
        if (publicUrl.origin !== window.location.origin) {
          throw new Error("publicUrl.origin does not match window.location.origin - serviceWorker won't be installed");
        }
        return checkForLocalHost();
      } catch (error) {
        addMessage({
          title: "ServiceWorker - preRegisterCheck",
          text: `ServiceWorker konnte nicht registriert werden\r\n\r\n ${error}`,
          color: "red",
          icon: "ExclamationCircleIcon",
        });
        return false;
      }
    },
    [addMessage, checkForLocalHost]
  );

  const register = useCallback(async () => {
    try {
      setIsRegistering(true);
      const hasPassedChecks = preRegisterCheck(isServiceWorkerSupported, isProduction);
      if (hasPassedChecks) {
        await registerValidSW();
      } else {
        return;
      }
    } catch (error) {
      setIsRegistering(false);
      addMessage({
        title: "ServiceWorker - Register",
        text: `ServiceWorker konnte nicht registriert werden\r\n\r\n ${error}`,
        color: "red",
        icon: "ExclamationCircleIcon",
      });
    }
  }, [addMessage, preRegisterCheck, registerValidSW]);

  const onStateChange = useCallback(
    (state: ServiceWorker["state"], onState: () => any) => (ev: Event) => {
      if (ev) {
        console.log(ev.target);
        const sw = ev.target as ServiceWorker;
        if (sw.state === state) {
          console.log(sw.state);
          onState();
        }
      }
    },
    []
  );

  const handleUpdate = useCallback(async () => {
    try {
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  const onUpdate = useCallback(
    (ev: Event) => {
      try {
        console.log("updatefound");
        if (ev) {
          console.log("updatefound ev");
          setHasFoundUpdate(true);
          const reg = ev.target as ServiceWorkerRegistration;
          if (reg) {
            console.log("updatefound ev reg");
            setServiceWorkerRegistration(reg);
            const { installing } = reg;
            if (installing) {
              installing.addEventListener("statechange", onStateChange("installed", updateIsAvailable));
            }
          }
        }
      } catch (error) {}
    },
    [onStateChange, updateIsAvailable]
  );

  const listenToReady = useCallback(async () => {
    try {
      setIsCaching(true);
      const ready = await navigator.serviceWorker.ready;
      setIsCaching(() => {
        setIsCached(() => {
          setServiceWorkerRegistration(ready);
          return true;
        });
        return false;
      });
    } catch (error) {
      addMessage({
        title: "ServiceWorker - First Install",
        text: `ServiceWorker konnte nicht installiert werden\r\n\r\n ${error}`,
        color: "red",
        icon: "ExclamationCircleIcon",
      });
    } finally {
      setIsCaching(false);
    }
  }, [addMessage]);

  useEffect(() => {
    if (isRegistering) {
      listenToReady();
    }
  }, [isRegistering, listenToReady]);

  useEffect(() => {
    if (serviceWorkerRegistration && isCached) {
      serviceWorkerRegistration.addEventListener("updatefound", onUpdate);
      return () => {
        serviceWorkerRegistration.removeEventListener("updatefound", onUpdate);
      };
    }
  }, [isCached, onUpdate, serviceWorkerRegistration]);

  useEffect(() => {
    if (isCached) {
      setShouldCheckForUpdates(true);
      addMessage({
        title: "ServiceWorker - Ready",
        text: "ServiceWorker ist bereit",
        color: "green",
        icon: "CheckIcon",
        delay: 2500,
      });
    }
  }, [addMessage, isCached]);

  useEffect(() => {
    if (isCached && serviceWorkerRegistration?.waiting) {
      if (serviceWorkerRegistration.waiting.state === "activating") {
        updateisNotAvailable();
      } else {
        updateIsAvailable();
      }
    }
  }, [
    hasFoundUpdate,
    isCached,
    serviceWorkerRegistration,
    serviceWorkerRegistration?.waiting,
    updateIsAvailable,
    updateisNotAvailable,
  ]);

  useEffect(() => {
    if (isCached && serviceWorkerRegistration?.waiting?.state === "activating") {
      updateisNotAvailable();
    }
  }, [isCached, serviceWorkerRegistration?.waiting, updateisNotAvailable]);

  const updateInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  useEffect(() => {
    if (shouldCheckForUpdate && serviceWorkerRegistration) {
      console.log("checking for updates");
      const timeout = setTimeout(() => {
        updateInterval.current = setInterval(async () => {
          try {
            if ("onLine" in navigator ? navigator.onLine : true) {
              setUpdateCheckPing((c) => !c);
              serviceWorkerRegistration.update();
            }
          } catch (error) {
            console.error(error);
            return;
          }
        }, 10000);
      }, 10000);

      return () => {
        if (timeout) clearTimeout(timeout);
        if (updateInterval.current) {
          console.log("not checking for updates");
          clearInterval(updateInterval.current);
        }
      };
    }
  }, [serviceWorkerRegistration, shouldCheckForUpdate]);

  const forceUpdate = useCallback(async () => {
    try {
      const registration = await navigator.serviceWorker.register(swUrl);
      setServiceWorkerRegistration(registration);
      const { waiting } = registration;
      if (waiting) {
        waiting.postMessage({ type: "SKIP_WAITING" });
      }
      setTimeout(() => {
        window.location.reload();
      }, 100);
    } catch (error) {
      addMessage({
        title: "ServiceWorker - Update",
        text: `Update Fehlgeschlagen\r\nKein Update verfügbar?`,
        color: "red",
        icon: "BanIcon",
        delay: 3500,
      });
    }
  }, [addMessage]);

  useEffect(() => {
    if (hasFoundUpdate) {
      addMessage({
        title: "ServiceWorker - Update",
        text: "Update gefunden - wird heruntergeladen...",
        color: "blue",
        icon: "RefreshIcon",
        delay: 3500,
      });
    }
  }, [addMessage, hasFoundUpdate]);

  useEffect(() => {
    if (isUpdateAvailable) {
      addMessage({
        title: "ServiceWorker - Update",
        text: "Update bereit installiert zu werden",
        color: "blue",
        icon: "RefreshIcon",
        delay: 15000,
        onClick: forceUpdate,
      });
    }
  }, [addMessage, forceUpdate, hasFoundUpdate, isUpdateAvailable]);

  return {
    register,
    forceUpdate,
    serviceWorkerRegistration,
    isCached,
    isCaching,
    isUpdateAvailable,
    hasFoundUpdate,
    shouldCheckForUpdate,
    unregister,
    updateCheckPing,
  };
};
