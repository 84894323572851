import { InteractionType, SilentRequest } from "@azure/msal-browser";
import { useAccount, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { useEffect, useMemo } from "react";
import AuthConfig from "../../AuthConfig";
import { useSavedAccount } from "../../hooks/useSavedAccount";
import { AuthenticatedViewTypes } from "./AuthenticatedViewTypes";

export const useAuthenticatedViewModel = (props: AuthenticatedViewTypes.Props) => {
  const [acc, setAccount] = useSavedAccount();
  const account = useAccount(acc ?? {});

  const config: SilentRequest = useMemo(
    () => ({
      ...AuthConfig.loginRequest,
      account: acc ?? undefined,
      loginHint:
        acc?.username ??
        ((acc?.idTokenClaims as any)?.emails ? (acc?.idTokenClaims as any)?.emails[0] ?? undefined : undefined) ??
        undefined,
    }),
    [acc]
  );
  const { result, error } = useMsalAuthentication(InteractionType.Silent, {
    ...config,
  });

  const { instance } = useMsal();

  useEffect(() => {
    if (result) {
      setAccount(result.account);
      instance.setActiveAccount(result.account);
    }
    if (error) {
      console.error(error);
      if (acc && error.message.includes("silent_sso_error")) {
        instance.loginRedirect(config);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acc, account, config, error, instance.setActiveAccount, instance.loginRedirect, result, setAccount]);

  return {};
};
