import { XIcon } from "@heroicons/react/outline";
import * as React from "react";
import { ReservationButton } from "../../components/ReservationButton";
import { SelectableTable, TableSelect } from "../../components/TableSelect";
import { useTheme } from "../../hooks/useTheme";
import { Table } from "../../models/Table";
import { useTischeViewModel } from "./TischeViewModel";
import { TischeViewModel } from "./TischeViewType";

export const TischeView = (props: TischeViewModel.Props) => {
  const viewModel = useTischeViewModel(props);
  const { theme } = useTheme();

  const areButtonsVisible = React.useMemo(
    () => !props.hideButtons && !!props.selectedReservation,
    [props.hideButtons, props.selectedReservation]
  );

  const isMultiTableVisible = React.useMemo(
    () => !!props.selectedReservation && !props.shouldHideMultiTable,
    [props.shouldHideMultiTable, props.selectedReservation]
  );

  const deselectTable = React.useMemo(() => {
    const ids = props.tables.map((table) => [table.id, () => viewModel.deselectTisch(table)]);
    const deselector = Object.fromEntries(ids);
    return deselector;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tables, viewModel.deselectTisch]);

  const multiTables = React.useMemo(
    () =>
      isMultiTableVisible
        ? props.selectedTables?.map((tab, index) => (
            <div key={index} className="relative flex flex-shrink-0 flex-grow-0 w-20 px-1 h-full">
              <XIcon
                className={`absolute flex flex-grow-0 flex-shrink-0 justify-center items center w-4 h-4 p-1  rounded-full bg-${theme.backgroundcolor}-600 text-${theme.backgroundcolor}-100 top-0 right-0 shadow z-10`}
                onClick={deselectTable[tab.id]}
              />
              <SelectableTable unfixedSize appColors={theme} isGrayed={false} table={tab} />
            </div>
          ))
        : null,
    [deselectTable, isMultiTableVisible, theme, props.selectedTables]
  );

  return (
    <div className={`flex flex-col flex-1 min-h-0 w-full h-full shadow-md`}>
      <div className={`flex flex-1 flex-col w-full max-h-full min-h-0 h-full bg-${theme.backgroundcolor}-700`}>
        <div className={`flex h-2 flex-grow-0 flex-shrink-0 w-full`}>
          <div className="flex w-full h-full bg-white justify-around items-center shadow-inner rounded-t-lg"></div>
        </div>
        <div className={`relative flex flex-col w-full h-full max-h-full min-h-0 z-10 bg-${theme.backgroundcolor}-700`}>
          <div className={`relative top-0 left-0 bg-white ${isMultiTableVisible ? "h-14" : "h-0"} w-full`}>
            <div
              className={`flex flex-grow-0 flex-shrink-0 w-full h-14 bg-transparent top-0 flex-no-wrap overflow-auto justify-start items-center px-1 transition-all duration-200 ease-in-out`}
            >
              {multiTables}
            </div>
          </div>
          <div
            className={`relative top-0 left-0 w-full h-full flex flex-col min-h-0 overflow-hidden transition-all duration-100 ease-in-out`}
          >
            <TableSelect
              data={props.tables}
              appColors={theme}
              onTableSelection={viewModel.selectTisch}
              onReservationSelection={viewModel.selectReservierung}
              currentTables={undefined}
              selectedTables={props.selectedTables || []}
              reservations={props.reservations}
              selectedReservation={props.selectedReservation || undefined}
              loadingReservations={props.loadingReservations}
              resNameFullLenght={true}
              canSelectReservation={props.canSelectReservation}
              filterReservationByStates={props.filterReservationByStates}
            />
          </div>

          {areButtonsVisible ? (
            <div
              className={`bottom-0 left-0 flex justify-start  flex-grow-0 flex-shrink-0 ${
                viewModel.spaceOnBottom ? "h-16 items-start" : "h-14 items-center"
              }  w-full px-1 py-1 bg-white border-t shadow-md`}
            >
              <div className={`flex justify-start items-center flex-grow-0 flex-shrink-0 h-10 w-full px-1`}>
                <ReservationButton
                  color={"red"}
                  isLocked={false}
                  unlockedHeight
                  onClickHandler={viewModel.deselectAllTische}
                  transition={{}}
                  additionalClasses={"rounded-lg"}
                >
                  Zurücksetzen
                </ReservationButton>
                <div className="w-3"></div>
                <ReservationButton
                  color={"green"}
                  isLocked={false}
                  unlockedHeight
                  onClickHandler={viewModel.confirmSelectedTables}
                  transition={{}}
                  additionalClasses={"rounded-lg"}
                >
                  Bestätigen
                </ReservationButton>
              </div>
            </div>
          ) : (
            <div className="flex w-full h-2 bg-white justify-around items-center shadow-md"></div>
          )}
        </div>
      </div>
    </div>
  );
};
