import * as React from "react";
import { AppFunctions, AppState } from "../App";
import { Nachricht } from "../clientApi/models/Nachricht";
import { useSettings } from "../clientApi/SettingsProvider";
import useConnected from "../hooks/useConnected";
import { useCurrentDate } from "../hooks/useCurrentDate";
import { useProfileImage } from "../hooks/useGraph";
import { addProtocol } from "../hooks/useProtocol";
import { Cause } from "../models/Cause";
import { TailwindBackgroundColors } from "../models/General";
import { DateButton } from "./DateButton";
import { Heroicons } from "./Heroicon/Heroicon";
import { NotificationBar } from "./NotificationBar";
import { TableImage } from "./TableImage";
import { UserMenu } from "./usermenu/UserMenu";

const possibleColors: TailwindBackgroundColors[] = [
  "yellow",
  "amber",
  "orange",
  "red",
  "rose",
  "pink",
  "fuchsia",
  "purple",
  "violet",
  "indigo",
  "darkBlue",
  "blue",
  "cyan",
  "teal",
  "emerald",
  "green",
  "lime",
  "gray",
];

interface AppNavBarProps {
  appVersion?: string;
  appCauses: AppState["appCauses"];
  appReservationDate: AppState["appReservationDate"];
  appColors: AppState["appColors"];
  appUser: AppState["appUser"];
  appDisplayMode: AppState["appDisplayMode"];
  appColSize: AppState["appColSize"];
  appHeadTitle: AppState["appHeadTitle"];
  appSetOverview: AppFunctions["appSetOverview"];
  appWebSocket: AppState["appWebSocket"];
  appRestaurantId: AppState["appRestaurantId"];
  appIsCached: AppState["appIsCached"];
  appIsUpdateAvailable: AppState["appIsUpdateAvailable"];
  appSize: AppState["appSize"];
  appInitialiseWebSocket: AppFunctions["appInitialiseWebsocket"];
  browserHistory: AppFunctions["browserHistory"];
  appShowNotification: AppFunctions["appShowNotification"];
  appUpdateApp: AppFunctions["appUpdateApp"];
  setNewReservationPrimer: AppFunctions["setNewReservationPrimer"];
  setColor: AppFunctions["setColor"];
  setAppState: AppFunctions["setAppState"];
}

export const AppNavBar: React.FC<AppNavBarProps> = (props) => {
  const { appCauses, appColors, setColor, children } = props;
  const [windowWidth, setWindowWidth] = React.useState<number>(0);
  const [isMenuVisible, setIsMenuVisible] = React.useState(false);
  const [hasTriedGettingPhotoUrl, setHasTriedGettingPhotoUrl] = React.useState(false);
  const [hasRetriedGettingPhotoUrl, setHasRetriedGettingPhotoUrl] = React.useState(false);

  const [, , , frozenDate] = useCurrentDate();

  const [amountOfNotifications, setAmountOfNotifications] = React.useState(0);

  const [isUserMenuVisible, setUserMenuVisible] = React.useState(false);

  const isOnline = useConnected();

  const unused = [hasTriedGettingPhotoUrl, hasRetriedGettingPhotoUrl];

  const { set } = useSettings();

  const _changeDateTo = React.useCallback(
    async (newDate: Date) => {
      const oldDate = props.appReservationDate.toISOString();
      try {
        set("date", newDate);
        return props.setAppState.call(undefined, "appReservationDate", newDate);
      } catch (error) {
        set("date", newDate);
        props.setAppState.call(undefined, "appReservationDate", new Date(oldDate));
        throw error;
      }
    },
    [props.appReservationDate, props.setAppState, set]
  );

  const _handleDateForward = React.useCallback(async () => {
    const currentDate = props.appReservationDate;
    const tomorrow = new Date(currentDate.setDate(currentDate.getDate() + 1));
    _changeDateTo(tomorrow);
  }, [_changeDateTo, props.appReservationDate]);

  const _handleDateBack = React.useCallback(async () => {
    const currentDate = props.appReservationDate;
    const yesterday = new Date(currentDate.setDate(currentDate.getDate() - 1));
    _changeDateTo(yesterday);
  }, [_changeDateTo, props.appReservationDate]);

  React.useEffect(() => {
    const nachrichten: (Nachricht.Client<any> | Cause)[] = appCauses ?? [];
    const tempDate = new Date(frozenDate);
    tempDate.setDate(tempDate.getDate() - 3);
    tempDate.setHours(0);
    tempDate.setMinutes(1);
    tempDate.setSeconds(1, 1);
    setAmountOfNotifications(
      nachrichten.filter(
        (c) => (typeof c.timestamp === "string" ? new Date(c.timestamp) : c.timestamp) >= tempDate && c.state <= 0
      ).length
    );
  }, [appCauses, frozenDate]);

  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    if (windowWidth === 0) handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth]);

  const userImgBlobUrl = useProfileImage();

  React.useEffect(() => {
    setIsMenuVisible(false);
  }, [windowWidth]);

  React.useEffect(() => {
    if (isOnline) {
      addProtocol({
        data: "Connected",
        desc: "App is Connected",
        type: "API",
      });
    } else {
      addProtocol({
        data: "Disconnected",
        desc: "App is Disconnected",
        type: "API",
      });
    }
  }, [isOnline]);

  const [currentColor, setCurrentColor] = React.useState<number>(0);

  const _handleChangeColorClick = React.useCallback(
    (color?: TailwindBackgroundColors) => {
      const selectedColor = possibleColors.findIndex((c) => color === c);
      setCurrentColor((currentIndex) => {
        let newIndex = currentIndex + 1;
        if (newIndex >= possibleColors.length) newIndex = 0;
        newIndex = selectedColor !== -1 ? selectedColor : newIndex;
        setColor({
          backgroundcolor: possibleColors[newIndex],
          textcoloroncolor: appColors.textcoloroncolor,
          textdefaultcolor: appColors.textdefaultcolor,
        });
        return newIndex;
      });
    },
    [appColors.textcoloroncolor, appColors.textdefaultcolor, setColor]
  );

  const transition: React.CSSProperties = React.useMemo(
    () => ({
      transitionDuration: "200ms",
      position: isMenuVisible ? "absolute" : "relative",
      top: isMenuVisible ? "1.1rem" : undefined,
    }),
    [isMenuVisible]
  );

  const showAllWidth = 900;
  const _toggleMenuVisibility = () => setIsMenuVisible((curr) => !curr);

  const _hideMenu = () => setIsMenuVisible(false);

  const _hideUserMenu = React.useCallback(() => setUserMenuVisible(false), []);

  const _toggleUserMenu = React.useCallback(() => setUserMenuVisible((c) => !c), []);

  const _setOverviewVisibility = React.useCallback(() => {
    props.appSetOverview.call(undefined, true);
  }, [props.appSetOverview]);

  const _style = React.useMemo(
    () =>
      ({
        transitionDuration: "200ms",
        transitionTimingFunction: "cubic-bezier(0.42, 0, 0.58, 1)",
        transitionDelay: "120ms",
        position: "absolute",
        transform: `translate(${isMenuVisible ? "0%" : "-100%"}, 0px)`,
      } as React.CSSProperties),
    [isMenuVisible]
  );

  const burgerMenu = React.useMemo(
    () =>
      windowWidth < showAllWidth && props.appDisplayMode !== "Tablet" ? (
        <div
          className={`flex w-16 bg-${props.appColors.backgroundcolor}-500 pl-3 pr-2 items-center text-white font-bold text-sm flex-shrink-0 flex-grow-0`}
          onClick={_toggleMenuVisibility}
        >
          <div className="flex relative w-6 flex-grow-0 flex-col justify-between items-center py-2 h-full">
            {amountOfNotifications > 0 ? (
              <div
                className={`absolute flex justify-center items-center top-0 right-0 bg-red-500 text-white ring-2 ring-${
                  props.appColors.backgroundcolor
                }-500 mt-px ${
                  amountOfNotifications >= 10 ? "w-7 -mr-4" : "w-4 -mr-2"
                } h-5 text-xs rounded-full z-20 leading-none tabular-nums`}
              >
                {amountOfNotifications}
              </div>
            ) : null}

            <div
              style={{ ...transition, transform: isMenuVisible ? "rotate(45deg)" : "initial" }}
              className={`bg-${props.appColors.backgroundcolor}-100 h-1 w-full`}
            ></div>
            <div
              style={transition}
              className={`h-1 w-full bg-${isMenuVisible ? "transparent" : props.appColors.backgroundcolor + "-100"}`}
            ></div>
            <div
              style={{ ...transition, transform: isMenuVisible ? "rotate(-45deg)" : "initial" }}
              className={`bg-${props.appColors.backgroundcolor}-100 h-1 w-full`}
            ></div>
          </div>
        </div>
      ) : null,
    [
      amountOfNotifications,
      isMenuVisible,
      props.appColors.backgroundcolor,
      props.appDisplayMode,
      transition,
      windowWidth,
    ]
  );

  const element = React.useMemo(
    () => (
      <>
        <div
          key={2}
          className={`bg-${props.appColors.backgroundcolor}-400 w-full h-full z-30 pt-10 pb-20`}
          style={_style}
        >
          <div className="flex flex-1 h-full w-full flex-col items-stretch justify-around" onClick={_hideMenu}>
            {props.children}
          </div>
        </div>
        <nav
          className={`inline-flex w-full max-w-full min-h-0 min-w-0 relative h-10 flex-grow-0 flex-shrink-0 bg-${props.appColors.backgroundcolor}-500 z-40 shadow`}
        >
          <UserMenu
            appColors={props.appColors}
            appRestaurantId={props.appRestaurantId}
            appUser={props.appUser}
            isVisible={isUserMenuVisible}
            setAppState={props.setAppState}
            handleClose={_hideUserMenu}
            appDisplayMode={props.appDisplayMode}
            appWebSocket={props.appWebSocket}
            appInitialiseWebSocket={props.appInitialiseWebSocket}
            appColSize={props.appColSize}
            handleColorChange={_handleChangeColorClick}
            possibleColors={possibleColors}
            appVersion={props.appVersion}
            appSize={props.appSize}
          ></UserMenu>
          <div className="inline-flex h-full w-full min-h-0 min-w-0 max-w-full overflow-hidden justify-start items-stretch content-start">
            {burgerMenu}
            <div
              className={`flex flex-4 md:flex-2 bg-${
                props.appColors.backgroundcolor
              }-500 pl-4 pr-4 md:pl-8 md:pr-4 items-center ${
                windowWidth < showAllWidth && props.appDisplayMode !== "Tablet" ? "justify-center" : "justify-start"
              } text-white font-bold text-sm flex-shrink-0`}
              // onClick={_handleChangeColorClick}
            >
              {windowWidth < showAllWidth && props.appDisplayMode !== "Tablet" ? (
                props.appHeadTitle ? (
                  props.appHeadTitle
                ) : (
                  <DateButton
                    appColors={props.appColors}
                    currentDate={props.appReservationDate}
                    handleDateBack={_handleDateBack}
                    handleDateForward={_handleDateForward}
                    onButtonClick={_setOverviewVisibility}
                  ></DateButton>
                )
              ) : (
                props.appRestaurantId
              )}
            </div>
            {windowWidth !== 0 && (windowWidth > showAllWidth || props.appDisplayMode === "Tablet") ? (
              <>
                <div
                  className={`inline-flex min-h-0 flex-10 text-${props.appColors.backgroundcolor}-100 max-w-full overflow-hidden`}
                >
                  {props.children}
                </div>
                <div
                  className={`flex flex-2 bg-${props.appColors.backgroundcolor}-400 justify-center items-center text-white text-xs flex-shrink-0`}
                  onClick={_toggleUserMenu}
                >
                  {props.appUser ? props.appUser.name ?? props.appUser.username : null}
                </div>
              </>
            ) : null}

            <div
              // style={{ flex: 1 }}
              className={`flex flex-grow-0 flex-shrink-0 bg-${
                props.appColors.backgroundcolor
              }-400 justify-center items-center text-white text-sm px-2 w-16 rounded-tl-lg sm:rounded-tl-none  pl-4 border-r-4 ${
                isOnline ? "border-green-500" : "border-red-500"
              }`}
              onClick={_toggleUserMenu}
            >
              <TableImage
                className={`z-50`}
                shadow={false}
                size={8}
                // onClick={adalLogOut}
                backgroundSize={"cover"}
                alt={"User Img"}
                url={userImgBlobUrl}
              >
                <div className="w-8 h-8 rounded-full bg-gray-100">
                  <Heroicons.Solid.UserCircleIcon className="w-full h-full text-gray-400" />
                </div>
              </TableImage>
            </div>
          </div>
        </nav>
        {/* <div
          className={`inline-flex w-full h-6 justify-center items-center flex-grow-0 bg-${props.appColors.backgroundcolor}-100 text-${props.appColors.backgroundcolor}-700 text-xs font-semibold tracking-wide shadow px-2`}
        >
          <Heroicons.Outline.CalendarIcon className="w-4 h-4 mr-auto" />
          <span id="eventBar">Kein Event</span>
          <Heroicons.Outline.SparklesIcon className="w-4 h-4 ml-auto" />
        </div> */}
        <NotificationBar
          shouldHide={!isMenuVisible}
          appDisplayMode={props.appDisplayMode}
          appIsCached={props.appIsCached}
          appIsUpdateAvailable={props.appIsUpdateAvailable}
          appRestaurantId={props.appRestaurantId}
          appShowNotification={props.appShowNotification}
          appUpdateApp={props.appUpdateApp}
          appWebSocket={props.appWebSocket}
          browserHistory={props.browserHistory}
          setNewReservationPrimer={props.setNewReservationPrimer}
        ></NotificationBar>
      </>
    ),
    [
      _handleChangeColorClick,
      _handleDateBack,
      _handleDateForward,
      _hideUserMenu,
      _setOverviewVisibility,
      _style,
      _toggleUserMenu,
      burgerMenu,
      isMenuVisible,
      isOnline,
      isUserMenuVisible,
      props.appColSize,
      props.appColors,
      props.appDisplayMode,
      props.appHeadTitle,
      props.appInitialiseWebSocket,
      props.appIsCached,
      props.appIsUpdateAvailable,
      props.appReservationDate,
      props.appRestaurantId,
      props.appShowNotification,
      props.appSize,
      props.appUpdateApp,
      props.appUser,
      props.appVersion,
      props.appWebSocket,
      props.browserHistory,
      props.children,
      props.setAppState,
      props.setNewReservationPrimer,
      userImgBlobUrl,
      windowWidth,
    ]
  );

  return element;
};
