import AuthConfig from "../AuthConfig";
import { Anfrage } from "../clientApi/models/Anfrage";
import { Gast } from "../clientApi/models/Gast";
import { Nachricht } from "../clientApi/models/Nachricht";
import SettingsProvider from "../clientApi/SettingsProvider";
import { addProtocol } from "../hooks/useProtocol";
import { getSavedAccount } from "../hooks/useSavedAccount";
import { PCA } from "../index";
import { Cause } from "../models/Cause";
import { Email } from "../models/Email";
import { NewReservation, Reservation, walkedInConfirmation } from "../models/Reservation";
import { SummaryTable } from "../models/Summary";
import { Table } from "../models/Table";

export const getDateString = (date?: Date) => {
  const dateString = (date || new Date()).toLocaleDateString("de-de", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  console.log(dateString);
  const [day, month, year] = [dateString.slice(0, 2), dateString.slice(3, 5), dateString.slice(6, 10)];
  return `${year}-${month}-${day}`;
};

type ServerResponse = { date: string; eventId: string; eventVersion: number };

type RequestOption = RequestInit & { API: string };
const DefaultOptions = {
  POST: {
    method: "POST",
    mode: "cors",
    credentials: "same-origin",
    headers: { "Content-Type": "application/json" },
  } as RequestInit,
  PUT: {
    method: "PUT",
    mode: "cors",
    credentials: "same-origin",
    headers: { "Content-Type": "application/json" },
  } as RequestInit,
  DELETE: {
    method: "DELETE",
    mode: "cors",
    credentials: "same-origin",
    headers: { "Content-Type": "application/json" },
  } as RequestInit,
  GET: { method: "GET", mode: "cors" } as RequestInit,
};

const getOption: (opt: RequestOption | keyof typeof DefaultOptions, API?: keyof typeof APIS) => RequestOption = (
  opt,
  API
) => {
  if (typeof opt === "string") {
    if (API) {
      if (opt === "GET") {
        return { API: APIS[API], ...DefaultOptions["GET"] };
      } else if (opt === "POST") {
        return { API: APIS[API], ...DefaultOptions["POST"] };
      } else if (opt === "PUT") {
        return { API: APIS[API], ...DefaultOptions["PUT"] };
      } else if (opt === "DELETE") {
        return { API: APIS[API], ...DefaultOptions["DELETE"] };
      } else return { API: APIS[API], ...DefaultOptions["GET"] };
    } else return { API: APIS.reservierungen, ...DefaultOptions["GET"] };
  } else {
    return opt;
  }
};

const APIS = {
  reservierungen: "api/reservations",
  nachrichten: "api/causes",
  gaeste: "api/guests",
  tische: "api/tables",
  anfragen: "api/requests",
};

const Options = {
  GetSingleReservation: getOption("GET", "reservierungen"),
  GetReservierungen: getOption("GET", "reservierungen"),
  GetTische: getOption("GET", "tische"),
  GetGaeste: getOption("GET", "gaeste"),
  GetAnfragen: getOption("GET", "anfragen"),
  GetZusammenfassung: getOption("GET", "reservierungen"),
  GetNachrichtenByDateSpan: getOption("GET", "nachrichten"),
  GetMissedCallMessage: getOption("GET", "nachrichten"),
  AddReservierung: getOption("PUT", "reservierungen"),
  WalkInWithoutReservation: getOption("POST", "reservierungen"),
  WalkInWithReservierung: getOption("POST", "reservierungen"),
  ProcessCause: getOption("POST", "nachrichten"),
  AssignTischeToReservierung: getOption("POST", "reservierungen"),
  UnassignTischeToReservierung: getOption("POST", "reservierungen"),
  CancelReservierung: getOption("DELETE", "reservierungen"),
  ConfirmReservierung: getOption("POST", "reservierungen"),
  LeaveReservierung: getOption("POST", "reservierungen"),
  AddTische: getOption("POST", "tische"),
  AddGaeste: getOption("POST", "gaeste"),
  AddAnfragen: getOption("POST", "anfragen"),
};

const Queries = {
  GetSingleReservation: async (id: Reservation["id"]) => {
    try {
      const resp: Reservation = await getItem({}, "GetSingleReservation", true, id, undefined, "json");
      return resp;
    } catch (error) {
      throw error;
    }
  },
  getReservierungenByDate: async (date: Date) => {
    try {
      const reservierungen = await getItems<{ date: string }, Reservation, Reservation>(
        {
          date: getDateString(date),
        },
        "GetReservierungen",
        undefined,
        undefined,
        undefined,
        (res: Reservation) => ({ ...res, tables: res.tables?.sort((a, b) => +a.name - +b.name) })
      );
      return reservierungen;
    } catch (error) {
      throw error;
    }
  },
  getTische: async (date?: Date) => {
    try {
      const tische = await getItems<undefined, Table, Table>(undefined, "GetTische");
      return tische;
    } catch (error) {
      throw error;
    }
  },
  getGaesteByDate: async (date: Date) => {
    try {
      const gaeste = await getItems<{ date: string }, Gast.Server, Gast.Client>(
        {
          date: getDateString(date),
        },
        "GetGaeste"
      );
      return gaeste;
    } catch (error) {
      throw error;
    }
  },
  getAnfragenByDate: async (date: Date) => {
    try {
      const anfragen = await getItems<{ date: string }, Anfrage.Server, Anfrage.Client>(
        {
          date: getDateString(date),
        },
        "GetAnfragen"
      );
      return anfragen;
    } catch (error) {
      throw error;
    }
  },
  getZusammenfassungByDate: async (date: Date) => {
    try {
      const zusammenfassung = await getItems<{ year: string; month: string }, SummaryTable, SummaryTable>(
        { year: date.getFullYear() + "", month: date.getMonth() + 1 + "" },
        "GetZusammenfassung",
        true,
        "GetReservationSummaryForMonth"
      );
      return zusammenfassung;
    } catch (error) {
      throw error;
    }
  },
  getMissedCallMessage: async (cause: Cause | Nachricht.Client<any>) => {
    try {
      const soundFile: File = await getItem<{}, File>({}, "GetMissedCallMessage", true, undefined, cause.id, "blob");
      return soundFile;
    } catch (error) {
      throw error;
    }
  },
  getNachrichtenByDateSpan: async (start: Date, end: Date) => {
    try {
      const nachrichten = await getItems<
        { startDate: string; endDate: string },
        Nachricht.Server,
        Nachricht.Client<any>
      >(
        {
          startDate: (typeof start === "string" ? new Date(start) : start).toISOString().slice(0, 10),
          endDate: (typeof end === "string" ? new Date(end) : end).toISOString().slice(0, 10),
        },
        "GetNachrichtenByDateSpan",
        false,
        undefined,
        undefined,
        (item) => {
          try {
            let parsed = item.body && typeof item.body === "string" ? JSON.parse(item.body) : item.body;
            return {
              ...item,
              body: parsed,
              timestamp: new Date(item.timestamp),
              modified: item.modified ? new Date(item.modified) : undefined,
            };
          } catch (error) {
            return {
              ...item,
              body: item.body || null,
              timestamp: new Date(item.timestamp),
              modified: item.modified ? new Date(item.modified) : undefined,
            };
          }
        }
      );
      return nachrichten.sort((a: any, b: any) => +b.timestamp - +a.timestamp);
    } catch (error) {
      throw error;
    }
  },
  getNachrichtenBeforeDate: async (start: Date) => {
    try {
      // const daySpan = SettingsProvider.get("nachrichtenDaySpan");
      // const tempEndDate = new Date(start);
      // tempEndDate.setDate(tempEndDate.getDate() - 1);

      const nachrichten = await Queries.getNachrichtenByDateSpan(start, start);
      return nachrichten;
    } catch (error) {
      throw error;
    }
  },
};

const Commands = {
  addReservierungToDate: async (
    reservierung: NewReservation,
    shouldConfirm?: boolean,
    email?: Email,
    createEmail?: boolean
  ) => {
    try {
      const resp = await addItem(
        { reservation: reservierung, email: email },
        { confirm: shouldConfirm, createEmail: Boolean(createEmail) },
        "AddReservierung"
      );
      return resp;
    } catch (error) {
      throw error;
    }
  },
  WalkInWithoutReservation: async (reservierung: NewReservation, tischIds: Table["id"][]) => {
    try {
      const resp = await addItem(
        { newReservation: reservierung, tableIds: tischIds },
        {},
        "WalkInWithoutReservation",
        true
      );
      return resp;
    } catch (error) {
      throw error;
    }
  },
  WalkInWithReservierung: async (reservierung: Reservation, walkedInConfirmation: walkedInConfirmation) => {
    try {
      const resp = await addItem(
        walkedInConfirmation,
        {},
        "WalkInWithReservierung",
        true,
        "WalkInWithReservation",
        reservierung.id
      );
      return resp;
    } catch (error) {
      throw error;
    }
  },
  ProcessCause: async (cause: Cause, requestReservation: boolean) => {
    try {
      const resp = await addItem(cause, { requestReservation }, "ProcessCause", true, cause.id);
      return resp;
    } catch (error) {
      throw error;
    }
  },
  ProcessNachricht: async (nachricht: Nachricht.Client<any>, requestReservation: boolean) => {
    try {
      const resp = await addItem(nachricht, { requestReservation }, "ProcessCause", true, nachricht.id);
      return resp;
    } catch (error) {
      throw error;
    }
  },
  AssignTischeToReservierung: async (reservierung: Reservation, tische: Table[]) => {
    try {
      const resp = await addItem(
        tische.map((t) => t.id),
        {},
        "AssignTischeToReservierung",
        true,
        "AssignTables",
        reservierung.id
      );
      return resp;
    } catch (error) {
      throw error;
    }
  },
  UnassignTischeToReservierung: async (reservierung: Reservation) => {
    try {
      const resp = await addItem(
        undefined,
        {},
        "UnassignTischeToReservierung",
        true,
        "UnassignTables",
        reservierung.id
      );
      return resp;
    } catch (error) {
      throw error;
    }
  },
  CancelReservierung: async (reservierung: Reservation, reason: string, reasonPhrase: string) => {
    try {
      const resp = await addItem({ reason, reasonPhrase }, {}, "CancelReservierung", false, undefined, reservierung.id);
      return resp;
    } catch (error) {
      throw error;
    }
  },
  ConfirmReservierung: async (reservierung: Reservation, createEmail: boolean, email?: Email) => {
    try {
      const resp = await addItem(
        { reservation: reservierung, email },
        { createEmail: Boolean(createEmail) },
        "ConfirmReservierung",
        true,
        "ConfirmReservation",
        reservierung.id
      );
      return resp;
    } catch (error) {
      throw error;
    }
  },
  LeaveReservierung: async (reservierung: Reservation) => {
    try {
      const resp = await addItem(reservierung, {}, "LeaveReservierung", true, "leave", reservierung.id);
      return resp;
    } catch (error) {
      throw error;
    }
  },
  addTischToDate: async (date: Date, tisch: Table) => {
    try {
      const resp = await addItem(tisch, { date }, "AddTische");
      return resp;
    } catch (error) {
      throw error;
    }
  },
  addGastToDate: async (date: Date, gast: Gast.Client) => {
    try {
      const resp = await addItem(gast, { date }, "AddGaeste");
      return resp;
    } catch (error) {
      throw error;
    }
  },
  addAnfrageToDate: async (date: Date, anfrage: Anfrage.Client) => {
    try {
      const resp = await addItem(anfrage, { date }, "AddAnfragen");
      return resp;
    } catch (error) {
      throw error;
    }
  },
};

const addItem = async <P, T, K extends keyof typeof Options>(
  item: T,
  params: P,
  optionKey: K,
  includeKey?: boolean,
  overWriteKey?: string,
  preKey?: string
) => {
  try {
    const resp = await sendToApi(
      optionKey,
      params,
      {
        body: item ? JSON.stringify(item) : item,
      } as typeof Options[typeof optionKey],
      includeKey,
      overWriteKey,
      preKey
    );
    try {
      const json: ServerResponse = await resp.json();
      return json;
    } catch (error) {
      return null;
    }
  } catch (error) {
    throw error;
  }
};

const getItems = async <P, T, R>(
  params: P,
  optionKey: keyof typeof Options,
  includeKey?: boolean,
  overWriteKey?: string,
  preKey?: string,
  mapper?: (item: T) => R
) => {
  try {
    const resp = await sendToApi(optionKey, params, undefined, includeKey, overWriteKey, preKey);
    const json: T[] = await resp.json();
    if (mapper && Array.isArray(json)) return json.map(mapper);
    return json;
  } catch (error) {
    throw error;
  }
};

const getItem = async <P, T>(
  params: P,
  optionKey: keyof typeof Options,
  includeKey?: boolean,
  overWriteKey?: string,
  preKey?: string,
  respFunc?: keyof Pick<Response, "arrayBuffer" | "blob" | "json" | "formData">
) => {
  try {
    const resp = await sendToApi(optionKey, params, undefined, includeKey, overWriteKey, preKey);
    if (resp && respFunc && respFunc in resp && typeof resp[respFunc] === "function") {
      const result: T = await resp[respFunc]();
      return result;
    } else {
      if (resp === undefined || resp === null) throw new Error("Response was null or undefined");
      else if (respFunc === undefined) throw new Error("Chosen Function was undefined");
      else if (!(respFunc in resp)) throw new Error("Chosen Function does not exist in response");
      else if (!(typeof resp[respFunc] === "function"))
        throw new Error("Given property for response function was not of type function");
      else throw new Error("Could not determine what went wrong");
    }
  } catch (error) {
    throw error;
  }
};

const sendToApi = async <P, T extends keyof typeof Options, O extends Partial<typeof Options[T]>>(
  key: T,
  params: P,
  option?: O,
  includeKey?: boolean,
  overWriteKey?: string,
  preKey?: string
) => {
  try {
    const mandant = SettingsProvider.get("mandant");
    const baseUrl = window.location.hostname === "localhost" ? "https://localhost:44324" : window.location.origin;
    const paramsString = paramsToString({ restaurantId: mandant, ...params });

    const savedAccount = PCA.getActiveAccount() || PCA.getAllAccounts().find((a) => a) || getSavedAccount();

    const { accessToken } = await PCA.acquireTokenSilent({
      ...AuthConfig.apiRequest.reservierungen,
      account: savedAccount || undefined,
    });

    // let token = authProvider.getToken();
    // const token = await getToken();
    const tempOpts: RequestOption = { ...Options[key], ...option } || Options[key];
    const opts: RequestOption = {
      ...tempOpts,
      headers: { ...(tempOpts.headers || {}), Authorization: `Bearer ${accessToken}` },
    };

    const url = `${baseUrl}/${opts.API}${preKey ? `/${preKey}` : ""}${
      includeKey ? `/${overWriteKey || key}` : ""
    }${paramsString}`;

    const wrappedFetch = new Promise<Response>((resolve, reject) => fetch(url, opts).then(resolve).catch(reject));

    const resp: Response = await wrappedFetch;
    if (resp.ok) {
      return resp;
    } else {
      try {
        addProtocol({
          data: option ? JSON.stringify(option, null, 2) : "--",
          desc: `Fehler - ${opts.method ?? "??"} - ${url}`,
          type: "API",
          date: new Date(),
        });
      } catch (error) {
        console.error("couldn't add to protocoll", error);
      }

      throw new Error(`${resp.statusText || "Status did not indicate Success"} - ${resp.status} - ${key}`);
    }
  } catch (error) {
    if (
      error &&
      error.message &&
      (error.message.includes("silent_sso") ||
        error.message.includes("grant") ||
        error.message.includes("interaction_required"))
    ) {
      addProtocol({
        data: option ? JSON.stringify(option, null, 2) : "--",
        desc: `Fehler - Silent Token Renewal Fehlgeschlagen`,
        type: "API",
        date: new Date(),
      });
      const savedAccount = PCA.getActiveAccount() || PCA.getAllAccounts().find((a) => a) || getSavedAccount();
      PCA.acquireTokenRedirect({
        ...AuthConfig.apiRequest.reservierungen,
        account: savedAccount ?? undefined,
      });
    } else {
      addProtocol({
        data: "Fehler - " + option ? JSON.stringify(option, null, 2) : "--",
        desc: error?.message,
        type: "API",
        date: new Date(),
      });
    }
    if (SettingsProvider.get("showServerApiError")) {
      window.alert(error instanceof Error ? error.message : JSON.stringify(error));
    }
    console.error("serverapi", error);
    throw error;
  }
};

const paramsToString = <P>(params: P) => {
  return (Object.keys(params) as (keyof typeof params)[]).reduce((baseString, prop, idx) => {
    if (idx !== 0) {
      baseString = baseString + `&${prop}=${params[prop]}`;
    } else {
      baseString = baseString + `?${prop}=${params[prop]}`;
    }
    return baseString;
  }, "");
};

const ServerApi = {
  ...Queries,
  ...Commands,
  APIS,
};

export default ServerApi;
