import { AccountInfo } from "@azure/msal-common";
import { useMsal } from "@azure/msal-react";
import { useCallback, useEffect, useState } from "react";
import { useEventListener } from "./useEventlistener";
import { useToggle } from "./useToggle";

export const SAVED_ACCOUNT_LOCATION = "ACCOUNT";
const BLOCK_ACCOUNT_STORAGE = "BLOCK_ACCOUNT_STORAGE";

export const getSavedAccount = () => {
  const resp = localStorage.getItem(SAVED_ACCOUNT_LOCATION);
  if (resp) {
    try {
      const parsed = JSON.parse(resp);
      return parsed;
    } catch (error) {
      console.error(error);
      return null;
    }
  } else return null;
};

export const useSavedAccount = (acc?: AccountInfo) => {
  const [savedAccount, setSavedAccount] = useState<AccountInfo | null>(
    localStorage[SAVED_ACCOUNT_LOCATION] ? JSON.parse(localStorage[SAVED_ACCOUNT_LOCATION]) || null : null
  );

  const [isBlocked, , block] = useToggle(false);

  const { instance, accounts } = useMsal();

  const clearAccount = useCallback(() => {
    window.dispatchEvent(new Event(BLOCK_ACCOUNT_STORAGE));
  }, []);

  useEffect(() => {
    if (!isBlocked && acc) setSavedAccount(acc);
  }, [acc, isBlocked]);

  useEffect(() => {
    if (!isBlocked && accounts && accounts[0]) {
      const temp = accounts[0];
      setSavedAccount(temp as AccountInfo);
    }
  }, [accounts, isBlocked]);

  useEffect(() => {
    if (!isBlocked && savedAccount) {
      localStorage.setItem(SAVED_ACCOUNT_LOCATION, JSON.stringify(savedAccount));
      instance.setActiveAccount(savedAccount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance.setActiveAccount, savedAccount]);

  useEffect(() => {
    if (isBlocked) {
      setSavedAccount(null);
      const storageAccount = localStorage.getItem(SAVED_ACCOUNT_LOCATION);
      if (storageAccount) localStorage.removeItem(SAVED_ACCOUNT_LOCATION);
      const interval = setInterval(() => {
        try {
          const storageAccount = localStorage.getItem(SAVED_ACCOUNT_LOCATION);
          if (storageAccount) localStorage.removeItem(SAVED_ACCOUNT_LOCATION);
        } catch (error) {
          console.error(error);
        }
      }, 50);
      return () => {
        if (interval) clearInterval(interval);
      };
    }
  }, [isBlocked]);

  useEventListener(BLOCK_ACCOUNT_STORAGE, block);

  return [savedAccount, setSavedAccount, clearAccount] as [
    AccountInfo | null,
    (acc: AccountInfo | null) => void,
    () => () => void
  ];
};
