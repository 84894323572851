import * as React from "react";
import { AppColors } from "../models/General";
import { InformationBar } from "./InformationBar";
import { InformationButton } from "./InformationButton";
import { Heroicon, Heroicons } from "./Heroicon/Heroicon";

type ViewTopSpaceProps = {
  appColors: AppColors;
  backgroundIconPosition?: {
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
    transform?: string;
  };
  sideButton?: { label: string; onClick: () => any };
  height?: string;
  backgroundIcon?: Heroicon;
  backgroundIconProps?: { width?: string; height?: string };
};

export const ViewTopSpace: React.FC<ViewTopSpaceProps> = (props) => {
  const Icon = React.useMemo(
    () => (props.backgroundIcon ? Heroicons.Outline[props.backgroundIcon] : Heroicons.Outline.BookmarkAltIcon),
    [props.backgroundIcon]
  );

  const IconProps = React.useMemo(
    () => ({ width: "55%", height: "60%", ...(props.backgroundIconProps || {}) }),
    [props.backgroundIconProps]
  );
  const IconContainerStyle = React.useMemo(
    () => ({
      transform: "rotate(15deg)",
      top: "0.5rem",
      left: "-1.75rem",
      right: "",
      bottom: "",
      ...(props.backgroundIconPosition ?? {}),
    }),
    [props.backgroundIconPosition]
  );

  return (
    <InformationBar color={props.appColors.backgroundcolor + "-700"} height={props.height ? props.height : "20"}>
      <div className={`absolute top-0 text-${props.appColors.backgroundcolor}-600`} style={IconContainerStyle}>
        <Icon {...IconProps} />
      </div>
      <div className="flex flex-3 flex-col z-10 overflow-hidden w-full h-full">{props.children}</div>
      {props.sideButton ? (
        <div className="flex flex-1 flex-col">
          <InformationButton
            big
            appColors={props.appColors}
            additionalClasses={"ml-auto"}
            spanClass={"shadow-md cursor-pointer"}
            handleClick={props.sideButton.onClick}
          >
            {props.sideButton.label}
          </InformationButton>
        </div>
      ) : null}
    </InformationBar>
  );
};
