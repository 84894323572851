import { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Tools from "../../../Tools";
import AbspracheDetailView from "../detail/AbspracheDetailView";
import AbspracheOverviewView from "../overview/AbspracheOverviewView";
import { useAbspracheMainViewModel } from "./AbspracheMainViewModel";
import AbspracheMainViewTypes from "./AbspracheMainViewTypes";

const AbspracheMainView = (props: AbspracheMainViewTypes.Props) => {
  const viewModel = useAbspracheMainViewModel(props);

  const { push } = useHistory();

  useEffect(() => {
    push(`/absprache/${Tools.dateToIsoLike(props.date)}`);
  }, [props.date, push]);

  return (
    <Switch>
      <Route exact path="/absprache">
        <AbspracheOverviewView {...viewModel} reservierungen={props.reservierungen} />
      </Route>
      <Route path="/absprache/:date">
        <AbspracheDetailView {...viewModel} reservierungen={props.reservierungen} setDate={props.setDate} />
      </Route>
    </Switch>
  );
};
export default AbspracheMainView;
