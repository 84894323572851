import * as React from "react";
import { AppColors } from "../models/General";
import { AppState } from "../App";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

export const DateButton = (props: {
  appColors: AppColors;
  handleDateBack: () => any;
  handleDateForward: () => any;
  currentDate: AppState["appReservationDate"];
  onButtonClick: () => any;
  desktopView?: boolean;
}) => {
  const dateString = React.useMemo(
    () =>
      props.currentDate.toLocaleString("de-de", {
        weekday: "long",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
    [props.currentDate]
  );
  return (
    <>
      <div className="flex flex-1 justify-end items-center h-full pr-2 z-10" onClick={props.handleDateBack}>
        <ChevronLeftIcon
          className={`h-6 w-6 text-${props.desktopView ? undefined : props.appColors.backgroundcolor + "-100"}`}
        />
      </div>
      <div
        className={`flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-8 w-24 ${
          props.desktopView ? "" : `bg-white rounded-lg shadow-md text-${props.appColors.backgroundcolor}-800`
        } select-none`}
        onClick={props.onButtonClick}
      >
        <span className={`text-xxs font-semibold leading-none select-none`}>{dateString.split(",")[0]}</span>
        <span className={`text-sm font-semibold leading-none select-none`}>{dateString.split(",")[1]}</span>
      </div>
      <div
        className="flex flex-1 justify-start items-center h-full pl-2 z-10 select-none"
        onClick={props.handleDateForward}
      >
        <ChevronRightIcon
          className={`h-6 w-6 text-${props.desktopView ? undefined : props.appColors.backgroundcolor + "-100"}`}
        />
      </div>
    </>
  );
};
