import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { useEffect, useMemo } from "react";
import { useProtocol } from "../../hooks/useProtocol";

type ProtocolTrackerProps = {};
const ProtocolTracker = (props: ProtocolTrackerProps) => {
  const appInsightsContext = useAppInsightsContext();
  const { protocol } = useProtocol();
  const lastProtocolEntry = useMemo(() => protocol[0], [protocol]);

  const trackLastProtcolEntry = useTrackEvent(appInsightsContext, "Protocol_Entry", lastProtocolEntry);

  useEffect(() => {
    trackLastProtcolEntry(lastProtocolEntry);
  }, [lastProtocolEntry, trackLastProtcolEntry]);

  return (
    <div
      id="Protocoltracker"
      className="hidden h-0 w-0 min-w-0 min-h-0 max-h-0 max-w-0 opacity-0 pointer-events-none"
    ></div>
  );
};
export default ProtocolTracker;
