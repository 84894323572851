import { Redirect, Route, Switch, useLocation } from "react-router";
import { CancelledLoginView } from "../cancelledLogin/CancelledLoginView";
import { ForgotPasswordView } from "../forgotPasword/ForgotPasswordView";
import { LoginView } from "../login/LoginView";
import { WelcomeView } from "../welcome/WelcomeView";
import { useUnauthenticatedMainViewModel } from "./UnauthenticatedMainViewModel";
import { UnauthenticatedMainViewTypes } from "./UnauthenticatedMainViewTypes";

export const UnauthenticatedMainView = (props: UnauthenticatedMainViewTypes.Props) => {
  const viewModel = useUnauthenticatedMainViewModel(props);
  const location = useLocation();
  return (
    <Switch location={location}>
      <Route exact path="/">
        <WelcomeView {...viewModel} />
      </Route>
      <Route path="/forgotPassword">
        <ForgotPasswordView />
      </Route>
      <Route path="/cancelledLogin">
        <CancelledLoginView />
      </Route>
      <Route path="/login/:shouldLogin?">
        <LoginView {...viewModel} />
      </Route>
      <Route path="/logout">
        <Redirect to="/" />
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};
